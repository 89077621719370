import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { getYear } from 'date-fns'

import { openApiFile } from '../utils/open-api-file'
import { queries } from '../graphql'
import { Box, Button, CardBox, Container, DataGrid, Icons, Stack, Text, useGlobalAlert } from '../components'

export function Documents() {
  return (
    <>
      <Container maxWidth="lg" sx={{ marginBottom: 1 }}>
        <Stack spacing={1}>
          <Text.H1>Documents</Text.H1>
        </Stack>
      </Container>
      <TaxReceipts />
    </>
  )
}

function ReceiptDownloadButton({ year }) {
  const { fundId } = useParams()
  const [, { setGlobalAlert }] = useGlobalAlert()
  const thisYear = getYear(new Date())
  const onClick = async (event) => {
    event.preventDefault()
    try {
      openApiFile(`/pdf/generate/tax-receipt/${fundId}/${year}`)
    } catch (e) {
      setGlobalAlert({
        message: `Could not get tax receipt for ${year}`,
        error: e?.message,
        severity: 'error',
        vertical: 'bottom',
      })
    }
  }
  return (
    <Button sx={{ background: 'none' }} onClick={onClick}>
      <Box sx={{ display: 'flex' }}>
        <Icons.FileOpen sx={{ marginRight: '.5rem' }} />
        <Text.SR>Download Tax Receipt for</Text.SR>
        {year === thisYear ? 'Year To Date' : year}
      </Box>
    </Button>
  )
}

export function TaxReceipts() {
  const { fundId } = useParams()
  const { data: { me: { fund: { taxReceiptYears = [] } = {} } = {} } = {} } = useQuery(queries.funds.myFund, {
    variables: { fundId: Number(fundId) },
  })
  const columns = [
    {
      field: 'year',
      headerName: 'Year',
      flex: 1,
      hideable: false,
      renderCell: ({ row: { year } = {} }) => <ReceiptDownloadButton year={year} />,
    },
  ]
  const rows = taxReceiptYears.map((v, i) => ({ id: i, year: v }))

  return (
    <Container maxWidth="lg">
      <CardBox>
        <DataGrid
          title="Tax Receipts"
          rows={rows}
          columns={columns}
          disableExport
          disableSelectionOnClick
          border={false}
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          }}
        />
      </CardBox>
    </Container>
  )
}
