import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Fields, FormControl, InputLabel, MenuItem, Row, Select, Stack, Text, useAlert } from '../../components'
import { validations } from '../../components/form'
import { mutations } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

export function ChangeEmailForm({ currentEmail, availableEmails, close }) {
  const [emailUpdated, setEmailUpdated] = useState(false)
  const { register, handleSubmit, formState, setError, setValue, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [updateEmail] = useMutation(mutations.user.updateEmail, {
    onCompleted: () => {
      setEmailUpdated(true)
      setAlert({ message: 'Email successfully updated', severity: 'success' })
    },
    onError: (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      setAlert({ message: `Email update failed: ${newErrors.message}`, severity: 'error' })
    },
  })

  const onSubmit = async (data) => {
    updateEmail({
      variables: { data },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {emailUpdated ? (
        <Stack>
          <Text.H6>Email Updated Successfully!</Text.H6>
          <Text.Body data-testid="dialogContent">
            Your primary email has now been updated and a confirmation email has been sent to the new email address as
            well. Note that you will need to use the new email next time you log in.
          </Text.Body>
          <Row justifyContent="flex-end" alignItems="center" m={4}>
            <Button color="info" onClick={close}>
              OK
            </Button>
          </Row>
        </Stack>
      ) : (
        <Stack>
          <Text.H6 data-testid="dialogTitle">Change Email</Text.H6>
          <Stack mx={2}>
            <Text.Body data-testid="dialogContent">
              Please note that changing your primary email will update the email you use for login (currently:{' '}
              {currentEmail})
            </Text.Body>
            <FormControl fullWidth>
              <InputLabel id="newEmailLable">New Email *</InputLabel>
              <Select
                value={getValues('newEmail')}
                defaultValue={currentEmail}
                onChange={(e) => {
                  setValue('newEmail', e.target.value, { shouldValidate: true })
                }}
                label="New Email *"
                labelId="newEmailLabel"
                aria-label="New Email *"
                error={!!errors.newEmail}
                helpertext={errors?.newEmail?.message}
                {...register('newEmail', { ...validations.required })}
              >
                {availableEmails.map((availableEmail) => (
                  <MenuItem key={availableEmail.id} value={availableEmail.email}>
                    {availableEmail.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Fields.Password register={register} errors={errors} />
          </Stack>
          <Row justifyContent="flex-end" alignItems="center" m={4}>
            <Button color="info" onClick={close} data-testid="cancelButton">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" data-testid="changeButton">
              Change Email
            </Button>
          </Row>
          <Alert {...alertProps} />
        </Stack>
      )}
    </form>
  )
}
