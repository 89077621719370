import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Acknowledge, Button, Container, ContentBox, Fields, Link, Row, Stack, Text } from '../components'
import { mutations } from '../graphql'

export function ResetPassword() {
  const { token } = useParams()
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { errors = {} } = formState

  const [resetPassword, { data, error, loading, reset: resetMutation }] = useMutation(mutations.user.resetPassword, {
    onCompleted: () => {
      resetForm()
    },
  })

  const onSubmit = ({ password, passwordConfirmation }) => {
    resetPassword({ variables: { data: { password, passwordConfirmation, token } } })
  }

  const reset = useCallback(() => {
    resetMutation()
    resetForm()
  }, [resetForm, resetMutation])

  const Success = useCallback(
    () => (
      <Acknowledge.Success
        title="Thanks!"
        content="You can now return to the login screen to log in with your new password."
      >
        <Button to="/login">Log In</Button>
      </Acknowledge.Success>
    ),
    []
  )

  const Error = useCallback(
    () => (
      <Acknowledge.Error title="Uh Oh!" content="Reset Password failed" message={error.message}>
        <Button onClick={reset}>Back</Button>
      </Acknowledge.Error>
    ),
    [error, reset]
  )

  return (
    <Container maxWidth="sm" mt={4} mb={4}>
      <Stack spacing={4}>
        <Acknowledge.Switch success={!!data && <Success />} error={!!error && <Error />}>
          <Text.H5>Reset Password</Text.H5>
          <Text.Body>Please enter a new password for your account.</Text.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ContentBox border>
              <Stack spacing={4}>
                <Fields.NewPassword register={register} errors={errors} getValues={getValues} />

                <Row justifyContent="space-between" alignItems="center" spacing={4}>
                  <Text.AltBody>
                    Still need help? Contact <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>support</Link>
                  </Text.AltBody>
                  <Button disabled={loading} type="submit">
                    Submit
                  </Button>
                </Row>
              </Stack>
            </ContentBox>
          </form>
        </Acknowledge.Switch>
      </Stack>
    </Container>
  )
}
