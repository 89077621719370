import { useParams } from 'react-router'
import { Create, Edit, List, Show, useResourceContext } from 'react-admin'

import { Box, Link, Text } from '../../../components'
import { camelToTitleCase } from '../../../utils'

function title(resource) {
  if (resource === 'MFund') return 'Funds'
  if (resource === 'Charity') return 'Charities'
  if (resource === 'Grant') return 'Grant Summaries'
  if (resource === 'MProfile') return 'Profiles'
  if (resource === 'DafTransfer') return 'Share Funds'
  if (resource === 'ManagingUserOnFund') return 'Roles'
  if (resource === 'RecurrentDafTransfer') return 'Recurring Share Funds'
  return `${camelToTitleCase(resource)}s`
}

function TitleLink({ children, ...props }) {
  return (
    <Link sx={{ fontWeight: '600' }} {...props}>
      {children}
    </Link>
  )
}

export function RaCreate({ ...props }) {
  const resource = useResourceContext()
  return (
    <>
      <Text.H1 sx={{ mb: 2 }}>
        <TitleLink to={`/management/${resource}`}>{title(resource)}</TitleLink> / Create
      </Text.H1>
      <Create {...props} />
    </>
  )
}

export function RaList({ ...props }) {
  const resource = useResourceContext()
  return (
    <>
      <Text.H1 sx={{ mb: 2 }}>
        <TitleLink to={`/management/${resource}`}>{title(resource)}</TitleLink>
      </Text.H1>
      <List {...props} />
    </>
  )
}

export function RaShow({ ...props }) {
  const { id = '' } = useParams()
  const resource = useResourceContext()
  return (
    <>
      <Text.H1 sx={{ mb: 2 }}>
        <TitleLink to={`/management/${resource}`}>{title(resource)}</TitleLink> / #{id}
      </Text.H1>
      <Show {...props} />
    </>
  )
}

export function RaEdit({ ...props }) {
  const { id = '' } = useParams()
  const resource = useResourceContext()
  return (
    <Box>
      <Text.H1 sx={{ mb: 2 }}>
        <TitleLink to={`/management/${resource}`}>{title(resource)}</TitleLink> /{' '}
        <TitleLink to={`/management/${resource}/${id}/show`}>#{id}</TitleLink> / Edit
      </Text.H1>
      <Edit {...props} />
    </Box>
  )
}
