import { getAuthToken } from '../hooks'

// Stream file from the api, with bearer token auth
export const openApiFile = async (path) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  })
  const file = window.URL.createObjectURL(await response.blob())
  const filename =
    response.headers.get('Content-Disposition')?.replace(/.*filename="([^"]+)"/, '$1') ?? 'givewise-pdf.pdf'

  // Trigger download
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  anchor.href = file
  anchor.download = filename
  anchor.click()
  anchor.remove()
  window.URL.revokeObjectURL(file)
}

export const openApiFileWithData = async (path, data) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`)
  }

  const blob = await response.blob()
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const base64data = reader.result.split(',')[1] // Get base64 string
      resolve(base64data)
    }
    reader.onerror = () => reject(new Error('Error reading blob as base64'))
    reader.readAsDataURL(blob) // Convert blob to base64
  })
}
