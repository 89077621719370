import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useMediaQuery, useTheme } from '@mui/material'
import { GridRow, useGridApiContext } from '@mui/x-data-grid'
import { Download as DownloadIcon } from '@mui/icons-material'

import { openApiFile } from '../../utils/open-api-file'
import { getTodaysDateFileName, toDateString } from '../../utils'
import { mutations, queries } from '../../graphql'
import {
  Button,
  CancelButton,
  CardBox,
  Container,
  DataGrid,
  Icons,
  Link,
  Row,
  Stack,
  Text,
  Tooltip,
} from '../../components'
// import { Filters } from './filters'

function useIsNarrowerThan(sz) {
  const theme = useTheme()
  return !useMediaQuery(theme.breakpoints.up(sz))
}

/* Shown as column on rows to toggle expand / collapse. */
function RowExpandButton({ id, isExpanded, toggleExpanded, sx, ...props }) {
  return (
    <Button sx={{ flexGrow: 1, p: 0, minWidth: 0, ...sx }} unstyled {...props} onClick={() => toggleExpanded(id)}>
      {isExpanded(id) ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
    </Button>
  )
}

function useColumnsForExpandPanel() {
  const apiRef = useGridApiContext()
  /* showInExpandedRowPanel can exclude some columns from rendering in the expanded panel.
   * Otherwise, show columns that have been hidden. */
  return apiRef.current
    .getAllColumns()
    .filter(({ showInExpandedRowPanel = true, hide = false }) => showInExpandedRowPanel && hide)
}

/* A GridRow component with that conditionally shows an ExpandedRowPanel. */
function ExpandableRow({ isExpanded, rowId, ...props }) {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  return (
    <div>
      <GridRow rowId={rowId} {...props} />
      {isExpanded(rowId) && isNarrowerThanMd && <ExpandedRowPanel data-expanded={rowId} rowId={rowId} />}
    </div>
  )
}

function ExpandedRowPanel({ rowId, sx, ...props }) {
  const apiRef = useGridApiContext()
  const fields = useColumnsForExpandPanel()
    .map(({ field, headerName, renderCell }) => {
      const cellParams = apiRef.current.getCellParams(rowId, field)
      const content =
        renderCell?.({ ...cellParams, api: apiRef.current }) ??
        cellParams.formattedValue?.toString() ??
        cellParams.value?.toString()
      return { key: field, headerName, content }
    })
    .filter(({ content }) => content)

  const justify = useIsNarrowerThan('sm') ? 'space-between' : 'start'

  return (
    <Stack spacing={0} sx={{ pl: 7, ...sx }} {...props}>
      {fields.map(({ key, headerName, content }) => (
        <Stack key={key} direction="row" justifyContent={justify} minHeight="28px" alignItems="center">
          <div style={{ flex: 1, maxWidth: '140px' }}>{headerName}</div>
          <div>{content}</div>
        </Stack>
      ))}
    </Stack>
  )
}

function DownloadGrantLetterButton({ id }) {
  return (
    <Row sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
      <Text.H7>
        <Button
          size="small"
          variant="text"
          onClick={() => openApiFile(`/pdf/generate/grant-letter/${id}?dateSentAt=true`)}
        >
          <DownloadIcon />
          Letter
        </Button>
      </Text.H7>
    </Row>
  )
}

export function Disbursements() {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  const isNarrowerThanSm = useIsNarrowerThan('sm')

  const gridRef = useRef()
  const gridApi = useRef()

  const charityId = Number(useParams().id)

  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId: useParams().id },
  })

  const rows = charity
    ? charity.grantSummaries.map((gs) => ({
        ...gs,
        id: Math.random().toString(36).substr(2, 9),
      }))
    : []

  const [expandMap, setExpandMap] = useState({})
  const isExpanded = useCallback((rowId) => expandMap[rowId] || false, [expandMap])
  const toggleExpanded = useCallback((rowId) => setExpandMap({ ...expandMap, [rowId]: !expandMap[rowId] }), [expandMap])

  function getTypeName(value) {
    switch (value) {
      case 'GrantRequest':
        return 'Gifts'
      case 'DafTransfer':
        return 'Shared Funds'
      default:
        return value
    }
  }

  // /* hide means is hidden from the columnar display and shown in ExpandedRowPanel.
  //  * showInExpandedRowPanel is a boolean that, when false, excludes showing that column in the expanded panel.
  //  *
  //  * Some columns have both because they are never shown in the datagrid but should appear in the csv export... */
  const columns = [
    {
      /* field is required so we have to provide a value, but no value makes
       * sense because this does not map to a property on the row */
      field: '__expand',
      disableExport: true,
      hide: !isNarrowerThanMd,
      sortable: false,
      filterable: false,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      renderCell: ({ row } = {}) => (
        <RowExpandButton id={row.id} isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
      ),
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: '__typename',
      headerName: 'Type',
      flex: 0.5,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      valueFormatter: ({ value }) => getTypeName(value),
      renderCell: ({ row } = {}) => <Icons.ActivityItem row={row} />,
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      type: 'date',
      align: isNarrowerThanSm && 'right',
      headerAlign: isNarrowerThanSm && 'right',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'sentAt',
      headerName: 'Sent Date',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      type: 'date',
      align: isNarrowerThanSm && 'right',
      headerAlign: isNarrowerThanSm && 'right',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'state',
      headerName: 'State',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 150,
      maxWidth: 150,
      // align: 'center',
      // headerAlign: 'center',
      renderCell: ({ value } = {}) => <Icons.State state={value} />,
    },
    {
      field: 'grantId',
      headerName: 'Gift Details Letter',
      hide: isNarrowerThanMd,
      // hide: true,
      flex: 1,
      minWidth: 220,
      maxWidth: 220,
      // headerAlign: 'center',
      // align: 'center',
      renderCell: ({ value, row } = {}) => {
        if (row.state !== 'sent') {
          return <Row sx={{ minHeight: '32px' }} />
        }

        return <DownloadGrantLetterButton id={value} />
      },
      disableExport: true,
    },
  ]

  return (
    <Container maxWidth="lg">
      <CardBox>
        {charity ? (
          <DataGrid
            ref={gridRef}
            apiRef={gridApi}
            exportFileName={`Gift Disbursements Export - ${
              charity ? charity.accountName : ''
            } - ${getTodaysDateFileName()}`}
            disableColumnSelector
            rows={rows}
            columns={columns}
            loading={charityLoading}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
            }}
            components={{ Row: ExpandableRow }}
            componentsProps={{ row: { isExpanded } }}
            autoRowHeight
            sx={{
              '& .MuiDataGrid-cell': {
                p: '3px',
              },
            }}
          />
        ) : (
          ''
        )}
      </CardBox>
    </Container>
  )
}

// Renders if description is from a grant request, else returns the string value
function GrantRequestDescriptionRender({ descriptionValue, fundId, isGrantRequest }) {
  if (isGrantRequest) {
    const [charity, description] = descriptionValue
    return (
      <Text.AltBody>
        {charity && <Link to={`/funds/${fundId}/grant/${charity?.id}`}>{charity?.accountName}</Link>}
        {description && `${charity && ', '}${description}`}
      </Text.AltBody>
    )
  }

  return descriptionValue
}
