import { useQuery } from '@apollo/client'

import { queries } from '../../graphql'
import { Icons } from '..'

export function FundLogo({ fundId, sx, ...props }) {
  const logoImage = <Icons.GrantThin style={{ height: '40%' }} /> // Replace later with a specific default image once chosen
  let savedLogo // No saved logos yet for generosity funds
  console.log(savedLogo)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: savedLogo ? 'transparent' : '#f0f0f0',
      }}
    >
      {logoImage}
    </div>
  )
}
