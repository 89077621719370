import { Icons, Link, Row, Stack, Text } from '..'

export function Error({ title, content, message, children }) {
  return (
    <Row justifyContent="center" spacing={4}>
      <Row alignItems="center" spacing={4}>
        <Icons.Error sx={{ fontSize: '3rem' }} color="warning" />
        <Stack spacing={2}>
          {title && <Text.H5>{title}</Text.H5>}
          {content && <Text.Body>{content}</Text.Body>}
          {message && <Text.Body>{message}</Text.Body>}
          <Text.Body>
            For support, please contact us at <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
          </Text.Body>
          {children && <Row sx={{ justifyContent: 'flex-end' }}>{children}</Row>}
        </Stack>
      </Row>
    </Row>
  )
}
