import { CardBox, Link, Stack, Text } from '.'
import placeholder from '../static/placeholder.jpg'

export function BigImageWithText({ title, body, footer, link, imageUrl }) {
  return (
    <CardBox
      sx={{
        background: `url(${imageUrl || placeholder})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '300px',
        color: 'var(--dark-blue)',
        padding: 0,
      }}
    >
      <ResolvedLink
        link={link}
        sx={{
          position: 'absolute',
          zindex: -1,
          width: '100%',
          height: '100%',
        }}
      />
      <Stack
        sx={{
          height: '100%',
          maxWidth: '300px',
          '& > *': { overflowWrap: 'anywhere' },
          /* For some reason this style exists by default on Stack except in management.
           * So the preview looks different. Anyway just set it explicitly so they match... */
          '& > *:not(:first-of-type)': { mt: 2 },
          padding: 4,
          textDecoration: 'none',
        }}
      >
        <Text.H1 sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}>{title}</Text.H1>
        <Text.Body>{body}</Text.Body>
        <Text.Body sx={{ textDecoration: 'underline' }}>{footer}</Text.Body>
      </Stack>
    </CardBox>
  )
}

/**
 * The link will be relative to the current URL unless it either
 * A) starts with http(s):// in which case it is an external link
 * B) starts with / which will route it at the domain e.g. fund.givewise.ca/
 * Normally this component will be hosted on the fund page
 * and so URLs will be relative to /funds/:fundId
 * @param link
 * @param internal
 * @returns
 */
function ResolvedLink({ link, children, sx, ...props }) {
  if (link?.startsWith('https://') || link?.startsWith('http://')) {
    return (
      <Link href={link} target="_blank" sx={{ ...sx }} {...props}>
        {children}
      </Link>
    )
  }
  return (
    <Link to={link} sx={{ ...sx }} {...props}>
      {children}
    </Link>
  )
}
