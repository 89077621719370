import { Outlet } from 'react-router-dom'

import { Container, Stack, Text, NavTabs } from '../../components'

export function Charities() {
  const navigation = [
    { label: 'Search By Text', to: 'search-by-text', text: 'Search By Text' },
    { label: 'Search By Location', to: 'search-by-location', text: 'Search By Location' },
  ]
  return (
    <>
      <Container maxWidth="lg" sx={{ mb: 0 }}>
        <Text.H1>Give to Charity</Text.H1>
        <Stack>
          <NavTabs tabs={navigation} />
        </Stack>
      </Container>
      <Outlet />
    </>
  )
}
