import { Amount, Stack, Text, Tooltip } from '..'

export function FundStat({ Icon, text, amount = 0, tooltip, sx }) {
  return (
    <Stack sx={{ ...sx }}>
      <Tooltip title={tooltip}>
        <Text.Body
          sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end', width: '100%', gap: '5px' }}
        >
          <Text.Body>
            <Icon style={{ marginRight: '.5rem', marginTop: '.1rem', fontSize: '1rem' }} />
            {text && `${text}: `}
          </Text.Body>
          <Amount value={amount} />
        </Text.Body>
      </Tooltip>
    </Stack>
  )
}
