import { useEffect, useState } from 'react'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { Controller } from 'react-hook-form'

import { AdapterDateFns, DatePicker, Fields, FormControlLabel, LocalizationProvider, Switch } from '..'

export function Scheduled({ register, errors, control, getValues, setValue, entity = 'Contribution' }) {
  const now = new Date()
  const minScheduledDate = startOfDay(addDays(now, 1))
  const maxScheduledDate = endOfDay(addDays(now, 31))

  const [isFutureDate, setIsFutureDate] = useState(!!getValues('processingDate'))
  const [pickerValue, setPickerValue] = useState(getValues('processingDate'))
  useEffect(() => setValue('processingDate', isFutureDate ? pickerValue : null), [isFutureDate, pickerValue])

  return (
    <>
      <FormControlLabel
        control={<Switch checked={isFutureDate} onChange={(e) => setIsFutureDate(e.target.checked)} />}
        label="Schedule for Future (maximum 31 days in advance)"
      />
      {isFutureDate && (
        <Controller
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                label={`Schedule ${entity} For:`}
                value={pickerValue}
                onChange={(newValue) => setPickerValue(typeof newValue === 'string' ? new Date(newValue) : newValue)}
                disablePast
                minDate={minScheduledDate}
                maxDate={maxScheduledDate}
                renderInput={(params) => (
                  <Fields.Text
                    {...params}
                    {...register('processingDate', {
                      validate: {
                        notInPast: (v) =>
                          new Date(v) >= minScheduledDate ||
                          `The date for a Scheduled ${entity} must be in the future.`,
                        tooFarInFuture: (v) =>
                          new Date(v) <= maxScheduledDate || `${entity} date must not be more than 31 days in advance.`,
                      },
                    })}
                    error={!!errors?.processingDate}
                    helperText={errors?.processingDate?.message}
                  />
                )}
              />
            </LocalizationProvider>
          )}
          control={control}
          name="processingDate"
          defaultValue={addDays(new Date(), 1)}
        />
      )}
    </>
  )
}
