import { useQuery, useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  BlockQuote,
  CardBox,
  Container,
  Stack,
  Text,
  Fields,
  InputAdornment,
  Button,
  Dialog,
  useAlert,
  useDialog,
} from '../../../components'
import { fromAmountField } from '../../../utils'

export function Cheque() {
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const { number } = fund
  const { fundId } = useParams()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const client = useApolloClient()

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0.0,
    },
  })

  const closeSuccessDialog = () => {
    resetForm()
    closeDialog()
  }

  const onSubmit = async ({ amount }) => {
    try {
      await client.mutate({
        mutation: mutations.contributions.createContributionRecord,
        variables: {
          fundId: Number(fundId),
          amount: fromAmountField(amount),
          paymentType: 2,
          description: ' ',
          taxReceipted: true,
        },
        refetchQueries: [
          { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
          { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
          { query: queries.paymentMethods.myLinkedBanks },
        ],
        awaitRefetchQueries: true,
      })
      openDialog()
    } catch (e) {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  const { errors = {} } = formState

  return (
    <Container maxWidth="lg">
      <Stack spacing={0}>
        <CardBox title="Cheque">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Fields.Text
                label="Amount of Cheque"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputComponent: Fields.Amount,
                  inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: 0,
                  },
                }}
                {...register('amount', {
                  required: 'This field is required',
                  validate: {
                    positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                    min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
                  },
                })}
              />
              <Text.Body>
                To send your contribution by cheque, please include the information below. Be sure to include your fund
                number on your cheque to prevent delays in processing.
              </Text.Body>
              <BlockQuote>
                <Text.Bold>Payee</Text.Bold>
                <Text.Body>GiveWise Foundation Canada</Text.Body>
                <Text.Bold>Memo (Must be included)</Text.Bold>
                <Text.Body>Giving Fund #{number}</Text.Body>
                <Text.Bold>Mailing Address</Text.Bold>
                <Text.Body>
                  3251 Boxwood Court <br />
                  Abbotsford BC <br />
                  V3G 0A6
                </Text.Body>
              </BlockQuote>

              <Text.Body>
                Your contribution will post to your account as soon as your cheque clears; however, your tax receipt
                will reflect the post mark date on the envelope.
              </Text.Body>
              <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
                Submit
              </Button>
              <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
            </Stack>
          </form>
        </CardBox>
      </Stack>

      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" />
        <div>
          <p>You have successfully initiated a contribution.</p>
          <p>To complete the contribution, you will now need to mail the cheque with the following details:</p>
          <p>
            <strong>Payee:</strong>
            <br />
            GiveWise Foundation Canada
          </p>
          <p>
            <strong>Memo (Must be included):</strong>
            <br />
            Giving Fund #{number}
          </p>
          <p>
            <strong>Mail Cheque To:</strong>
            <br />
            3251 Boxwood Court
            <br />
            Abbotsford, BC
            <br />
            V3G 0A6
          </p>
        </div>
        <Button
          onClick={closeSuccessDialog}
          to={`/funds/${fundId}/activity/giving-wallet`}
          sx={{ width: 'fit-content', position: 'absolute', right: '32px', bottom: '32px' }}
        >
          Done
        </Button>
      </Dialog>
    </Container>
  )
}
