import { useEffect, useState } from 'react'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { CircularProgress } from '@mui/material'

import { Text, Button, Stack } from '../components'
import { mutations, queries } from '../graphql'

export function BankAccountSetup({ onSuccess }) {
  const apolloClient = useApolloClient()
  const [isProcessing, setIsProcessing] = useState(false)

  const { data: { userHasBankAccount } = {}, loading: userHasBankAccountLoading } = useQuery(
    queries.paymentMethods.userHasBankAccount,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      pollInterval: isProcessing ? 1000 : 0,
    }
  )

  const { data: userData } = useQuery(queries.user.me)
  const userType = userData?.me?.profile?.userType

  const { data: { getZumrailsToken: addPaymentProfileToken } = {}, refetch: refetchZumrailsToken } = useQuery(
    queries.paymentMethods.getZumrailsToken,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [createZumRailsUser] = useMutation(mutations.paymentMethods.createZumRailsUser, {
    refetchQueries: [{ query: queries.paymentMethods.userHasBankAccount }],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    if (userHasBankAccount && !userHasBankAccountLoading && !isProcessing) {
      onSuccess?.()
    }
  }, [userHasBankAccount, userHasBankAccountLoading, isProcessing])

  useEffect(() => setIsProcessing(false), [])

  const handleRefetch = async () => {
    try {
      await apolloClient.refetchQueries({
        include: ['Charity', 'getCharityBankAccounts', 'userHasBankAccount'],
        updateCache: (cache) => {
          cache.evict({ fieldName: 'userHasBankAccount' })
          cache.evict({ fieldName: 'getCharityBankAccounts' })
        },
      })
    } catch (error) {
      console.error('Error refetching queries:', error)
    }
  }

  const loadIframe = () => {
    const token = addPaymentProfileToken
    setIsProcessing(true)

    // eslint-disable-next-line no-undef
    ZumRailsSDK.init({
      token,
      onLoad: () => {
        console.log('ZumRails SDK loaded')
      },
      onError: (error) => {
        console.error('ZumRails SDK error:', error)
        setIsProcessing(false)
      },
      onSuccess: async (data) => {
        try {
          await createZumRailsUser({
            variables: {
              data: {
                id: data.userId,
              },
            },
          })

          await handleRefetch()
        } catch (error) {
          console.error('Error creating ZumRails user:', error)
        } finally {
          setIsProcessing(false)
        }
      },
      onButtonClose: () => {
        console.log('ZumRails SDK closed')
        setIsProcessing(false)
        refetchZumrailsToken()
      },
    })
  }

  const renderContent = () => {
    if (userHasBankAccountLoading || isProcessing) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CircularProgress color="info" />
          <div style={{ marginLeft: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text.Body>
              {isProcessing ? 'Setting up your bank account...' : 'Loading bank account information...'}
            </Text.Body>
          </div>
        </div>
      )
    }

    if (userHasBankAccount === undefined) {
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <CircularProgress color="info" />
          <div style={{ marginLeft: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Text.Body>Verifying bank account status...</Text.Body>
          </div>
        </div>
      )
    }

    return (
      <Stack spacing={2}>
        <Text.Body>To connect your online bank account, please click the button below.</Text.Body>
        <Button onClick={loadIframe} disabled={isProcessing}>
          Connect Bank Account
        </Button>
      </Stack>
    )
  }

  return <Stack spacing={4}>{renderContent()}</Stack>
}

export default BankAccountSetup
