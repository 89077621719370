import { useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  Stack,
  Text,
  Scheduled,
  Recurring,
  useAlert,
  useDialog,
  validations,
} from '../../../components'
import { fromAmountField } from '../../../utils'
import * as Card from '../../../components/payment/credit-card'

export function CreditCard() {
  const { fundId } = useParams()
  const client = useApolloClient()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const cardApi = useRef()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors = {}, isSubmitting },
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
      isRecurring: false,
      recurringInterval: 'monthly',
    },
  })

  const closeSuccessDialog = () => {
    resetForm()
    closeDialog()
  }

  const onSubmit = async ({ multiUse, amount, paymentMethodId, isRecurring, recurringInterval, processingDate }) => {
    if (cardApi.current.isNewCard) {
      try {
        /* eslint-disable no-param-reassign */
        paymentMethodId = await cardApi.current.postToStripeAndSavePaymentMethod({ multiUse })
      } catch (e) {
        setAlert({
          message: `This card cannot be used to finalize the transaction: ${e.message || e.type}`,
          error: e.message,
          severity: 'error',
        })
        return
      }
    }

    try {
      await client.mutate({
        mutation: mutations.contributions.createContribution,
        variables: {
          data: {
            paymentMethodId,
            fundId: Number(fundId),
            amount: fromAmountField(amount),
            isRecurring,
            recurringInterval,
            processingDate,
          },
        },
        refetchQueries: [
          { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
          { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
          { query: queries.funds.myRecurring, variables: { fundId: Number(fundId) } },
          { query: queries.paymentMethods.myCreditCards },
        ],
        awaitRefetchQueries: true,
      })
      openDialog()
    } catch (e) {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={0}>
          <CardBox title="Credit Card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Fields.Text
                  label="Amount"
                  name="amount"
                  error={!!errors?.amount}
                  helperText={errors?.amount?.message}
                  InputProps={{
                    inputComponent: Fields.Amount,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: {
                      setValue: (value) => setValue('amount', value),
                      getValue: () => getValues('amount'),
                      defaultValue: 0,
                    },
                  }}
                  {...register('amount', {
                    ...{ min: { value: 1, message: 'Must be at least 1' } },
                    ...validations.required,
                    ...validations.number,
                  })}
                />
                <Card.Select ref={cardApi} register={register} />
                <Scheduled
                  register={register}
                  errors={errors}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                />
                <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
                <Card.FeesNotice />
                <Button disabled={isSubmitting} type="submit" sx={{ alignSelf: 'flex-end' }}>
                  Submit
                </Button>
                <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
              </Stack>
            </form>
          </CardBox>
        </Stack>
      </Container>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" content="You have successfully created a contribution.">
          <Button to={`/funds/${fundId}/activity/giving-wallet`}>Done</Button>
        </Acknowledge.Success>
      </Dialog>
    </>
  )
}
