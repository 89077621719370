import { useState, useRef, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, useController } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'

import {
  Acknowledge,
  Button,
  Box,
  Checkbox,
  Container,
  ContentBox,
  Fields,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  Typography,
  Row,
  Select,
  Stack,
  Switch,
  Text,
  useAlert,
  RadioGroup,
  CharityLogo,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'

export function SignUpSuccess() {
  return (
    <Container maxWidth="sm">
      <Acknowledge.Success
        title="Welcome to GiveWise and our community of generous givers!"
        content={
          <Text.Body>
            Please check your email for a link to confirm your email and log in to your account. If you do not receive a
            confirmation email, please check your junk mail or email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> for assistance.
          </Text.Body>
        }
      >
        <Button to="/login">Log In</Button>
      </Acknowledge.Success>
    </Container>
  )
}

function CharityDetailsConfirmForm({ formDefaultData = {}, myDescription, mySocialMedia }) {
  const { id: charityId } = useParams()
  const [isCorporation, setIsCorporation] = useState(false)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { afterLogin } = state ?? {}
  const { register, handleSubmit, formState, setError, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...formDefaultData,
      firstName: formDefaultData.firstName === formDefaultData.accountName ? ' ' : formDefaultData.firstName,
      lastName: formDefaultData.lastName === formDefaultData.id ? ' ' : formDefaultData.lastName,
      description: formDefaultData.description,
      tagline: formDefaultData.tagline,
    },
  })
  const { errors = {} } = formState
  const [logoFile, setLogoFile] = useState()
  const [newLogo, setNewLogo] = useState(null)
  const [newLogoBinary, setNewLogoBinary] = useState(null)

  const { data: { getMyCharityLogo: myLogo } = {}, fetchLogo } = useQuery(queries.charities.getMyCharityLogo, {
    variables: { id: charityId },
    onCompleted: () => console.log('FETCHED MY LOGO', myLogo),
    onError: () => console.log('FAILED TO FETCH MY LOGO'),
  })
  const uploadNewLogo = (e) => {
    const file = e.target.files[0]
    setLogoFile(file)
    setNewLogo(URL.createObjectURL(file))

    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64Data = btoa(event.target.result) // Convert binary data to base64
        setNewLogoBinary(base64Data)
      }
      reader.readAsBinaryString(file)
    }
  }

  const [updateCharity, { data, loading }] = useMutation(mutations.charities.updateCharityCustom, {
    onCompleted: () => setAlert({ message: 'Charity Profile Updated', severity: 'success' }),
    onError: () => setAlert({ message: 'Charity Profile Update Failed', severity: 'error' }),
    refetchQueries: ['Charity', 'Me'],
  })
  const [updateMyCharityDescription, { dataDescription, loadingDescription }] = useMutation(
    mutations.charities.updateMyCharityDescription,
    {
      // onCompleted: () => setAlert({ message: 'Charity Description Updated', severity: 'success' }),
      // onError: () => setAlert({ message: 'Charity Description Update Failed', severity: 'error' }),
      refetchQueries: ['getMyCharityDescription'],
    }
  )
  const [updateMyCharityLogo, { dataLogo, loadingLogo }] = useMutation(mutations.charities.updateMyCharityLogo, {
    // onCompleted: () => setAlert({ message: 'Charity Logo Updated', severity: 'success' }),
    // onError: () => setAlert({ message: 'Charity Logo Update Failed', severity: 'error' }),
    refetchQueries: ['getMyCharityLogo'],
  })

  const [logoLanguageConfirmed, setLogoLanguageConfirmed] = useState(false)
  const logoConfirmLanguage = `
    We grant GiveWise a non-exclusive, royalty-free, revocable license to use the 
    Charity’s logo on GiveWise’s website, marketing materials, and other promotional content. 
    This license is granted solely for the purpose of promoting the Charity’s profile and facilitating 
    donor recognition on GiveWise’s platform
    `
  const confirmLogoLanguage = () => {
    setLogoLanguageConfirmed(!logoLanguageConfirmed)
  }
  const [pageLanguageConfirmed, setPageLanguageConfirmed] = useState(false)
  const pageConfirmLanguage = `
    It is verified that all provided details are accurate and correct to the best of our knowledge.
  `
  const confirmPageLanguage = () => {
    setPageLanguageConfirmed(!pageLanguageConfirmed)
  }

  const [updateSocialMediaLink] = useMutation(mutations.charities.updateSocialMediaLink, {
    refetchQueries: ['getMyCharityDescriptionSocialMedia'],
  })
  const [createSocialMediaLink] = useMutation(mutations.charities.createSocialMediaLink, {
    refetchQueries: ['getMyCharityDescriptionSocialMedia'],
  })

  const onSubmit = ({
    mailingAddress,
    city,
    province,
    contactPhoneNumber,
    emailAddress,
    websiteAddress,
    title,
    firstName,
    lastName,
    phone,
  }) => {
    const updateData = {
      mailingAddress,
      city,
      province,
      emailAddress,
      websiteAddress,
      contactPhoneNumber,
      title,
      firstName,
      lastName,
      phone,
    }
    Object.keys(updateData).forEach((k) => {
      updateData[k] = updateData[k] ? updateData[k].trim() : ''
    })
    updateCharity({
      variables: {
        id: charityId,
        data: updateData,
      },
    })
    const newDescription = getValues('description')
    const newTagline = getValues('tagline')
    updateMyCharityDescription({
      variables: {
        data: {
          charityId,
          description: newDescription || '',
          tagline: newTagline || '',
        },
      },
    })

    const newInstagramLink = getValues('instagramLink')
    const newFacebookLink = getValues('facebookLink')
    const newLinkedinLink = getValues('linkedinLink')
    // Update social media links
    const socialMediaLinks = [
      { id: mySocialMedia.instagramId, link: newInstagramLink, platform: 'Instagram' },
      { id: mySocialMedia.facebookId, link: newFacebookLink, platform: 'Facebook' },
      { id: mySocialMedia.linkedinId, link: newLinkedinLink, platform: 'Linkedin' },
    ]

    socialMediaLinks.forEach(({ id, link, platform }) => {
      if (link) {
        if (id) {
          // If the link exists, update it
          updateSocialMediaLink({
            variables: {
              id,
              link: link.trim(),
              platform,
              charityId,
            },
          })
        } else {
          // If the link does not exist, create it
          createSocialMediaLink({
            variables: {
              data: {
                charityId,
                link: link.trim(),
                platform,
              },
            },
          })
        }
      }
    })

    if (newLogo) {
      updateMyCharityLogo({
        variables: {
          data: {
            charityId,
            logoPath: null,
            imagePath: null,
            logo: newLogoBinary,
          },
        },
      })
    }
    setLogoLanguageConfirmed(false)
    setPageLanguageConfirmed(false)
    setLogoFile(null)
    document.getElementById('newLogoInput').value = null
  }

  return (
    <Container maxWidth="lg">
      <Stack spacing={4}>
        <Row style={{ margin: 0 }} justifyContent="space-between">
          <Box>
            <Text.H4 component="h1" data-testid="title">
              {/* {charity?.accountName}{' '} */}
              Account Details
            </Text.H4>
            <br />
            <Text.H7>These details will be reflected on the public facing charity details page.</Text.H7>
            {/* <br />
            <br /> */}
            {/* <Text.Body>BN / CRA Registration Number: {charity?.id}</Text.Body> */}
          </Box>
        </Row>
        <ContentBox style={{ marginTop: 25 }} border>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="flex-start">
              <Text.H6>Charity Logo & Description</Text.H6>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '75%' }}>
                  <Fields.Text
                    multiline
                    rows={7}
                    inputProps={{ 'data-testid': 'description' }}
                    label="Description *"
                    error={!!errors.Description}
                    helperText={errors.description?.message}
                    {...register('description', { ...validations.required })}
                    defaultValue={formDefaultData?.description}
                    onChange={(e) => setValue('description', e.target.value)}
                    sx={{ marginBottom: '20px' }}
                  />
                  <Fields.Text
                    multiline
                    rows={1}
                    inputProps={{ 'data-testid': 'tagline' }}
                    label="Tagline *"
                    error={!!errors.Tagline}
                    helperText={errors.tagline?.message}
                    {...register('tagline', { ...validations.required })}
                    defaultValue={formDefaultData?.tagline || ''}
                    onChange={(e) => setValue('tagline', e.target.value)}
                  />
                </div>
                <div style={{ marginLeft: '10px', marginTop: '2px', maxWidth: '25%' }}>
                  <div style={{ height: '120px' }}>
                    {logoFile ? (
                      <CharityLogo temporarySrc={newLogo} />
                    ) : (
                      <CharityLogo temporarySrc={`data:image/png;base64,${myLogo?.logo}`} link={myLogo?.logoPath} />
                    )}
                  </div>
                  <div>
                    <Text.H7>Upload New Logo Image (jpg/png):</Text.H7>
                    <input id="newLogoInput" type="file" accept=".jpg, .jpeg, .png" onChange={uploadNewLogo} />
                    <button
                      type="button"
                      onClick={() => {
                        setLogoFile(null)
                        setNewLogo(null)
                        setNewLogoBinary(null)
                        document.getElementById('newLogoInput').value = null
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <Text.H6>Charity Address</Text.H6>
              <Fields.Text
                inputProps={{ 'data-testid': 'mailingAddress' }}
                label="Mailing Address *"
                error={!!errors.mailingAddress}
                helperText={errors.mailingAddress?.message}
                {...register('mailingAddress', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'city' }}
                label="City *"
                error={!!errors.city}
                helperText={errors.city?.message}
                {...register('city', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'province' }}
                label="Province *"
                error={!!errors.province}
                helperText={errors.province?.message}
                {...register('province', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'postalCode' }}
                label="Postal Code *"
                error={!!errors.postalCode}
                helperText={errors.postalCode?.message}
                {...register('postalCode', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'country' }}
                label="Country *"
                error={!!errors.country}
                helperText={errors.country?.message}
                {...register('country', { ...validations.required })}
              />
              <Text.H6>Charity Contact Info</Text.H6>
              <Fields.Text
                inputProps={{ 'data-testid': 'contactPhoneNumber' }}
                label="Charity Phone Number *"
                error={!!errors.contactPhoneNumber}
                helperText={errors.contactPhoneNumber?.message}
                {...register('contactPhoneNumber', { ...validations.required, ...validations.phoneNumber })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'emailAddress' }}
                label="Charity Email Address"
                error={!!errors.emailAddress}
                helperText={errors.emailAddress?.message}
                {...register('emailAddress', { ...validations.email })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'websiteAddress' }}
                label="Charity Website Address"
                error={!!errors.websiteAddress}
                helperText={errors.websiteAddress?.message}
                {...register('websiteAddress')}
              />
              <Text.H6>Social Media Links</Text.H6>
              <Fields.Text
                inputProps={{ 'data-testid': 'Instagram' }}
                label="Instagram Link"
                error={!!errors.contactPhoneNumber}
                helperText={errors.contactPhoneNumber?.message}
                defaultValue={formDefaultData?.instagramLink}
                {...register('instagramLink')}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'Facebook' }}
                label="Facebook Link"
                error={!!errors.emailAddress}
                helperText={errors.emailAddress?.message}
                defaultValue={formDefaultData?.facebookLink}
                {...register('facebookLink')}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'Linkedin' }}
                label="Linkedin Link"
                error={!!errors.websiteAddress}
                helperText={errors.websiteAddress?.message}
                defaultValue={formDefaultData?.linkedinLink}
                {...register('linkedinLink')}
              />

              <Text.H6>User Details</Text.H6>
              <Text.Subtitle>
                Please confirm the details of the user who will be accessing the charity portal.
                <br />
                These details will NOT be shared on the public facing charity details page.
              </Text.Subtitle>
              <Fields.Text
                inputProps={{ 'data-testid': 'firstName' }}
                label="First Name *"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                {...register('firstName', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'lastName' }}
                label="Last Name *"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                {...register('lastName', { ...validations.required })}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'title' }}
                label="Job Title/Role"
                error={!!errors.title}
                helperText={errors.title?.message}
                {...register('title')}
              />
              <Fields.Text
                inputProps={{ 'data-testid': 'phoneNumber' }}
                type="text"
                label="Phone Number"
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                {...register('phone', { ...validations.phoneNumber })}
              />
            </Stack>
            <Row justifyContent="space-between" spacing={4} sx={{ marginTop: '24px ' }}>
              <div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <FormControlLabel
                    sx={{ alignItems: 'flex-start' }}
                    control={
                      <Checkbox
                        sx={{ marginTop: -1, marginRight: -1 }}
                        checked={pageLanguageConfirmed}
                        onChange={(e) => {
                          confirmPageLanguage()
                        }}
                      />
                    }
                  />
                  <Text.Caption>{pageConfirmLanguage}</Text.Caption>
                </div>
                {logoFile && (
                  <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
                    <FormControlLabel
                      sx={{ alignItems: 'flex-start' }}
                      control={
                        <Checkbox
                          sx={{ marginTop: -1, marginRight: -1 }}
                          checked={logoLanguageConfirmed}
                          onChange={(e) => {
                            confirmLogoLanguage()
                          }}
                        />
                      }
                    />
                    <Text.Caption>{logoConfirmLanguage}</Text.Caption>
                  </div>
                )}
              </div>
              <Button
                type="submit"
                disabled={loading || !pageLanguageConfirmed || (logoFile && !logoLanguageConfirmed)}
                data-testid="signUpButton"
                sx={{ minWidth: '150px', minHeight: '50px' }}
              >
                Save
              </Button>
              {errors.form && <FormHelperText error={!!errors.form}>{errors.form.message}</FormHelperText>}
            </Row>
            <Alert {...alertProps} />
          </form>
        </ContentBox>
      </Stack>
    </Container>
  )
}

export function CharityDetailsConfirm() {
  const { id: charityId } = useParams()
  const { data: { charity } = {}, loading: charityLoading } = useQuery(queries.charities.charity, {
    variables: { charityId },
  })
  const { data: { me } = {}, loading: loadingUser } = useQuery(queries.user.me)
  const { data: { getMyCharityDescription: myDescription } = {}, loading: descriptionLoading } = useQuery(
    queries.charities.getMyCharityDescription,
    {
      variables: { id: charityId },
    }
  )

  const { data: { getMyCharityDescriptionSocialMedia: mySocialMedia } = {}, loading: socialMediaLoading } = useQuery(
    queries.charities.getMyCharityDescriptionSocialMedia,
    {
      variables: { id: charityId },
    }
  )
  const isLoading = charityLoading || loadingUser || descriptionLoading

  if (isLoading) {
    return null
  }

  if (me && charity) {
    const charityUser = me.charityUsers.find((cu) => cu.userId === me.id)
    const socialMediaData = mySocialMedia.reduce((acc, socialMedia) => {
      switch (socialMedia.platform) {
        case 'Instagram':
          acc.instagramId = socialMedia.id
          acc.instagramLink = socialMedia.link
          break
        case 'Facebook':
          acc.facebookId = socialMedia.id
          acc.facebookLink = socialMedia.link
          break
        case 'Linkedin':
          acc.linkedinId = socialMedia.id
          acc.linkedinLink = socialMedia.link
          break
        // Add more platforms if needed
        default:
          break
      }
      return acc
    }, {})
    return (
      <CharityDetailsConfirmForm
        formDefaultData={{
          ...charity,
          title: charityUser.title,
          firstName: me.profile.firstName,
          lastName: me.profile.lastName,
          phone: me.profile.phone,
          description: myDescription ? myDescription.description : 'no description found',
          ...socialMediaData,
          tagline: myDescription ? myDescription.tagline : 'no tagline found',
        }}
        myDescription={myDescription}
        mySocialMedia={socialMediaData}
      />
    )
  }

  return ''
}
