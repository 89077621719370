import { useState, useEffect } from 'react'

/* Not sure I'm doing it right.  https://reactjs.org/docs/hooks-custom.html
 * Might need to use some react context singleton thing to manage multiple
 * invocations of `useTitle` if that ever happens. */
export function useTitle(newTitle) {
  const [originalTitle] = useState(document.title)

  useEffect(() => {
    document.title = newTitle ? `${newTitle} › ${originalTitle}` : originalTitle

    return () => {
      document.title = originalTitle
    }
  }, [newTitle])
}
