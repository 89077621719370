import { Box as MuiBox } from '@mui/material'

export function ContentBox({ children, sx, border, ...props }) {
  return (
    <MuiBox
      sx={{
        border: border ? '1px solid lightGray' : 'none',
        borderRadius: border ? '10px' : '0px',
        backgroundColor: 'white',
        width: '100%',
        boxSizing: 'border-box',
        ...sx,
      }}
      p={4}
      {...props}
    >
      {children}
    </MuiBox>
  )
}
