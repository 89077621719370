import { Container, Stack, Text } from '../components'

export function NoMatch({ children, title = 'Not Found!' }) {
  return (
    <Container maxWidth="lg">
      <Stack>
        <Text.H1>{title}</Text.H1>
        {children}
      </Stack>
    </Container>
  )
}
