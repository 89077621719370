import { Icons, Row, Stack, Text } from '..'

export function Success({ title, content, children }) {
  return (
    <Row alignItems="center" spacing={4}>
      <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
      <Stack spacing={2}>
        {title && <Text.H5 data-testid="signupSuccessMessage">{title}</Text.H5>}
        {typeof content === 'string' ? <Text.Body>{content}</Text.Body> : content}
        {children && <Row sx={{ justifyContent: { md: 'flex-end' } }}>{children}</Row>}
      </Stack>
    </Row>
  )
}
