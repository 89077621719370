import { gql } from '@apollo/client'

export const updateFund = gql`
  mutation UpdateFund($data: UpdateFundInput!) {
    updateFund(data: $data) {
      id
      name
    }
  }
`

export const giveToGenerosityFundAsGuest = gql`
  mutation giveToGenerosityFundAsGuest($data: GiveToGenerosityFundAsGuest!) {
    giveToGenerosityFundAsGuest(data: $data)
  }
`

export const startIntentToGiveToGenerosityFund = gql`
  mutation startIntentToGiveToGenerosityFund($data: StartIntentToGiveToGenerosityFund!) {
    startIntentToGiveToGenerosityFund(data: $data)
  }
`

export const giveToGenerosityFundAsUser = gql`
  mutation giveToGenerosityFundAsUser($data: GiveToGenerosityFundAsUser!) {
    giveToGenerosityFundAsUser(data: $data) {
      amount
    }
  }
`
