import { useEffect, useState } from 'react'

import { Fields, validations } from '..'
import { IconButton, Icons, InputAdornment } from '../..'

export function NewPassword({ register, errors, getValues }) {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const [zxcvbn, setZxcvbn] = useState(null)

  useEffect(() => {
    import('zxcvbn').then((z) => {
      setZxcvbn(z)
    })
  }, [])

  if (!zxcvbn) return <p>Loading....</p>

  const isSecure = () => {
    const {
      score = 0,
      feedback: { suggestions = [] },
    } = zxcvbn.default(getValues('password'), [getValues('email')])
    return score >= 3 || `Too weak${suggestions.length > 0 ? `: ${suggestions.join(' ')}` : ''}`
  }

  const matchesPassword = () => getValues('passwordConfirmation') === getValues('password') || 'Must match password'

  return (
    <>
      <Fields.Text
        label="Password *"
        error={!!errors.password}
        helperText={
          errors.password?.message || 'Password must contain multiple words or a mix of words, symbols, and numbers'
        }
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          inputProps: {
            'data-testid': 'password',
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Icons.Visibility /> : <Icons.VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register('password', { ...validations.required, validate: { isSecure } })}
      />
      <Fields.Text
        label="Repeat Password *"
        error={!!errors.passwordConfirmation}
        helperText={errors.passwordConfirmation?.message}
        type={showPasswordConfirmation ? 'text' : 'password'}
        InputProps={{
          inputProps: {
            'data-testid': 'repeatPassword',
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                {showPassword ? <Icons.Visibility /> : <Icons.VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register('passwordConfirmation', { ...validations.required, validate: { matchesPassword } })}
      />
    </>
  )
}
