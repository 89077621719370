import { useParams } from 'react-router-dom'

import { FormControl, FormControlLabel, FormHelperText, InputLabel, Link, MenuItem, Text, Select, Switch } from '..'

export function Recurring({ register, errors, getValues, setValue }) {
  const { fundId } = useParams()

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={getValues('isRecurring')}
            {...register('isRecurring')}
            onChange={(e) => {
              setValue('isRecurring', e.target.checked, { shouldValidate: true })
            }}
          />
        }
        label="Recurring"
      />
      {!!getValues('isRecurring') && (
        <>
          <FormControl fullWidth error={!!errors?.recurringInterval?.message}>
            <InputLabel id="demo-simple-select-label">Interval</InputLabel>
            <Select
              required
              value={getValues('recurringInterval')}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              aria-label="Recurring Interval"
              label="Interval"
              {...register('recurringInterval')}
              onChange={(e) => {
                setValue('recurringInterval', e.target.value, { shouldValidate: true })
              }}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="yearly">Annually</MenuItem>
            </Select>
            {errors?.recurringInterval && <FormHelperText>{errors?.recurringInterval?.message}</FormHelperText>}
          </FormControl>
          <Text.Caption>
            Recurring gifts can be cancelled anytime from the{' '}
            <Link to={`/funds/${fundId}/activity/recurring`}>Giving Fund Activity page</Link>.
          </Text.Caption>
        </>
      )}
    </>
  )
}
