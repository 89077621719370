import { createTheme } from '@mui/material/styles'

import palette from '../palette.json'

export const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: palette.white,
    },
    text: {
      primary: palette.black,
      secondary: palette.black,
      disabled: palette.black,
      hint: palette.black,
    },
    primary: {
      main: palette.blue,
      light: palette.dark.blue,
      dark: palette.light.blue,
      contrastText: palette.black,
    },
    secondary: {
      main: palette.yellow,
      light: palette.light.yellow,
      dark: palette.dark.yellow,
      contrastText: palette.black,
    },
    error: {
      main: palette.red,
      light: palette.light.red,
      dark: palette.dark.red,
      contrastText: palette.white,
    },
    warning: {
      main: palette.orange,
      light: palette.light.orange,
      dark: palette.dark.orange,
      contrastText: palette.black,
    },
    info: {
      main: palette.dark.blue,
      light: palette.light.blue,
      dark: palette.dark.blue,
      contrastText: palette.black,
    },
    success: {
      main: palette.green,
      light: palette.light.green,
      dark: palette.dark.green,
      contrastText: palette.black,
    },
    divider: palette.black,
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h7: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 500,
    },
    button: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
    },
    overline: {
      fontWeight: 500,
    },
  },
  sidebar: {
    width: 250,
    closedWidth: 50,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: palette.dark.blue,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.dark.blue,
          fontWeight: 400,
          textDecorationColor: `${palette.dark.blue} !important`,
          '& span': {
            color: `${palette.dark.blue} !important`,
            textDecoration: `underline ${palette.dark.blue} !important`,
          },
        },
      },
    },
    RaLabeled: {
      styleOverrides: {
        root: {
          '& .RaLabeled-label': {
            fontWeight: 400,
            color: palette.darker.grey,
          },
        },
      },
    },
  },
})
