import React, { useEffect, useState } from 'react'
import {
  TextField,
  Button,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
  Checkbox,
  Tabs,
  Tab,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { mutations, queries } from '../../graphql'

function DonationReasons() {
  const [tabIndex, setTabIndex] = useState(0)
  const { id: charityId } = useParams()
  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId },
  })
  const [deleteReasonId, setDeleteReasonId] = useState(null)
  const [updateReasons, setUpdateReasons] = useState([])
  const [dirty, setDirty] = useState(false)
  const [createPurposeOfGift] = useMutation(mutations.charities.createPurposeOfGift, {
    variables: {
      data: {
        reason: '',
        charityId: charity?.id,
      },
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })
  const [deletePurposeOfGift] = useMutation(mutations.charities.deletePurposeOfGift, {
    variables: {
      id: deleteReasonId,
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })
  const [updatePurposeOfGift] = useMutation(mutations.charities.updatePurposeOfGift, {
    variables: {
      data: updateReasons.map((r) => ({
        id: r?.id,
        reason: r?.reason,
        active: r?.active,
      })),
    },
    refetchQueries: [
      {
        query: queries.charities.myCharityActivity,
        variables: { charityId },
      },
    ],
  })
  const [donationReasons, setDonationReasons] = useState([{ reason: '' }])
  const maxReasons = 15
  const remainingReasons = charity?.purposeOfGifts ? maxReasons - charity.purposeOfGifts.length : maxReasons

  useEffect(() => {
    if (charity?.purposeOfGifts) {
      let reasonsToLoad = []
      if (updateReasons.length > 0) {
        charity?.purposeOfGifts.forEach((r) => {
          const updateMatch = updateReasons.find((_r) => _r.id === r.id)
          if (updateMatch) {
            reasonsToLoad.push(updateMatch)
          } else {
            reasonsToLoad.push(r)
          }
        })
      } else {
        reasonsToLoad = [...charity.purposeOfGifts]
      }
      setDonationReasons(reasonsToLoad)
    }
  }, [charity])

  useEffect(() => {
    if (deleteReasonId) {
      deletePurposeOfGift()
    }
  }, [deleteReasonId])

  const handleReasonChange = (id, event, toggle = false) => {
    const idx = donationReasons.findIndex((r) => r.id === id)
    let reasonToChange
    if (toggle) {
      reasonToChange = {
        ...donationReasons.find((r) => r.id === id),
        active: event.target.checked,
      }
    } else {
      reasonToChange = {
        ...donationReasons.find((r) => r.id === id),
        reason: event.target.value,
      }
    }
    const newDonationReasons = [...donationReasons.filter((r) => r.id !== id)]
    newDonationReasons.splice(idx, 0, reasonToChange)
    setDonationReasons(newDonationReasons)
    const exists = updateReasons.findIndex((r) => r.id === reasonToChange.id)
    let newUpdatedReasons = [...updateReasons]
    if (exists > -1) {
      newUpdatedReasons.splice(exists, 1, reasonToChange)
    } else {
      newUpdatedReasons = [...updateReasons, reasonToChange]
    }
    setUpdateReasons(newUpdatedReasons)
    setDirty(true)
  }

  const handleAddReason = () => {
    if (charity?.purposeOfGifts.length < maxReasons) {
      const reasonsToLoad = []
      if (updateReasons.length > 0) {
        donationReasons.forEach((r) => {
          const updateMatch = updateReasons.find((_r) => _r.id === r.id)
          if (updateMatch) {
            reasonsToLoad.push(updateMatch)
          } else {
            reasonsToLoad.push(r)
          }
        })
      }
      console.log(reasonsToLoad)
      setDonationReasons([...reasonsToLoad, { reason: '' }])
    }
    createPurposeOfGift()
  }

  const handleRemoveReason = (id) => {
    setDonationReasons(donationReasons.filter((r) => r.id !== id))
    setDeleteReasonId(id)
  }

  const handleUpdateReason = () => {
    updatePurposeOfGift({
      variables: {
        data: updateReasons.map((r) => ({
          id: r?.id,
          reason: r?.reason,
          active: r?.active,
        })),
      },
      refetchQueries: [
        {
          query: queries.charities.myCharityActivity,
          variables: { charityId },
        },
      ],
    })
    setDirty(false)
  }

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  function reorderArrayByReason(array, reasons) {
    const specificOrder = reasons.map((reason) => array.find((item) => item.reason === reason))
    const filteredArray = array.filter((item) => !reasons.includes(item.reason))
    return specificOrder.concat(filteredArray)
  }

  const customTab = () => {
    if (!donationReasons || donationReasons[0].reason === '') {
      return ''
    }
    const ordered = reorderArrayByReason(donationReasons, ['General Support', 'In Memory Of', 'Other'])
    return (
      <>
        {ordered.map((entry, index) => (
          <Box key={entry.id} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <TextField
              fullWidth
              label={`Designation #${index + 1}`}
              variant="outlined"
              value={entry.reason}
              onChange={(event) => handleReasonChange(entry.id, event)}
            />
            <Checkbox
              checked={entry.active}
              disabled={entry.reason === 'General Support'}
              onChange={(event) => handleReasonChange(entry.id, event, true)}
            />
            {charity?.purposeOfGifts?.length > 0 && (
              <IconButton onClick={() => handleRemoveReason(entry.id)} color="error" sx={{ ml: 1 }}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddReason}
            disabled={donationReasons.length >= maxReasons}
          >
            Add New
          </Button>
          <Typography variant="caption" color="text.secondary">
            You can add <b>{remainingReasons}</b> more Gift Designations.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateReason}
            sx={{
              display: dirty ? 'auto' : 'none',
            }}
          >
            Save
          </Button>
        </Stack>
      </>
    )
  }

  const defualtTab = () => (
    <>
      <br />
      {donationReasons
        ? donationReasons
            .filter((r) => r.isDefault)
            .map((entry, index) => (
              <Box key={entry.id} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <Checkbox checked={entry.active} onChange={(event) => handleReasonChange(entry.id, event, true)} />
                <TextField
                  fullWidth
                  label={`Designation #${index + 1}`}
                  variant="outlined"
                  value={entry.reason}
                  onChange={(event) => handleReasonChange(entry.id, event)}
                />
                {charity?.purposeOfGifts?.length > 0 && (
                  <IconButton onClick={() => handleRemoveReason(entry.id)} color="error" sx={{ ml: 1 }}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))
        : ''}
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateReason}
          sx={{
            display: dirty ? 'auto' : 'none',
          }}
        >
          Save
        </Button>
      </Stack>
    </>
  )

  return (
    <Box sx={{ maxWidth: 560, mt: 3 }}>
      <Card>
        <CardHeader
          title="Purpose Of Gift"
          subheader={`Add or update the specific purposes and programs for which your charity accepts donations.
          These designations will restrict the gift to the specified purpose.`}
        />
        <CardContent>
          {/* <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Default" />
            <Tab label="Custom" />
          </Tabs> */}

          {customTab()}

          {/* <br />
          {tabIndex === 1 ? customTab() : defualtTab()} */}
        </CardContent>
      </Card>
    </Box>
  )
}

export default DonationReasons
