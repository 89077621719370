import { onError } from '@apollo/client/link/error'

function logout() {
  if (!window?.location?.href) return
  if (window?.location?.href?.includes('login')) return
  window.location.href = '/logout'
}

// Anytime we catch a 401, assume our token has expired and log the user out
export const logoutOnUnauthorizedLink = onError(({ networkError, graphQLErrors }) => {
  // Graphql Auth Guard reports 401s in graphqlErrors
  let graphqlUnauthorized
  if (Array.isArray(graphQLErrors)) {
    graphqlUnauthorized = graphQLErrors?.some((e) => e.extensions.response?.statusCode === 401)
  }
  const networkUnauthorized = networkError?.statusCode === 401

  if (graphqlUnauthorized || networkUnauthorized) logout()
})
