import {
  BooleanField,
  ChipField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  TextField,
  useRecordContext,
} from 'react-admin'
import ReactJson from 'react-json-view'

import { DeactivatePaylink } from '../../payment-method/actions/deactivate-paylink'
import { toDateString } from '../../../utils'
import { Link } from '../../../components'

export { NotesField as Notes } from '../notes'
export { ImageField as Image } from 'react-admin'

const camelToTitleCase = (string) =>
  string
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (str) => str.toUpperCase())

export function Json({ source = '', label, ...props }) {
  const record = useRecordContext()
  if (!record) return null
  if (!record[source]) return null
  return (
    <ReactJson
      collapsed
      enableClipboard={false}
      displayDataTypes={false}
      name={label ?? camelToTitleCase(source)}
      src={typeof record[source] === 'string' ? JSON.parse(record[source]) : record[source]}
      {...props}
    />
  )
}

export function Amount({ source = 'amount', ...props }) {
  return (
    <NumberField
      source={source}
      label={camelToTitleCase(source)}
      locales="en-ca"
      options={{ style: 'currency', currency: 'CAD' }}
      {...props}
    />
  )
}

export function Text({ source = '', ...props }) {
  return <TextField source={source} label={camelToTitleCase(source)} {...props} />
}

export function Boolean({ source = '', ...props }) {
  return <BooleanField source={source} label={camelToTitleCase(source)} {...props} />
}

export function RichText({ source = '', ...props }) {
  return <RichTextField source={source} label={camelToTitleCase(source)} {...props} />
}

export function Function({ source = '', ...props }) {
  return <FunctionField source={source} label={camelToTitleCase(source)} {...props} />
}

export function Reference({ source = '', ...props }) {
  return <ReferenceField addLabel source={source} label={camelToTitleCase(source)} {...props} />
}

export function Chip({ source = '', ...props }) {
  return <ChipField source={source} label={camelToTitleCase(source)} {...props} />
}

export function Date({ source = '', showTime = false, ...props }) {
  return (
    <Function
      source={source}
      label={camelToTitleCase(source)}
      render={(record) => toDateString(record[source], { showTime })}
      {...props}
    />
  )
}

export function Number({ source = '', ...props }) {
  return <NumberField source={source} label={camelToTitleCase(source)} {...props} />
}

export function Fund({ source = 'fundId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="MFund" {...props}>
      <TextField source="name" />
    </ReferenceField>
  )
}

export function FundNumber({ source = 'fundId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="MFund" {...props}>
      <TextField source="number" />
    </ReferenceField>
  )
}

export function Charity({ source = 'charityId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="Charity" {...props}>
      <TextField source="accountName" />
    </ReferenceField>
  )
}

export function CharityBN({ source = 'charityId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="Charity" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function User({ source = 'userId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="User" {...props}>
      <TextField source="name" />
    </ReferenceField>
  )
}

export function GrantRequest({ source = 'grantRequestId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="GrantRequest" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function DafTransfer({ source = 'dafTransferId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="DafTransfer" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function Contribution({ source = 'contributionId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="Contribution" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function BalanceAdjustment({ source = 'balanceAdjustmentId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="BalanceAdjustment" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function RecurrentGrantRequest({ source = 'recurrentGrantRequestId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="RecurrentGrantRequest" {...props}>
      <Date source="nextScheduledGrant" />
    </ReferenceField>
  )
}

export function RecurrentContribution({ source = 'recurrentContributionId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="RecurrentContribution" {...props}>
      <Date source="nextScheduledCharge" />
    </ReferenceField>
  )
}

export function PaymentMethod({ source = 'paymentMethodId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="PaymentMethod" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function PaymentMethods({ target = 'userId', deactivateOption = false, children, ...props }) {
  return (
    <ReferenceManyField reference="PaymentMethod" target={target} {...props} label="TEST">
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function source="id" render={({ id }) => <Link to={`/management/PaymentMethod/${id}/show`}>{id}</Link>} />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Chip source="status" />
          {deactivateOption && (
            <Function
              source="id"
              label="Deactivate Paylink"
              render={({ id, status }) => <DeactivatePaylink paymentMethodId={id} activeStatus={status} />}
            />
          )}
          <Json source="details" />
          <Text source="multiUse" label="Saved" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function Grant({ source = 'grantId', ...props }) {
  return (
    <ReferenceField link="show" source={source} reference="Grant" {...props}>
      <TextField source="id" />
    </ReferenceField>
  )
}

export function Contributions({ target = 'contributionId', children, ...props }) {
  return (
    <ReferenceManyField reference="Contribution" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function source="id" render={({ id }) => <Link to={`/management/Contribution/${id}/show`}>{id}</Link>} />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function RecurrentContributions({ target = 'recurrentContributionId', children, ...props }) {
  return (
    <ReferenceManyField reference="RecurrentContribution" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function
            source="id"
            render={({ id }) => <Link to={`/management/RecurrentContribution/${id}/show`}>{id}</Link>}
          />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function BalanceAdjustments({ target = 'balanceAdjustmentId', children, ...props }) {
  return (
    <ReferenceManyField reference="BalanceAdjustment" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function
            source="id"
            render={({ id }) => <Link to={`/management/BalanceAdjustment/${id}/show`}>{id}</Link>}
          />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function GrantRequests({ target = 'grantId', children, ...props }) {
  return (
    <ReferenceManyField reference="GrantRequest" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function source="id" render={({ id }) => <Link to={`/management/GrantRequest/${id}/show`}>{id}</Link>} />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <GrantRequest label="Grant Request" source="id" />
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function RecurrentGrantRequests({ target = 'recurrentGrantRequestId', children, ...props }) {
  return (
    <ReferenceManyField reference="RecurrentGrantRequest" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function
            source="id"
            render={({ id }) => <Link to={`/management/RecurrentGrantRequest/${id}/show`}>{id}</Link>}
          />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function DafTransfers({ target = 'dafTransferId', children, ...props }) {
  return (
    <ReferenceManyField reference="DafTransfer" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function source="id" render={({ id }) => <Link to={`/management/DafTransfer/${id}/show`}>{id}</Link>} />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <DafTransfer label="Share Fund" source="id" />
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}

export function RecurrentDafTransfers({ target = 'recurrentDafTransferId', children, ...props }) {
  return (
    <ReferenceManyField reference="RecurrentDafTransfer" perPage={1000} target={target} {...props}>
      {children ? (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <Function
            source="id"
            render={({ id }) => <Link to={`/management/RecurrentDafTransfer/${id}/show`}>{id}</Link>}
          />
          {children}
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} sx={{ m: 3 }}>
          <User label="User" />
          <Fund label="Fund" />
          <Amount label="Amount" />
        </Datagrid>
      )}
    </ReferenceManyField>
  )
}
