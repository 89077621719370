import { Box, Button, Icons, useConfirm, Text, Stack } from '..'

export function CancelButton({
  title,
  details,
  mutation,
  success = 'This transaction has been cancelled',
  error = 'Failed to cancel this transaction',
  variables,
  refetchQueries,
}) {
  const [, { setConfirm }] = useConfirm()
  const message = (
    <Stack spacing={0}>
      <Text.Body>Please confirm the cancellation of the following transaction:</Text.Body>
      {details}
    </Stack>
  )
  const onClick = async (_event) => {
    setConfirm((prev) => ({
      ...prev,
      title,
      message,
      mutation,
      success,
      error,
      variables,
      refetchQueries,
    }))
  }
  return (
    <Button sx={{ background: 'none', p: 0, '&:hover': { background: 'none !important' } }} onClick={onClick}>
      <Box sx={{ display: 'flex' }}>
        <Icons.Cancel />
        <Text.SR>Cancel</Text.SR>
      </Box>
    </Button>
  )
}
