import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { Box, DataGrid, FavouriteCharityButton, Link, Stack, Text, CharityLogo, FundLogo } from '../../components'
import SearchResultCardsContainer from './search-result-cards-container'

export function Results({ loading, count = 0, page, onPageChange, charities = [], funds = [], fundsCount = 0 }) {
  // Format funds object structure to match charity object
  const formattedFunds = funds.map((fund) => ({
    id: fund.id,
    accountName: fund.name,
    categoryCode: '',
    city: '',
    mailingAddress: '',
    province: '',
    // eslint-disable-next-line no-underscore-dangle
    __typeName: fund.__typename,
  }))
  const charitiesAndFunds = [...charities, ...formattedFunds]
  const resultCount = count + fundsCount
  const { fundId: myFundId } = useParams()

  const columns = [
    {
      field: 'logo',
      headerName: 'Logo',
      minWidth: 100,
      flex: 1,
      renderCell: ({ id, row: { categoryCode, logo, __typename } = {} }) =>
        __typename === 'Charity' ? (
          <CharityLogo charityId={id} categoryCode={categoryCode} logo={logo} />
        ) : (
          <FundLogo fundId={id} />
        ),
      valueGetter: ({ id, row: { __typename } = {} }) => ({ id, __typename }),
    },
    {
      field: 'charity',
      headerName: 'Charity',
      minWidth: 300,
      flex: 3,
      renderCell: ({ id, row: { accountName, __typename } = {} }) => (
        <Stack spacing={0}>
          <Text.Body>
            <Link
              to={__typename === 'Charity' ? `../${id}` : `/funds/${myFundId}/share-funds-with-generosity-fund/${id}`}
            >
              <strong style={{ whiteSpace: 'normal' }}>{accountName}</strong>
            </Link>
          </Text.Body>
          <Text.Body>{__typename === 'Charity' ? `BN #${id}` : `Generosity Fund`}</Text.Body>
        </Stack>
      ),
      valueGetter: ({ id, row: { accountName, __typename } = {} }) => ({ accountName, id, __typename }),
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      minWidth: 300,
      renderCell: ({ row }) => (
        <Stack spacing={0}>
          <Box>{row.mailingAddress}</Box>
          {row.city && row.province && (
            <Box>
              {row.city}, {row.province}
            </Box>
          )}
        </Stack>
      ),
      valueGetter: ({ row }) => ({ mailingAddress: row.mailingAddress, city: row.city, province: row.province }),
    },
    {
      field: 'favouriteCharity',
      headerName: 'Favourited',
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      renderCell: ({ id, row: { __typename } }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          {__typename === 'Charity' && <FavouriteCharityButton charityId={id} />}
        </Box>
      ),
    },
  ]

  const [gridDisplayState, setGridDisplayState] = useState('grid') // default view is the 3x3 grid
  const [gridDisplayToggle, setGridDisplayToggle] = useState('left')
  const handleLeftClick = () => {
    setGridDisplayState('grid')
  }
  const handleRightClick = () => {
    setGridDisplayState('list')
  }
  useEffect(() => {
    if (gridDisplayState === 'list') {
      setGridDisplayToggle('right')
    } else {
      setGridDisplayToggle('left')
    }
  }, [gridDisplayState])

  return (
    <>
      {gridDisplayState === 'grid' && gridDisplayToggle && (
        <SearchResultCardsContainer
          title="Results"
          charities={charities}
          charitiesAndFunds={charitiesAndFunds}
          viewToggleOnClicks={[handleLeftClick, handleRightClick]}
          viewToggleState={gridDisplayToggle}
          cardCount={resultCount}
          page={page}
          onPageChange={onPageChange}
        />
      )}

      {gridDisplayState === 'list' && gridDisplayToggle && (
        <DataGrid
          viewToggleOnClicks={[handleLeftClick, handleRightClick]}
          viewToggleState={gridDisplayToggle}
          disableExport
          autoRowHeight
          title="Results"
          rows={charitiesAndFunds}
          rowHeight={120}
          columns={columns}
          paginationMode="server"
          rowCount={resultCount}
          page={page}
          pageSize={9}
          onPageChange={onPageChange}
          loading={loading}
          showTroubleMessage
          hideColumnHeaders
          // sx={{
          //   '& .MuiDataGrid-virtualScroller': {
          //     marginTop: '5rem !important',
          //   },
          //   // '& .MuiDataGrid-columnHeaders': {
          //   //   maxHeight: '5rem !important',
          //   //   height: '5rem !important',
          //   //   lineHeight: '1.5rem !important',
          //   // },
          // }}
        />
      )}
    </>
  )
}
