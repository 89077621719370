import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { Dialog, useDialog } from '..'
import { LoginDialog } from './login-dialog'

export function useLoginDialog() {
  const navigate = useNavigate()
  const location = useLocation()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()

  const [loginDialogProps, setLoginDialogProps] = useState()
  const startLogin = useCallback(
    ({ ...props }) => {
      setLoginDialogProps({ ...props })
      openDialog()

      navigate({ hash: '#with-login' }, { state: { ...location.state, hashAfterLogin: location.hash ?? '' } })
    },
    [location.hash, location.state, navigate, openDialog]
  )

  const close = useCallback(() => {
    closeDialog()
    navigate(
      { hash: location.state.hashAfterLogin || '#' },
      { state: { ...location.state, hashAfterLogin: undefined } }
    )
  }, [closeDialog, location.state, navigate])

  return {
    loginDialog: useMemo(
      () => (
        <Dialog {...dialogProps} onClose={close}>
          <LoginDialog {...loginDialogProps} close={close} notification={loginDialogProps?.notification} />
        </Dialog>
      ),
      [close, dialogProps, loginDialogProps]
    ),
    startLogin,
  }
}
