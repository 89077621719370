import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router'

import { queries } from '../../graphql'
import { Amount, Box, Collapse, Container, FundSummary, IconButton, Icons, Link, Text, Tooltip } from '..'

export function Header() {
  const { pathname } = useLocation()
  const defaultExpanded = /^\/funds\/\d+$/.test(pathname)
  const { matches: isWideDisplay } = window.matchMedia('(min-width: 600px)')
  const [isOpenByUser, setIsOpenByUser] = useState(null)
  const isOpen = useMemo(
    () => isOpenByUser ?? (defaultExpanded && isWideDisplay),
    [isOpenByUser, defaultExpanded, isWideDisplay]
  )

  const { data: { me } = {} } = useQuery(queries.user.me)
  const { fundId } = useParams()
  const { data: { me: { fund = {} } = {} } = {} } = useQuery(queries.funds.myFund, {
    skip: !fundId,
    variables: { fundId: parseInt(fundId, 10) },
  })
  const { name } = me || {}
  const { name: fundName, number: fundNumber, totalBalance, givingWalletBalance, investmentWalletBalance } = fund || {}

  if (!me) return null
  if (!fund?.id) return null // return null if fund was not returned
  return (
    <Box component="header" sx={{ backgroundColor: 'var(--dark-blue)', m: 0, py: 3 }}>
      <Container maxWidth="lg" sx={{ mb: 0 }}>
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'noWrap' }}>
          <Text.H4 color="var(--white)">{name}</Text.H4>
          <IconButton sx={{ color: 'var(--white)' }} onClick={() => setIsOpenByUser(!isOpen)}>
            {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
          </IconButton>

          {/* commentted out until final approval of design */}
          {/* <Quicklinks fundId={fundId} /> */}
        </Box>
        <Box
          sx={{
            width: '100%',
            color: 'var(--white)',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {!isOpen && (
            <>
              <Text.Body sx={{ fontSize: '1.25rem' }}>
                <b>
                  {fundName} #{fundNumber}
                </b>
              </Text.Body>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Balance
                  Icon={Icons.Account}
                  amount={totalBalance}
                  tooltip="Represents total value of Giving Wallet plus Investment Account"
                />
                <Balance
                  Icon={Icons.Wallet}
                  amount={givingWalletBalance}
                  tooltip="Represents the value of funds available to give right now"
                />
                <Balance
                  Icon={Icons.Investments}
                  amount={investmentWalletBalance}
                  tooltip="Represents the value of funds that are 'locked' as they are in investment"
                />
              </Box>
            </>
          )}
        </Box>
        {fund && (
          <Collapse in={isOpen} unmountOnExit>
            <FundSummary sx={{ mb: 0 }} />
          </Collapse>
        )}
      </Container>
    </Box>
  )
}

function Balance({ Icon, amount, tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <Text.Body sx={{ fontSize: '1.25rem', color: 'var(--white)', display: 'flex', mr: 4, alignItems: 'center' }}>
        <Icon style={{ marginRight: '.5rem', fontSize: '1rem' }} />
        <Amount value={amount} />
      </Text.Body>
    </Tooltip>
  )
}

function ContactCallOut() {
  return (
    <Text.AltBody sx={{ color: 'var(--white)', textAlign: 'center', m: '16px auto 0' }}>
      We are always striving to improve. If you see anything out of place or have a question, please don’t hesitate to
      contact us:{' '}
      <Link
        sx={{
          color: 'var(--white)',
          textDecoration: 'none',
          '&:hover': { color: 'var(--white)', textDecoration: 'underline' },
        }}
        href="mailto:support@givewise.ca"
      >
        <b>support@givewise.ca</b>
      </Link>
    </Text.AltBody>
  )
}
