import { useMutation } from '@apollo/client'
import { COUNTRY_LIST, PROVINCES_LIST } from 'constants'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  CardBox,
  Fields,
  FormControl,
  Icons,
  InputLabel,
  MenuItem,
  Row,
  Select,
  Stack,
  Text,
  Tooltip,
  useAlert,
} from '../../components'
import { validations } from '../../components/form'
import { mutations } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

export function ProfileForm({ profile = {}, firstLogin, afterLogin }) {
  const navigate = useNavigate()
  const { address = {} } = profile
  const { register, handleSubmit, formState, setError, getValues, setValue, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState

  const country = watch('address.country')
  const province = watch('address.province')
  useEffect(() => {
    setValue('address.country', getValues('address.country'))
    setValue('address.province', getValues('address.province'))
  }, [country, province])

  const countryIsCanada =
    getValues('address.country') === 'Canada' ||
    getValues('address.country') === 'CAN' ||
    getValues('address.country') === 'CA'

  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [updateProfile] = useMutation(mutations.user.updateProfile, {
    onCompleted: () =>
      firstLogin ? navigate(afterLogin ?? '/funds') : setAlert({ message: 'Profile Updated', severity: 'success' }),
    onError: (serverErrors) => {
      parseAndSetFormErrors(serverErrors, setError)
      setAlert({ message: 'Update Profile Failed', severity: 'error' })
    },
  })

  const onSubmit = async (data) =>
    updateProfile({
      variables: { data },
    })

  return (
    <CardBox>
      <Text.H4>Personal Details</Text.H4>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Tooltip title="Address is used for tax receipts only, No physical mail will be sent by GiveWise">
            <Text.H6 sx={{ display: 'flex' }}>
              Address <Icons.Help sx={{ alignSelf: 'center', marginLeft: '.5rem' }} />
            </Text.H6>
          </Tooltip>
          <FormControl fullWidth>
            <InputLabel id="countryLabel">Country *</InputLabel>
            <Select
              inputProps={{ 'data-testid': 'country' }}
              value={getValues('address.country')}
              onChange={(e) => {
                setValue('address.country', e.target.value, { shouldValidate: true })
              }}
              label="Country *"
              labelId="countryLabel"
              aria-label="Country *"
              error={!!errors.address?.country}
              helpertext={errors?.address?.country?.message}
              defaultValue={address?.country ?? 'Canada'}
              {...register('address.country', { ...validations.required })}
            >
              {COUNTRY_LIST.map((c) => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {countryIsCanada && (
            <FormControl fullWidth>
              <InputLabel id="provinceLabel">Province *</InputLabel>
              <Select
                inputProps={{ 'data-testid': 'province' }}
                value={getValues('address.province')}
                onChange={(e) => {
                  setValue('address.province', e.target.value, { shouldValidate: true })
                }}
                label="Province *"
                labelId="provinceLabel"
                aria-label="Province *"
                error={!!errors.address?.province}
                helpertext={errors?.address?.province?.message}
                defaultValue={address?.province}
                {...register('address.province', { ...validations.required })}
              >
                {PROVINCES_LIST.map((p) => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {!countryIsCanada && (
            <Fields.Text
              inputProps={{ 'data-testid': 'province' }}
              type="text"
              label="Province / State *"
              error={!!errors.address?.province}
              helperText={errors?.address?.province?.message}
              defaultValue={address?.province}
              {...register('address.province', { ...validations.required })}
            />
          )}

          <Fields.Text
            inputProps={{ 'data-testid': 'city' }}
            type="text"
            label="City *"
            error={!!errors.address?.city}
            helperText={errors?.address?.city?.message}
            defaultValue={address?.city}
            {...register('address.city', { ...validations.required })}
          />
          <Fields.Text
            inputProps={{ 'data-testid': 'addrLineOne' }}
            type="text"
            label="Address Line One *"
            error={!!errors.address?.lineOne}
            helperText={errors?.address?.lineOne?.message}
            defaultValue={address?.lineOne}
            {...register('address.lineOne', { ...validations.required })}
          />
          <Fields.Text
            inputProps={{ 'data-testid': 'addrLineTwo' }}
            type="text"
            label="Address Line Two"
            error={!!errors.address?.lineTwo}
            helperText={errors?.address?.lineTwo?.message}
            defaultValue={address?.lineTwo}
            {...register('address.lineTwo')}
          />
          {countryIsCanada && (
            <Fields.Text
              inputProps={{ 'data-testid': 'postalCode' }}
              type="text"
              label="Postal Code *"
              error={!!errors.address?.postalCode}
              helperText={errors?.address?.postalCode?.message}
              defaultValue={address?.postalCode}
              {...register('address.postalCode', { ...validations.required, ...validations.postalCode })}
            />
          )}
          {!countryIsCanada && (
            <Fields.Text
              inputProps={{ 'data-testid': 'postalCode' }}
              type="text"
              label="Postal Code / Zip Code *"
              error={!!errors.address?.postalCode}
              helperText={errors?.address?.postalCode?.message}
              defaultValue={address?.postalCode}
              {...register('address.postalCode', { ...validations.required })}
            />
          )}

          <Text.H6>Contact</Text.H6>
          <Fields.Text
            inputProps={{ 'data-testid': 'phoneNumber' }}
            type="text"
            label="Phone Number *"
            error={!!errors.phone}
            helperText={errors?.phone?.message}
            defaultValue={profile?.phone}
            {...register('phone', { ...validations.required, ...validations.phoneNumber })}
          />

          <Row sx={{ marginTop: '2rem!important' }} alignSelf="flex-end">
            <Button type="submit" disabled={isSubmitting} data-testid="updateProfileButton">
              Update Profile
            </Button>
          </Row>
          <Alert {...alertProps} />
        </Stack>
      </form>
    </CardBox>
  )
}
