import { useState } from 'react'

import * as validations from '../validations'
import { Text } from './index'
import { IconButton, Icons, InputAdornment } from '../..'

export function Password({ register, errors = {}, label = 'Password *', name = 'password', autoComplete }) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Text
      data-testid={name}
      label={label}
      type={showPassword ? 'text' : 'password'}
      error={!!errors[name]}
      helperText={errors[name]?.message}
      autoComplete={autoComplete}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Icons.Visibility /> : <Icons.VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...register(name, { ...validations.required })}
    />
  )
}
