import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Container, ContentBox, Row, Link, Stack, Text } from '../../components'
import { LoginForm } from '../../components/login/login-form'

export function LoginModal({ params, dispatchStep, stepperState, ...props }) {
  const navigate = useNavigate()
  const onCompleted = () => {
    // @note the useAuth does share its state so we have to make a fake navigation to re-render with the updated login state
    navigate('.')
    dispatchStep({ action: 'LOGIN_SUCCESS', modal: null, state: { loggedInFromModal: true } })
  }

  return (
    <Container maxWidth="sm" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <MuiAlert severity="info">
        <MuiAlertTitle>Verify Account</MuiAlertTitle>
        Check your email for a verification email
      </MuiAlert>
      <Stack alignItems="center" spacing={4}>
        <ContentBox border>
          <LoginForm prefillEmail={params.prefillEmail} onCompleted={onCompleted} />
        </ContentBox>
        <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={4} pr={4}>
          <Text.Body variant="body2" data-testid="haveAccountText">
            To delete a GiveWise Giving Fund, please email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
          </Text.Body>
        </Row>
      </Stack>
    </Container>
  )
}
