import { useState, useRef } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, useController } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Radio from '@mui/material/Radio'
import { CORPORATION_USER_TYPE, INDIVIDUAL_USER_TYPE, COUNTRY_LIST, PROVINCES_LIST } from 'constants'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { Avatar, CardActionArea, CardHeader } from '@mui/material'

import { Business, CircleUser, HandHoldingHeart } from '../components/icons/icons'
import { parseAndSetFormErrors } from '../utils'
import {
  Acknowledge,
  Button,
  Container,
  ContentBox,
  Fields,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  Row,
  Select,
  Stack,
  Switch,
  Text,
  useAlert,
  Card,
} from '../components'
import { validations } from '../components/form'
import { mutations } from '../graphql'
import { SIGNUP_TYPE } from '../constants'

export function SignUpSuccess() {
  return (
    <Container maxWidth="sm">
      <Acknowledge.Success
        title="Welcome to GiveWise and our community of generous givers!"
        content={
          <Text.Body>
            Please check your email for a link to confirm your email and log in to your account. If you do not receive a
            confirmation email, please check your junk mail or email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> for assistance.
          </Text.Body>
        }
      />
    </Container>
  )
}

export function SignUp() {
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { selectedSignupType, afterLogin } = state ?? {}
  const isCorporation = selectedSignupType === SIGNUP_TYPE.business
  const { register, handleSubmit, formState, setError, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: JSON.parse(localStorage.getItem('guest_user_prefill_info') || 'null') || {
      address: {
        country: 'Canada',
      },
    },
  })
  const { errors = {} } = formState
  const captchaRef = useRef()
  const [captchaToken, setCaptchaToken] = useState()
  const { data: { captcha: captchaSiteKey } = {}, refetch: refetchCaptcha } = useQuery(gql`
    query Captcha {
      captcha
    }
  `)

  const [signUp, { loading }] = useMutation(mutations.user.signUp, {
    onCompleted: async () => {
      navigate('/sign-up/success')
    },
    onError: async (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)

      /* if we submitted a captcha, the server likely verified it and it can't
       * be used again; so reset the widget ... */
      captchaRef?.current?.resetCaptcha?.()

      if (newErrors?.message.includes('Email taken')) {
        setAlert({ message: 'An account with this email address already exists.', severity: 'error' })
      } else if (newErrors?.message === 'captcha required') {
        setAlert({ message: 'Please fill out the captcha on this form.', severity: 'error' })
        /* if we a captcha was not required before, it is now */
        if (!captchaSiteKey) await refetchCaptcha()
      } else {
        setAlert({ message: 'Signup Failed', severity: 'error' })
      }
    },
  })

  const onSubmit = ({
    firstName,
    middleInitials,
    lastName,
    email,
    phone,
    password,
    passwordConfirmation,
    corporateName,
    address,
  }) => {
    signUp({
      variables: {
        data: {
          firstName,
          middleInitials,
          lastName,
          corporateName,
          email,
          phone,
          password,
          passwordConfirmation,
          address,
          userType: selectedSignupType === SIGNUP_TYPE.business ? CORPORATION_USER_TYPE : INDIVIDUAL_USER_TYPE,
          afterLogin,
          captcha: captchaToken,
        },
      },
    })

    localStorage.removeItem('guest_user_prefill_info')
  }

  const signUpForm = () => (
    <>
      <ContentBox border>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControlLabel
            size="small"
            control={<Switch />}
            checked={isCorporation}
            onChange={(e) =>
              navigate(
                {},
                {
                  state: { selectedSignupType: e.target.checked ? SIGNUP_TYPE.business : SIGNUP_TYPE.personal },
                  replace: true,
                }
              )
            }
            label="Sign up as a corporation or an organization"
            sx={{ mb: 2 }}
          />
          <Stack alignItems="flex-start">
            {isCorporation && (
              <>
                <Fields.Text
                  inputProps={{ 'data-testid': 'corporateName' }}
                  label="Corporate Name *"
                  error={!!errors.corporateName}
                  helperText={errors.corporateName?.message}
                  {...register('corporateName', { ...validations.required })}
                />
                <Fields.Text
                  inputProps={{ 'data-testid': 'email' }}
                  label="Email *"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register('email', { ...validations.required, ...validations.email })}
                />
                <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
                <Text.Bold>Primary Contact Person</Text.Bold>
              </>
            )}
            <Fields.Text
              inputProps={{ 'data-testid': 'firstName' }}
              label="First Name *"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              {...register('firstName', { ...validations.required })}
            />
            <Fields.Text
              inputProps={{ 'data-testid': 'middleInitial' }}
              label="Middle Initials"
              error={!!errors.middleInitial}
              helperText={errors.middleInitial?.message}
              {...register('middleInitials')}
            />
            <Fields.Text
              inputProps={{ 'data-testid': 'lastName' }}
              label="Last Name *"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              {...register('lastName', { ...validations.required })}
            />
            {!isCorporation && (
              <>
                <Fields.Text
                  inputProps={{ 'data-testid': 'email' }}
                  label="Email *"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register('email', { ...validations.required, ...validations.email })}
                />
                <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
              </>
            )}

            <Text.Bold>Contact</Text.Bold>
            <Fields.Text
              inputProps={{ 'data-testid': 'phoneNumber' }}
              type="text"
              label="Phone Number"
              error={!!errors.phone}
              helperText={errors?.phone?.message}
              {...register('phone', { ...validations.phoneNumber })}
            />

            <Text.Bold>Your Address</Text.Bold>
            <Text.Body>Address is used for tax receipts only. No physical mail will be sent by GiveWise.</Text.Body>
            <Fields.Text
              label="Address Line One"
              required
              error={!!errors.address?.lineOne}
              helperText={errors?.address?.lineOne?.message}
              {...register('address.lineOne', { ...validations.required })}
            />
            <Fields.Text
              label="Address Line Two"
              error={!!errors.address?.lineTwo}
              helperText={errors?.address?.lineTwo?.message}
              {...register('address.lineTwo')}
            />
            <Fields.Text
              label="City"
              required
              error={!!errors.address?.city}
              helperText={errors?.address?.city?.message}
              {...register('address.city', { ...validations.required })}
            />

            {getValues('address.country') === 'Canada' ? (
              <FormControl fullWidth>
                <InputLabel id="provinceLabel">Province *</InputLabel>
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      inputProps={{ 'data-testid': 'province' }}
                      // @fixme It looks as if this were only added because the controller was given the wrong field name
                      value={watch('address.province')}
                      onChange={(e) => {
                        setValue('address.province', e.target.value, { shouldValidate: true })
                      }}
                      label="Province *"
                      labelId="provinceLabel"
                      aria-label="Province *"
                      error={!!errors.address?.province}
                      helpertext={errors?.address?.province?.message}
                      defaultValue=""
                      required
                    >
                      {PROVINCES_LIST.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <Controller
                control={control}
                name="province"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Fields.Text
                    label="Province/State"
                    value={watch('address.province')}
                    onChange={(e) => {
                      setValue('address.province', e.target.value, { shouldValidate: true })
                    }}
                    required
                    error={!!errors.address?.province}
                    helperText={errors?.address?.province?.message}
                  />
                )}
              />
            )}
            <Fields.Text
              label="Postal Code"
              required
              error={!!errors.address?.postalCode}
              helperText={errors?.address?.postalCode?.message}
              {...register('address.postalCode', { ...validations.required })}
            />
            <FormControl fullWidth>
              <InputLabel id="countryLabel">Country *</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    inputProps={{ 'data-testid': 'country' }}
                    defaultValue="Canada"
                    value={watch('address.country')}
                    onChange={(e) => {
                      setValue('address.country', e.target.value, { shouldValidate: true })
                      setValue('address.province', '', { shouldValidate: true })
                    }}
                    label="Country *"
                    labelId="countryLabel"
                    aria-label="Country *"
                    error={!!errors.address?.country}
                    helpertext={errors?.address?.country?.message}
                  >
                    {COUNTRY_LIST.map((c) => (
                      <MenuItem key={c} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            {captchaSiteKey && <HCaptcha sitekey={captchaSiteKey} onVerify={setCaptchaToken} ref={captchaRef} />}
            <Row justifyContent="flex-end" spacing={4}>
              <Text.Body variant="body2" sx={{ maxWidth: '300px', textAlign: 'right' }}>
                By clicking &ldquo;Sign Up&rdquo;, you agree to our{' '}
                <Link
                  href="https://www.givewise.ca/terms-conditions"
                  target="_blank"
                  variant="body2"
                  data-testid="termConditionLink"
                >
                  Terms and Conditions
                </Link>
              </Text.Body>
              <Button type="submit" disabled={loading} sx={{ whiteSpace: 'nowrap' }} data-testid="signUpButton">
                Sign Up
              </Button>
              {errors.form && <FormHelperText error={!!errors.form}>{errors.form.message}</FormHelperText>}
            </Row>
            <Alert {...alertProps} />
          </Stack>
        </form>
      </ContentBox>
      <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={4} pr={4}>
        <Text.Body variant="body2" data-testid="haveAccountText">
          Already have an account with us?
        </Text.Body>
        <Button color="info" to="/login" state={{ afterLogin }} data-testid="signInButton">
          Sign In
        </Button>
      </Row>
      <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={4} pr={4}>
        <Text.Body variant="body2" data-testid="haveAccountText">
          To delete a GiveWise Giving Fund, please email{' '}
          <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
        </Text.Body>
      </Row>
    </>
  )

  const charitySignUpMessage = () => (
    <Row alignItems="center" spacing={4}>
      <Stack spacing={2}>
        <Text.H5 data-testid="signupSuccessMessage">Charity Sign Up</Text.H5>
        <Text.Body>
          Charity Portal accounts are currently by invite only. For an invitation or to have your invitation resent,
          please email <Link href="mailto:grants@givewise.ca">grants@givewise.ca</Link>
        </Text.Body>
      </Stack>
    </Row>
  )

  const userSelector = () => {
    const iconStyle = { fontSize: '48px', color: '#61bee8', backgroundColor: 'white' }
    return (
      <>
        {[
          { value: SIGNUP_TYPE.personal, name: 'Personal', icon: <CircleUser style={iconStyle} /> },
          { value: SIGNUP_TYPE.business, name: 'Business', icon: <Business style={iconStyle} /> },
          { value: SIGNUP_TYPE.charity, name: 'Charity', icon: <HandHoldingHeart style={iconStyle} /> },
        ].map((u) => (
          <Card
            style={{
              width: '100%',
              borderLeft: '4px solid #61bee8',
            }}
            onClick={() => {
              navigate({}, { state: { selectedSignupType: u.value } })
            }}
          >
            <CardActionArea to="route-name">
              <CardHeader
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: 'white',
                      padding: '8px',
                      width: '52px',
                      height: '52px',
                    }}
                  >
                    {u.icon}
                  </Avatar>
                }
                title={u.name}
                titleTypographyProps={{ variant: 'h6', color: 'primary' }}
              />
            </CardActionArea>
          </Card>
        ))}
      </>
    )
  }

  const mainForm = () => (
    <>
      <Text.H5 component="h1" data-testid="title">
        Join GiveWise Canada
      </Text.H5>
      <Text.Body data-testid="content">
        {selectedSignupType === SIGNUP_TYPE.personal || selectedSignupType === SIGNUP_TYPE.business
          ? `Create your giving fund today, and experience a new world of giving!`
          : `Please select the type of account you would like to create`}
      </Text.Body>
      {selectedSignupType === SIGNUP_TYPE.personal || selectedSignupType === SIGNUP_TYPE.business
        ? signUpForm()
        : userSelector()}
    </>
  )

  return (
    <Container maxWidth="sm">
      <Stack spacing={4} style={{ padding: 24 }}>
        {selectedSignupType === SIGNUP_TYPE.charity ? charitySignUpMessage() : mainForm()}
      </Stack>
    </Container>
  )
}
