import { forwardRef, useImperativeHandle } from 'react'

import { BlockQuote, Stack, Text } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef(({ me, defaultFund }, ref) => {
  useImperativeHandle(ref, () => ({}))
  const name = me?.name ?? 'Guest'

  return (
    <Stack spacing={2}>
      <Text.Body>E-Transfers can be performed from your online banking platform using the following details:</Text.Body>
      <BlockQuote>
        <Text.Bold>Recipient</Text.Bold>
        <Text.Body>
          GiveWise Canada <br />
          donations@givewise.ca
        </Text.Body>
        <Text.Bold>Notes</Text.Bold>
        <Text.Body>
          {/** unsure as to what Giving Fund is */}
          Giving Fund #000 <br />
          Donor Name: {name}
        </Text.Body>
      </BlockQuote>
      <Text.Body>
        <strong>*IMPORTANT:</strong> In the notes section, you must remember to enter your first and last name and your
        GiveWise Giving Fund Number, so we can properly receive your funds into the correct Giving Fund.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ fundId, giftDetails, payment, userId }) {
  const amounts = calculateGiftAmount(giftDetails)

  const contribution = await new Promise((resolve) => {
    resolve({
      createContributionRecord: {
        fundId,
        amount: amounts.total,
        paymentType: 3,
        description: 'Interac e-Transfer',
        taxReceipted: true,
        state: 'cleared',
        netAmount: 100,
        fee: 5,
      },
    })
  }).then(({ createContributionRecord }) => createContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, fund, grantTotal }) {
  const name = me?.name ?? 'Guest'

  return (
    <>
      <p>
        You will now need to create an eTransfer using your bank&apos;s online platform in the amount of{' '}
        {toAmountString(grantTotal)} and send it to:
      </p>
      <p>
        <strong>Recipient:</strong>
        <br />
        GiveWise Canada
        <br />
        donations@givewise.ca
      </p>
      <p>
        <strong>Include in &quot;Message For Recipient&quot;:</strong>
        <br />
        Giving Fund #000
        <br />
        Donor Name: {name}
      </p>
      <Text.Body>
        <strong>*IMPORTANT:</strong> In the notes section, you must remember to enter your first and last name and your
        GiveWise Giving Fund Number, so we can properly receive your funds into the correct Giving Fund.
      </Text.Body>
    </>
  )
}
