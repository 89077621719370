import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Acknowledge, Button, Container, Stack, Text } from '../components'
import { mutations } from '../graphql'
import { useSearchParams } from '../hooks'

function ConfirmSuccess({ afterLogin }) {
  return (
    <Acknowledge.Success
      title="Email Confirmed"
      content="Thank you for confirming the additional email.
      This email is now available to choose if you would like to change your primary email address,
      which you can do from your profile page."
    >
      <Button to="/profile" state={{ afterLogin }}>
        Go to your profile
      </Button>
    </Acknowledge.Success>
  )
}

function ConfirmError({ afterLogin }) {
  return (
    <Acknowledge.Error title="Sorry!" content="We are unable to confirm the new email.">
      <Button to="/login" state={{ afterLogin }}>
        Back
      </Button>
    </Acknowledge.Error>
  )
}

export function ConfirmAddedEmail() {
  const { confirmationToken } = useParams()
  const [{ afterLogin }] = useSearchParams()
  const [confirmAddedEmail, { data, error }] = useMutation(mutations.user.confirmAddedEmail)
  useEffect(() => {
    if (!confirmationToken) return
    confirmAddedEmail({ variables: { data: confirmationToken, afterLogin } })
  }, [confirmationToken, confirmAddedEmail])

  return (
    <Container maxWidth="lg">
      <Stack justifyItems="center">
        <Stack>
          <Text.H1>My Giving Fund</Text.H1>
        </Stack>
        <Acknowledge.Switch
          success={!!data && <ConfirmSuccess afterLogin={afterLogin} />}
          error={!!error && <ConfirmError afterLogin={afterLogin} />}
        />
      </Stack>
    </Container>
  )
}
