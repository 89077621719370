// this function is used primarily for recursion within parseAndSetFormErrors so that we go as many levels as necessesary
// if error has children, ignore the parent level and jsut set errors for the children
// property: string = input field
// constraints: [errorMessage: string] = failed field vaildations
const setFieldError = ({ property, constraints = [], children = [] }, setError, parent = '') => {
  if (children.length > 0) {
    children.forEach((childField) => setFieldError(childField, setError, `${parent}${property}.`))
  } else if (property) {
    setError(`${parent}${property}`, { message: Object.values(constraints).join(',  ') })
  }
}

// function which accepts an error as thrown from apollo client onError, and the function which will set an error in a form state. its primary job is to take any graphql type errors thrown by apollo client and turn them into form state errors on the related field.
export const parseAndSetFormErrors = ({ graphQLErrors = [] }, setError) => {
  if (!setError) return
  graphQLErrors.forEach((graphQLError) => {
    graphQLError?.extensions?.fields?.forEach((field) => setFieldError(field, setError))
  })
}
