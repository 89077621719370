import { useQuery } from '@apollo/client'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'

import { queries } from '../../graphql'
import { Icons } from '..'

const categoryToIconMap = {
  '0001': <Icons.HandHoldingCircleDollar style={{ height: '40%' }} />,
  '0002': <Icons.HandHoldingCircleDollar style={{ height: '40%' }} />,
  '0010': <Icons.UserGraduate style={{ height: '40%' }} />,
  '0011': <Icons.UserGraduate style={{ height: '40%' }} />,
  '0012': <Icons.UserGraduate style={{ height: '40%' }} />,
  '0013': <Icons.UserGraduate style={{ height: '40%' }} />,
  '0015': <Icons.UserGraduate style={{ height: '40%' }} />,
  '0014': <Icons.Books style={{ height: '40%' }} />,
  '0030': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0040': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0050': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0060': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0070': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0080': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0090': <Icons.HandsPraying style={{ height: '40%' }} />,
  '0100': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0110': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0120': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0130': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0140': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0150': <Icons.ClipboardMedical style={{ height: '40%' }} />,
  '0155': <Icons.ScaleBalanced style={{ height: '40%' }} />,
  '0160': <Icons.PeopleGroup style={{ height: '40%' }} />,
  '0170': <Icons.Seedling style={{ height: '40%' }} />,
  '0175': <Icons.Farm style={{ height: '40%' }} />,
  '0180': <Icons.Paw style={{ height: '40%' }} />,
  '0190': <Icons.MasksTheater style={{ height: '40%' }} />,
  '0200': <Icons.TreeCity style={{ height: '40%' }} />,
  '0210': <Icons.Cubes style={{ height: '40%' }} />,
  '0214': <Icons.CircleDollarToSlot style={{ height: '40%' }} />,
  '0215': <Icons.CircleDollarToSlot style={{ height: '40%' }} />,
}

export function CharityLogo({ charityId, categoryCode, logo: savedLogo, temporarySrc, link, sx, ...props }) {
  let logoImage = categoryToIconMap[categoryCode] || <BrokenImageIcon sx={{ width: '100%', height: '100%' }} />

  if (
    (temporarySrc &&
      temporarySrc.startsWith('data:image/') &&
      !temporarySrc.includes('base64,null') &&
      !temporarySrc.includes('base64,undefined')) ||
    (temporarySrc && temporarySrc.startsWith('blob:'))
  ) {
    logoImage = (
      <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={temporarySrc} alt="temporary logo" />
    )
  } else if (savedLogo && savedLogo.logo) {
    logoImage = (
      <img
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        src={`data:image/jpeg;base64,${savedLogo.logo}`}
        // @fixme This should at least include the charity name
        alt="charity logo"
      />
    )
  } else if (savedLogo && savedLogo.logoPath) {
    logoImage = (
      <img
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        src={savedLogo.logoPath}
        // @fixme This should at least include the charity name
        alt="charity logo"
      />
    )
  } else if (link) {
    logoImage = <img style={{ maxHeight: '100px', objectFit: 'contain' }} src={link} alt="linked logo" />
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: savedLogo ? 'transparent' : '#f0f0f0',
      }}
    >
      {logoImage}
    </div>
  )
}
