import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useMediaQuery, useTheme } from '@mui/material'
import { GridRow, useGridApiContext } from '@mui/x-data-grid'

import {
  formatContributionDescription,
  formatDafTransferDescription,
  grantRequestValueFormatter,
  toAmountString,
  toDateString,
} from '../../utils'
import { mutations, queries } from '../../graphql'
import { Button, CancelButton, CardBox, Container, DataGrid, Icons, Link, Stack, Text, Tooltip } from '../../components'
import { Filters } from './filters'

function useIsNarrowerThan(sz) {
  const theme = useTheme()
  return !useMediaQuery(theme.breakpoints.up(sz))
}

/* Shown as column on rows to toggle expand / collapse. */
function RowExpandButton({ id, isExpanded, toggleExpanded, sx, ...props }) {
  return (
    <Button sx={{ flexGrow: 1, p: 0, minWidth: 0, ...sx }} unstyled {...props} onClick={() => toggleExpanded(id)}>
      {isExpanded(id) ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
    </Button>
  )
}

function useColumnsForExpandPanel() {
  const apiRef = useGridApiContext()
  /* showInExpandedRowPanel can exclude some columns from rendering in the expanded panel.
   * Otherwise, show columns that have been hidden. */
  return apiRef.current
    .getAllColumns()
    .filter(({ showInExpandedRowPanel = true, hide = false }) => showInExpandedRowPanel && hide)
}

/* A GridRow component with that conditionally shows an ExpandedRowPanel. */
function ExpandableRow({ isExpanded, rowId, ...props }) {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  return (
    <div>
      <GridRow rowId={rowId} {...props} />
      {isExpanded(rowId) && isNarrowerThanMd && <ExpandedRowPanel data-expanded={rowId} rowId={rowId} />}
    </div>
  )
}

function ExpandedRowPanel({ rowId, sx, ...props }) {
  const apiRef = useGridApiContext()
  const fields = useColumnsForExpandPanel()
    .map(({ field, headerName, renderCell }) => {
      const cellParams = apiRef.current.getCellParams(rowId, field)
      const content =
        renderCell?.({ ...cellParams, api: apiRef.current }) ??
        cellParams.formattedValue?.toString() ??
        cellParams.value?.toString()
      return { key: field, headerName, content }
    })
    .filter(({ content }) => content)

  const justify = useIsNarrowerThan('sm') ? 'space-between' : 'start'

  return (
    <Stack spacing={0} sx={{ pl: 7, ...sx }} {...props}>
      {fields.map(({ key, headerName, content }) => (
        <Stack key={key} direction="row" justifyContent={justify} minHeight="28px" alignItems="center">
          <div style={{ flex: 1, maxWidth: '140px' }}>{headerName}</div>
          <div>{content}</div>
        </Stack>
      ))}
    </Stack>
  )
}

export function GivingWallet() {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  const isNarrowerThanSm = useIsNarrowerThan('sm')

  const gridRef = useRef()
  const gridApi = useRef()

  const [showContributions, setShowContributions] = useState(true)
  const [showGrantRequests, setShowGrantRequests] = useState(true)
  const [showBalanceAdjustements, setShowBalanceAdjustements] = useState(true)
  const [showDafTransfers, setShowDafTransfers] = useState(true)
  const fundId = Number(useParams().fundId)
  const {
    loading,
    data: {
      me: { fund: { contributions = [], grantRequests = [], balanceAdjustments = [], dafTransfers = [] } = {} } = {},
    } = {},
  } = useQuery(queries.funds.myFundActivity, {
    variables: { fundId },
  })

  const { data: { me: { fund } = {} } = {} } = useQuery(queries.funds.myFund, {
    variables: { fundId },
  })

  const gWContributions = useMemo(() => contributions.filter((c) => c.walletType === 'giving'), [contributions])
  const gWBalanceAdjustments = useMemo(
    () => balanceAdjustments.filter((ba) => ba.walletType === 'giving'),
    [balanceAdjustments]
  )
  const initiatedDafTransfers = useMemo(
    () => dafTransfers.filter(({ initiatingFundId }) => initiatingFundId === fundId),
    [dafTransfers, fundId]
  )
  const receivedDafTransfers = useMemo(
    () => dafTransfers.filter(({ receivingFundId }) => receivingFundId === fundId),
    [dafTransfers, fundId]
  )

  const standardReceivedDafTransferMap = (dafTransfer) => ({
    ...dafTransfer,
    id: `rdt${dafTransfer.id}`,
    recordId: dafTransfer.id,
    date: dafTransfer?.canceledAt ?? dafTransfer?.receivedAt ?? dafTransfer?.createdAt,
    description: formatDafTransferDescription(dafTransfer, { type: 'recevived' }),
  })

  const generosityFundDafTransferMap = (dafTransfer) => ({
    ...standardReceivedDafTransferMap(dafTransfer),
    ...{ email: dafTransfer.initiatingUserEmail },
  })

  const rows = [
    ...(showDafTransfers
      ? [
          ...initiatedDafTransfers.map((dafTransfer) => ({
            ...dafTransfer,
            id: `idt${dafTransfer.id}`,
            recordId: dafTransfer.id,
            showAsDebit: true,
            date: dafTransfer?.canceledAt ?? dafTransfer?.receivedAt ?? dafTransfer?.createdAt,
            description: formatDafTransferDescription(dafTransfer, { type: 'initiated' }),
          })),
          ...receivedDafTransfers.map((dafTransfer) =>
            fund.isGenerosityFund
              ? generosityFundDafTransferMap(dafTransfer)
              : standardReceivedDafTransferMap(dafTransfer)
          ),
        ]
      : []),
    ...(showBalanceAdjustements
      ? gWBalanceAdjustments.map((balanceAdjustment) => ({
          ...balanceAdjustment,
          id: `ba${balanceAdjustment.id}`,
          recordId: balanceAdjustment.id,
          state: 'posted',
          date: balanceAdjustment?.createdAt,
        }))
      : []),
    ...(showGrantRequests
      ? grantRequests.map((grantRequest) => ({
          ...grantRequest,
          id: `gr${grantRequest.id}`,
          recordId: grantRequest.id,
          date: grantRequest?.processingDate ? grantRequest.processingDate : grantRequest?.createdAt,
          showAsDebit: true,
          isGrantRequest: true,
          description: [grantRequest?.charity, grantRequest?.description, grantRequest?.processingNote],
        }))
      : []),
    ...(showContributions
      ? gWContributions.map((contribution) => ({
          ...contribution,
          id: `c${contribution.id}`,
          recordId: contribution.id,
          date: contribution?.receiptedDate,
          description: formatContributionDescription(contribution),
        }))
      : []),
  ]

  const [expandMap, setExpandMap] = useState({})
  const isExpanded = useCallback((rowId) => expandMap[rowId] || false, [expandMap])
  const toggleExpanded = useCallback((rowId) => setExpandMap({ ...expandMap, [rowId]: !expandMap[rowId] }), [expandMap])
  const expandedHeight = useCallback(
    (rowId) => (expandMap[rowId] && gridRef?.current?.querySelector(`[data-expanded=${rowId}]`)?.clientHeight) || 0,
    [expandMap, gridRef]
  )

  function getTypeName(value) {
    switch (value) {
      case 'GrantRequest':
        return 'Gifts'
      case 'DafTransfer':
        return 'Shared Funds'
      default:
        return value
    }
  }

  /* hide means is hidden from the columnar display and shown in ExpandedRowPanel.
   * showInExpandedRowPanel is a boolean that, when false, excludes showing that column in the expanded panel.
   *
   * Some columns have both because they are never shown in the datagrid but should appear in the csv export... */
  const columns = [
    {
      /* field is required so we have to provide a value, but no value makes
       * sense because this does not map to a property on the row */
      field: '__expand',
      disableExport: true,
      hide: !isNarrowerThanMd,
      sortable: false,
      filterable: false,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      renderCell: ({ row } = {}) => (
        <RowExpandButton id={row.id} isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
      ),
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: '__typename',
      headerName: 'Type',
      flex: 0.5,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      valueFormatter: ({ value }) => getTypeName(value),
      renderCell: ({ row } = {}) => <Icons.ActivityItem row={row} />,
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 80,
      valueFormatter: ({ api: { getRow } = {}, id, value }) => toAmountString(value, getRow(id)?.showAsDebit),
      renderCell: ({ value, row: { showAsDebit } = {} } = {}) => (
        <Text.Body>{toAmountString(value, showAsDebit)}</Text.Body>
      ),
    },
    {
      field: 'tip',
      headerName: 'Courtesy Payment',
      description: 'Amount you gave to cover GiveWise’s transaction fees',
      hide: true,
      showInExpandedRowPanel: false,
      flex: 1,
      minWidth: 70,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'fee',
      headerName: 'Fee',
      hide: true,
      showInExpandedRowPanel: false,
      flex: 1,
      minWidth: 70,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'netAmount',
      headerName: 'Net Amount',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 80,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'description',
      headerName: 'Description',
      hide: isNarrowerThanSm,
      flex: 3,
      minWidth: 100,
      sortable: false,
      renderCell: ({ api: { getRow } = {}, id, value }) => (
        <GrantRequestDescriptionRender
          descriptionValue={value}
          fundId={fundId}
          isGrantRequest={getRow(id)?.isGrantRequest}
        />
      ),
      valueFormatter: ({ api: { getRow } = {}, id, value }) =>
        grantRequestValueFormatter(value, getRow(id)?.isGrantRequest),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 100,
      type: 'date',
      align: isNarrowerThanSm && 'right',
      headerAlign: isNarrowerThanSm && 'right',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'email',
      headerName: 'Email',
      hide: true,
      showInExpandedRowPanel: false,
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableExport: !fund.isGenerosityFund,
    },
    {
      field: 'taxReceipted',
      headerName: 'Tax Receipted',
      hide: true,
      showInExpandedRowPanel: false,
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'isRecurring',
      headerName: 'Recurring',
      hide: true,
      showInExpandedRowPanel: false,
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'state',
      headerName: 'State',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 100,
      renderCell: ({ value } = {}) => <Icons.State state={value} />,
    },
    {
      field: 'cancel',
      headerName: 'Cancel',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 40,
      sortable: false,
      renderCell: ({
        row: { __typename, amount, sentTo, recordId, state, charity: { accountName: name } = {} } = {},
      }) => {
        if (__typename === 'GrantRequest' && (state === 'pending' || state === 'scheduled')) {
          return (
            <CancelButton
              id={recordId}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>{toAmountString(amount)}</b>
                  <br />
                  {name ? `: ${name}` : ''}
                </Text.AltBody>
              }
              mutation={mutations.grantRequests.cancelPendingGrantRequest}
              refetchQueries={['MyFund']}
              variables={{ id: recordId }}
            />
          )
        }
        if (__typename === 'DafTransfer' && state === 'initiated') {
          return (
            <CancelButton
              id={recordId}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>Amount:</b> {toAmountString(amount)}
                  <br />
                  <b>To:</b> {sentTo}
                </Text.AltBody>
              }
              mutation={mutations.dafTransfers.cancelDafTransfer}
              refetchQueries={['MyFund']}
              variables={{ id: recordId }}
            />
          )
        }
        if (__typename === 'Contribution' && state === 'scheduled') {
          return (
            <CancelButton
              id={recordId}
              title="Confirm"
              details={
                <Text.AltBody>
                  <b>Contribution Amount:</b> {toAmountString(amount)}
                </Text.AltBody>
              }
              mutation={mutations.contributions.cancelScheduledContribution}
              refetchQueries={['MyFund', 'MyFundActivity', 'MyLinkedBanks', 'MyCreditCards']}
              variables={{ id: recordId }}
            />
          )
        }
        return null
      },
    },
  ]

  useEffect(() => {
    const applyExpandedHeight = (value, row) => ({ ...value, expanded: expandedHeight(row.id) })
    return gridApi?.current?.unstable_registerPipeProcessor('rowHeight', 'expanded-row-height', applyExpandedHeight)
  }, [expandedHeight])

  return (
    <Container maxWidth="lg">
      <CardBox>
        <DataGrid
          ref={gridRef}
          apiRef={gridApi}
          disableColumnSelector
          rows={rows}
          columns={columns}
          loading={loading}
          filters={
            <Filters
              showGrantRequests={showGrantRequests}
              setShowGrantRequests={setShowGrantRequests}
              showContributions={showContributions}
              setShowContributions={setShowContributions}
              showBalanceAdjustements={showBalanceAdjustements}
              setShowBalanceAdjustements={setShowBalanceAdjustements}
              showDafTransfers={showDafTransfers}
              setShowDafTransfers={setShowDafTransfers}
            />
          }
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
          }}
          components={{ Row: ExpandableRow }}
          componentsProps={{ row: { isExpanded } }}
          autoRowHeight
          sx={{
            '& .MuiDataGrid-cell': {
              p: '3px',
            },
          }}
        />
      </CardBox>
    </Container>
  )
}

// Renders if description is from a grant request, else returns the string value
function GrantRequestDescriptionRender({ descriptionValue, fundId, isGrantRequest }) {
  if (isGrantRequest) {
    const [charity, description] = descriptionValue
    return (
      <Text.AltBody>
        {charity && <Link to={`/funds/${fundId}/grant/${charity?.id}`}>{charity?.accountName}</Link>}
        {description && `${charity && ', '}${description}`}
      </Text.AltBody>
    )
  }

  return descriptionValue
}
