import { CardBox, Icons, Row, Stack } from '..'

export function Notice({ children, ...props }) {
  return (
    <CardBox sx={{ backgroundColor: 'aliceBlue' }} {...props}>
      <Row justifyContent="flex-end" alignItems="center" spacing={4}>
        <Icons.AnnouncementOutlined sx={{ fontSize: '3rem' }} color="warning" />
        <Stack>{children}</Stack>
      </Row>
    </CardBox>
  )
}
