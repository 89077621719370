import { forwardRef } from 'react'
import { Button, Confirm } from 'react-admin'

import { Inputs } from '../../management-components'
import { Icons } from '../../../components'

const Content = forwardRef(({ emailControl, noteControl, isSubmitted }, ref) => (
  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
    <Inputs.MuiText
      {...emailControl.field}
      label="email"
      error={(emailControl.fieldState.isTouched || isSubmitted) && emailControl.fieldState.invalid}
      helperText={
        (emailControl.fieldState.isTouched || isSubmitted) && emailControl.fieldState.invalid
          ? emailControl.fieldState.error
          : ''
      }
    />
    <Inputs.MuiText
      {...noteControl.field}
      label="note"
      error={(noteControl.fieldState.isTouched || isSubmitted) && noteControl.fieldState.invalid}
      multiline
      rows={4}
      helperText={
        (noteControl.fieldState.isTouched || isSubmitted) && noteControl.fieldState.invalid
          ? noteControl.fieldState.error
          : ''
      }
    />
  </div>
))

// Adding a disableNote Prop did not hold its state on rerenders, and so new components were created
// to remove the note field for charity invites
const ContentInviteCharity = forwardRef(({ emailControl, isSubmitted }, ref) => (
  <div ref={ref} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
    <Inputs.MuiText
      {...emailControl.field}
      label="email"
      error={(emailControl.fieldState.isTouched || isSubmitted) && emailControl.fieldState.invalid}
      helperText={
        (emailControl.fieldState.isTouched || isSubmitted) && emailControl.fieldState.invalid
          ? emailControl.fieldState.error
          : ''
      }
    />
  </div>
))

export function InviteCharityButton({
  label = null,
  disabled,
  loading,
  handleClick,
  isOpen,
  handleDialogClose,
  handleConfirm,
  emailControl,
  noteControl,
  isSubmitted,
}) {
  return (
    <>
      <Button label={label ?? 'Invite Charity'} disabled={disabled} onClick={handleClick}>
        <Icons.PayLink />
      </Button>
      <Confirm
        isOpen={isOpen}
        loading={loading}
        title="Invite Charity"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        content={
          <ContentInviteCharity emailControl={emailControl} noteControl={noteControl} isSubmitted={isSubmitted} />
        }
        cancel="Cancel"
        confirm="Invite"
      />
    </>
  )
}

export function SendPaylinkButton({
  label = null,
  disabled,
  loading,
  handleClick,
  isOpen,
  handleDialogClose,
  handleConfirm,
  emailControl,
  noteControl,
  isSubmitted,
}) {
  return (
    <>
      <Button label={label ?? 'Send Paylink'} disabled={disabled} onClick={handleClick}>
        <Icons.PayLink />
      </Button>
      <Confirm
        isOpen={isOpen}
        loading={loading}
        title="Send Paylink"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        sx={{ '& .MuiDialog-paper': { width: '100%' } }}
        content={<Content emailControl={emailControl} noteControl={noteControl} isSubmitted={isSubmitted} />}
      />
    </>
  )
}
