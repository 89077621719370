import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { TextField, Button, Text, Box, LinearProgress } from '../../components'
import { mutations, queries } from '../../graphql'

const ManualEntryForm = function ({ onSuccess }) {
  const { id: charityId } = useParams()
  const initialBankDetails = {
    institutionNumber: '',
    transitNumber: '',
    accountNumber: '',
  }

  const { data: userData } = useQuery(queries.user.me)
  const userType = userData?.me?.profile?.userType

  const [bankDetails, setBankDetails] = useState(initialBankDetails)
  const [confirmBankDetails, setConfirmBankDetails] = useState(initialBankDetails)
  const [step, setStep] = useState(1)
  const [error, setError] = useState('')
  const [fieldErrors, setFieldErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const resetForm = () => {
    setBankDetails(initialBankDetails)
    setConfirmBankDetails(initialBankDetails)
    setError('')
    setFieldErrors({})
    setStep(1)
  }

  const [createZumRailsManualUser] = useMutation(mutations.paymentMethods.createZumRailsManualUser, {
    refetchQueries: [
      { query: queries.paymentMethods.userHasBankAccount },
      { query: queries.charities.getCharityBankAccounts, variables: { charityId } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setError('')
      resetForm()
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    },
    onError: (mutationError) => {
      console.error('Mutation error:', mutationError)
      setError('Unable to process your request. Please try again later.')
    },
  })

  const validateInstitution = (value) => {
    const sanitized = value.replace(/\D/g, '')
    if (sanitized.length !== 3) {
      return 'Institution number must be exactly 3 digits'
    }
    if (!/^[0-9]{3}$/.test(sanitized)) {
      return 'Institution number must contain only digits'
    }
    return ''
  }

  const validateTransit = (value) => {
    const sanitized = value.replace(/\D/g, '')
    if (sanitized.length !== 5) {
      return 'Transit number must be exactly 5 digits'
    }
    if (!/^[0-9]{5}$/.test(sanitized)) {
      return 'Transit number must contain only digits'
    }
    return ''
  }

  const validateAccount = (value) => {
    const sanitized = value.replace(/\D/g, '')
    if (sanitized.length < 7 || sanitized.length > 12) {
      return 'Account number must be between 7 and 12 digits'
    }
    if (!/^[0-9]+$/.test(sanitized)) {
      return 'Account number must contain only digits'
    }
    return ''
  }

  const handleInputChange = (e, isConfirm = false) => {
    const { name, value } = e.target
    const sanitizedValue = value.replace(/\D/g, '')

    const setDetails = isConfirm ? setConfirmBankDetails : setBankDetails
    setDetails((prev) => ({ ...prev, [name]: sanitizedValue }))

    let validationError = ''
    switch (name) {
      case 'institutionNumber':
        validationError = validateInstitution(sanitizedValue)
        break
      case 'transitNumber':
        validationError = validateTransit(sanitizedValue)
        break
      case 'accountNumber':
        validationError = validateAccount(sanitizedValue)
        break
      default:
        break
    }

    setFieldErrors((prev) => ({
      ...prev,
      [isConfirm ? `confirm_${name}` : name]: validationError,
    }))
  }

  const validateStep = () => {
    if (step === 1) {
      const institutionError = validateInstitution(bankDetails.institutionNumber)
      const transitError = validateTransit(bankDetails.transitNumber)
      const accountError = validateAccount(bankDetails.accountNumber)

      setFieldErrors({
        institutionNumber: institutionError,
        transitNumber: transitError,
        accountNumber: accountError,
      })

      return !institutionError && !transitError && !accountError
    }
    return true
  }

  const handleSubmit = async () => {
    setError('')
    setIsLoading(true)

    try {
      if (!validateStep()) {
        setIsLoading(false)
        return
      }

      if (step === 1) {
        setStep(2)
        setIsLoading(false)
        return
      }

      const detailsMatch =
        bankDetails.institutionNumber === confirmBankDetails.institutionNumber &&
        bankDetails.transitNumber === confirmBankDetails.transitNumber &&
        bankDetails.accountNumber === confirmBankDetails.accountNumber

      if (!detailsMatch) {
        setError('Bank account details do not match. Please try again.')
        setStep(1)
        setConfirmBankDetails(initialBankDetails)
        setIsLoading(false)
        return
      }

      if (userType === 'charity' && !charityId) {
        setError('Charity ID is required')
        setIsLoading(false)
        return
      }

      const mutationData = {
        institutionNumber: bankDetails.institutionNumber,
        transitNumber: bankDetails.transitNumber,
        accountNumber: bankDetails.accountNumber,
        charityId,
      }

      await createZumRailsManualUser({
        variables: {
          data: mutationData,
        },
      })

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
      resetForm()
    } catch (err) {
      console.error('Full error object:', err)
      if (err.graphQLErrors) {
        console.error('GraphQL Errors:', err.graphQLErrors)
      }
      if (err.networkError) {
        console.error('Network Error:', err.networkError)
      }
      setError(err.message || 'An unexpected error occurred. Please try again later.')
    } finally {
      setIsLoading(false)
    }
  }

  const renderField = (name, label, maxLength, isConfirm = false) => {
    const stateValue = isConfirm ? confirmBankDetails[name] : bankDetails[name]
    const errorKey = isConfirm ? `confirm_${name}` : name
    const fieldError = fieldErrors[errorKey]

    return (
      <TextField
        label={label}
        name={name}
        value={stateValue}
        onChange={(e) => handleInputChange(e, isConfirm)}
        inputProps={{
          maxLength,
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        required
        sx={{ mb: 2 }}
        error={!!fieldError}
        helperText={fieldError}
        autoComplete="off"
      />
    )
  }

  return (
    <Box>
      <Box mb={3}>
        <Text.H6>
          {step === 1 ? (
            <>
              <strong>Step 1:</strong> Enter charity bank account details
            </>
          ) : (
            <>
              <strong>Step 2:</strong> Re-enter charity bank account details to ensure accuracy
            </>
          )}
        </Text.H6>
      </Box>

      {step === 1 ? (
        <>
          {renderField('institutionNumber', '3-digit Institution Number', 3)}
          {renderField('transitNumber', '5-digit Transit Number', 5)}
          {renderField('accountNumber', 'Bank Account Number (7-12 digits)', 12)}
        </>
      ) : (
        <>
          {renderField('institutionNumber', '3-digit Institution Number', 3, true)}
          {renderField('transitNumber', '5-digit Transit Number', 5, true)}
          {renderField('accountNumber', 'Bank Account Number (7-12 digits)', 12, true)}
        </>
      )}

      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || Object.values(fieldErrors).some((err) => !!err)}
        >
          {step === 1 ? 'Continue' : 'Confirm'}
        </Button>

        <Button variant="outlined" onClick={resetForm} disabled={isLoading}>
          Clear
        </Button>
      </Box>

      {error && (
        <Box mt={2}>
          <Text.Body color="error">{error}</Text.Body>
        </Box>
      )}
      {isLoading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress sx={{ mb: 2 }} />
        </Box>
      )}
    </Box>
  )
}

export default ManualEntryForm
