import { Alert as MuiAlert } from '@mui/material'
import { createContext, useContext, useMemo, useState } from 'react'

import { Box, Button, IconButton, Icons, Link, Snackbar, Text } from '..'

const GlobalAlertContext = createContext(null)

export function GlobalAlertProvider({ children }) {
  const initialState = {
    message: '',
    severity: undefined,
    vertical: undefined,
    horizontal: undefined,
    error: undefined,
    action: { label: undefined, onClick: undefined },
  }

  const [alert, setAlert] = useState(initialState)
  const { vertical = 'bottom', horizontal = 'left' } = alert

  const reset = () => setAlert(initialState)
  const handleClose = () => {
    reset()
  }
  const value = useMemo(() => [alert, { setGlobalAlert: setAlert }], [alert, setAlert])

  const ActionContainer = alert.action?.message ? (
    <>
      <Button color="secondary" size="small" onClick={alert.action.onClick}>
        {alert.action.label}
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <Icons.Close fontSize="small" />
      </IconButton>
    </>
  ) : null

  return (
    <GlobalAlertContext.Provider value={value}>
      {children}
      {alert.message ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={alert.message}
          open={!!alert.message}
          onClose={handleClose}
          autoHideDuration={12000}
          action={ActionContainer}
        >
          <MuiAlert variation="filled" severity={alert.severity} onClose={handleClose} sx={{ width: '100%' }}>
            <Box sx={{ maxWidth: '300px' }}>
              <Text.Body>{alert.message}</Text.Body>
              {alert.error && (
                <>
                  <Text.AltBody>
                    <i>{alert.error}</i>
                  </Text.AltBody>
                  <Text.AltBody>
                    For support, please contact us at <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
                  </Text.AltBody>
                </>
              )}
            </Box>
          </MuiAlert>
        </Snackbar>
      ) : null}
    </GlobalAlertContext.Provider>
  )
}

export function useGlobalAlert() {
  return useContext(GlobalAlertContext)
}
