import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { COUNTRY_LIST, PROVINCES_LIST } from 'constants'
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { CircularProgress, Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'

import { NoMatch } from './noMatch'
import { useAuth, useTitle } from '../hooks'
import palette from '../palette.json'
import { mutations, queries } from '../graphql'
import {
  Button,
  Checkbox,
  Collapse,
  Container,
  ContentBox,
  Fields,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icons,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Link,
  Row,
  Stack,
  Text,
  useAlert,
} from '../components'
import { validations } from '../components/form'
import * as Card from '../components/payment/credit-card'
import { fromAmountField } from '../utils'

const shareAsUser = (fundId) => `/funds/default/share-funds-with-generosity-fund/${fundId}`

export function GenerosityWorks() {
  const [{ isLoggedIn }] = useAuth()
  const { fundId } = useParams()
  const [success, setSuccess] = useState(null)

  const { data: { generosityFund } = {}, loading: loadingFund } = useQuery(queries.funds.generosityFund, {
    variables: { fundId: Number(fundId) },
  })

  useTitle(generosityFund?.name)

  if (loadingFund) return <LinearProgress />

  if (!generosityFund)
    return (
      <NoMatch title="Generosity Fund not found">
        <Text.Body>{`This fund doesn't exist or isn't set up for public giving right now.`}</Text.Body>
      </NoMatch>
    )

  return (
    <Container maxWidth="sm">
      <Stack alignItems="stretch">
        {success ? (
          <Text.H1>Donation complete!</Text.H1>
        ) : (
          <Text.H1>
            <small style={{ fontWeight: 'normal' }}>Donate to</small>
            <br />
            {generosityFund.name}
          </Text.H1>
        )}

        <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Text.AltBody>Already have an account with us?</Text.AltBody>
          <Link to="/login" state={{ afterLogin: shareAsUser(fundId) }}>
            Sign In
          </Link>
        </Row>

        {isLoggedIn && (
          <MuiAlert severity="info">
            <MuiAlertTitle>{`It looks like you're logged in!`}</MuiAlertTitle>
            You can <Link to={shareAsUser(fundId)}>share directly from your giving fund</Link>.
          </MuiAlert>
        )}

        {success ? (
          <Success generosityFundName={generosityFund.name} email={success.email} />
        ) : (
          <Form generosityFund={generosityFund} setDone={(v) => setSuccess(v)} />
        )}
      </Stack>
    </Container>
  )
}

/* on success, calls setDone passing object `{ email }` that was submitted */
function Form({ generosityFund, setDone }) {
  const client = useApolloClient()
  const newCard = useRef()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const { register, handleSubmit, formState, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      agreeToTerms: false,
      address: {
        country: 'Canada',
      },
    },
  })
  const { isSubmitting, errors = {} } = formState

  const [canGiveAsGuest, { data: { canGiveAsGuest: canUseEmail } = {} }] = useLazyQuery(queries.funds.canGiveAsGuest)

  const checkEmailUse = () => {
    const email = getValues('email')
    canGiveAsGuest({
      variables: { email },
    })
  }

  const onSubmit = async ({ email, firstName, middleInitials, lastName, address, amount }) => {
    let clientSecret = ''
    let stripePaymentMethodId = ''

    localStorage.setItem('guest_user_prefill_info', JSON.stringify(getValues()))

    try {
      const { data: { startIntentToGiveToGenerosityFund } = {} } = await client.mutate({
        mutation: mutations.funds.startIntentToGiveToGenerosityFund,
        variables: {
          data: {
            email,
            firstName,
            lastName,
          },
        },
      })
      clientSecret = startIntentToGiveToGenerosityFund
    } catch (error) {
      setAlert({ message: 'Something went wrong', error: error.message, severity: 'error' })
      console.error({ ...error })
      return
    }

    try {
      const setupIntent = await newCard.current.postToStripe({
        clientSecret,
        multiUse: false,
        firstName,
        lastName,
      })
      stripePaymentMethodId = setupIntent.payment_method
    } catch (e) {
      setAlert({
        message: `Something went wrong using this card`,
        error: e.message || e.type,
        severity: 'error',
      })
      console.error({ ...e })
      return
    }

    try {
      await client.mutate({
        mutation: mutations.funds.giveToGenerosityFundAsGuest,
        variables: {
          data: {
            email,
            firstName,
            middleInitials,
            lastName,
            fundId: generosityFund?.id,
            address,
            amount: fromAmountField(amount),
            stripePaymentMethodId,
          },
        },
      })
    } catch (error) {
      setAlert({ message: 'Something went wrong', error: error.message, severity: 'error' })
      console.error({ ...error })
      return
    }

    setDone({ email })
  }

  return (
    <ContentBox border>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ border: 0, padding: 0 }}>
          <Stack>
            <Text.Bold>Payment Information</Text.Bold>
            <Fields.Text
              label="Amount"
              name="amount"
              error={!!errors?.amount}
              helperText={errors?.amount?.message}
              InputProps={{
                inputComponent: Fields.Amount,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  setValue: (value) => setValue('amount', value),
                  getValue: () => getValues('amount'),
                  defaultValue: 0,
                },
              }}
              {...register('amount', {
                ...{ min: { value: 1, message: 'Must be at least 1' } },
                ...validations.required,
                ...validations.number,
              })}
            />
            <Card.GuestGenerosityForm ref={newCard} register={register} />

            <Text.Bold>Donor Information</Text.Bold>
            <Fields.Text
              label="Email"
              required
              error={!!errors.email}
              helperText={errors.email?.message}
              {...register('email', { ...validations.required, onBlur: checkEmailUse })}
            />
            {canUseEmail === false && (
              <MuiAlert severity="warning">
                <MuiAlertTitle>Log in to use this email.</MuiAlertTitle>
                This email belongs to a user.{' '}
                <Link
                  to="/login"
                  state={{ prefillEmail: getValues('email'), afterLogin: shareAsUser(generosityFund.id) }}
                >
                  Log in with this email
                </Link>{' '}
                and share directly from your giving fund to make a donation.
              </MuiAlert>
            )}
            <Fields.Text
              label="First Name"
              required
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              {...register('firstName', { ...validations.required })}
            />
            <Fields.Text
              label="Middle Initials"
              error={!!errors.middleInitials}
              helperText={errors.middleInitials?.message}
              {...register('middleInitials')}
            />
            <Fields.Text
              label="Last Name"
              required
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              {...register('lastName', { ...validations.required })}
            />

            <Text.Bold>Your Address</Text.Bold>
            <Text.Body>Address is used for legal tax receipts. No physical mail will be sent by GiveWise.</Text.Body>
            <Fields.Text
              label="Address Line One"
              required
              error={!!errors.address?.lineOne}
              helperText={errors?.address?.lineOne?.message}
              {...register('address.lineOne', { ...validations.required })}
            />
            <Fields.Text
              label="Address Line Two"
              error={!!errors.address?.lineTwo}
              helperText={errors?.address?.lineTwo?.message}
              {...register('address.lineTwo')}
            />
            <Fields.Text
              label="City"
              required
              error={!!errors.address?.city}
              helperText={errors?.address?.city?.message}
              {...register('address.city', { ...validations.required })}
            />

            {getValues('address.country') === 'Canada' ? (
              <FormControl fullWidth>
                <InputLabel id="provinceLabel">Province *</InputLabel>
                <Controller
                  control={control}
                  name="province"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      inputProps={{ 'data-testid': 'province' }}
                      value={watch('address.province')}
                      onChange={(e) => {
                        setValue('address.province', e.target.value, { shouldValidate: true })
                      }}
                      label="Province *"
                      labelId="provinceLabel"
                      aria-label="Province *"
                      error={!!errors.address?.province}
                      helpertext={errors?.address?.province?.message}
                      defaultValue=""
                      required
                    >
                      {PROVINCES_LIST.map((p) => (
                        <MenuItem key={p} value={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            ) : (
              <Controller
                control={control}
                name="province"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Fields.Text
                    label="Province/State"
                    value={watch('address.province')}
                    onChange={(e) => {
                      setValue('address.province', e.target.value, { shouldValidate: true })
                    }}
                    required
                    error={!!errors.address?.province}
                    helperText={errors?.address?.province?.message}
                  />
                )}
              />
            )}
            <Fields.Text
              label="Postal Code"
              required
              error={!!errors.address?.postalCode}
              helperText={errors?.address?.postalCode?.message}
              {...register('address.postalCode', { ...validations.required })}
            />
            <FormControl fullWidth>
              <InputLabel id="countryLabel">Country *</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    inputProps={{ 'data-testid': 'country' }}
                    defaultValue="Canada"
                    value={watch('address.country')}
                    onChange={(e) => {
                      setValue('address.country', e.target.value, { shouldValidate: true })
                      setValue('address.province', '', { shouldValidate: true })
                    }}
                    label="Country *"
                    labelId="countryLabel"
                    aria-label="Country *"
                    error={!!errors.address?.country}
                    helpertext={errors?.address?.country?.message}
                  >
                    {COUNTRY_LIST.map((c) => (
                      <MenuItem key={c} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Row>
              <FormControl error={!!errors?.agreeToTerms}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: errors?.agreeToTerms ? palette.red : 'black',
                      }}
                      {...register('agreeToTerms', { ...validations.required })}
                      checked={getValues('agreeToTerms')}
                      onChange={(e) => {
                        setValue('agreeToTerms', e.target.checked, { shouldValidate: true })
                      }}
                    />
                  }
                  label={
                    <>
                      I agree to the{' '}
                      <Link href="https://www.givewise.ca/terms-conditions" target="_blank">
                        Terms and Conditions
                      </Link>
                      .
                    </>
                  }
                />
                {errors?.agreeToTerms && <FormHelperText>{errors?.agreeToTerms?.message}</FormHelperText>}
              </FormControl>
              {isSubmitting && <CircularProgress thickness={6} />}
              <div style={{ flexGrow: '1' }} />
              <Button type="submit" disabled={isSubmitting}>
                Donate
              </Button>
            </Row>
            <Alert {...alertProps} />
          </Stack>
        </fieldset>
      </form>
    </ContentBox>
  )
}

function Success({ generosityFundName, email }) {
  return (
    <ContentBox border>
      <Text.Bold>Thanks for donating to {generosityFundName.toUpperCase()}!</Text.Bold>
      <Text.Body>Instructions on how to retrieve your tax receipt are on the way to {email}.</Text.Body>
      <Stack spacing={1} sx={{ marginTop: '25px' }}>
        <Text.Body>
          <Link href="/sign-up" target="_blank" sx={{ fontWeight: 'bold' }}>
            You’re almost there! Add a password to your Giving Fund so that you can:
          </Link>{' '}
        </Text.Body>
        <Text.Body>&bull; Set up recurring giving effortlessly.</Text.Body>
        <Text.Body>&bull; Streamline all your donations in one convenient place.</Text.Body>
        <Text.Body>&bull; Simplify tax time with a single tax receipt for all your giving.</Text.Body>
        <Row justifyContent="flex-end">
          <Button to="/sign-up" color="info" target="_blank">
            Add Password
          </Button>
        </Row>
      </Stack>
    </ContentBox>
  )
}
