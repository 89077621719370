import { colors } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { light } from '@mui/material/styles/createPalette'

import palette from './palette.json'

export const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: palette.white,
    },
    primary: {
      main: palette.blue,
      light: palette.dark.blue,
      dark: palette.light.blue,
    },
    secondary: {
      main: palette.yellow,
      light: palette.light.yellow,
      dark: palette.dark.yellow,
    },
    error: {
      main: palette.red,
      light: palette.light.red,
      dark: palette.dark.red,
    },
    warning: {
      main: palette.orange,
      light: palette.light.orange,
      dark: palette.dark.orange,
    },
    info: {
      main: palette.dark.blue,
      light: palette.light.blue,
      dark: palette.dark.blue,
    },
    success: {
      main: palette.green,
      light: palette.light.green,
      dark: palette.dark.green,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h7: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    caption: {
      fontWeight: 400,
    },
    overline: {
      fontWeight: 400,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginBottom: '3rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.text,
          '&:hover': {
            color: palette.dark.blue,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          color: palette.text,
          '&:hover': {
            color: palette.black,
            backgroundColor: palette.yellow,
          },
        },
        containedInfo: {
          color: palette.white,
          '&:hover': {
            color: palette.black,
            backgroundColor: palette.yellow,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          // Use default text color instead of branding color for active tabs
          color: light.text.primary,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        type: 'text',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          // When the input label shrinks, use the darker primary color to increase legibility.
          // The lighter secondary color is only neecessary on the larger un-shrunk label,
          // where it may be confused for input content.
          color: light.text.primary,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 2,
        justifyItems: 'flex-start',
        alignItems: 'flex-start',
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: '100%',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        action: {
          alignSelf: 'flex-start',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          margin: '0',
          padding: '32px',
          boxSizing: 'border-box',
        },
        paper: {
          margin: '0',
          padding: '32px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: colors.grey[200],
        },
      },
    },
  },
})
