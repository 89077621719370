import { gql } from '@apollo/client'

export const prepareVopayCSV = gql`
  mutation prepareVopayCSV($grantIds: [Int!]!) {
    prepareVopayCSV(grantIds: $grantIds) {
      service
      currency
      amount
      beneficiary
      address
      city
      province
      country
      postalCode
      clientReferenceNumber
      institutionNumber
      transitNumber
      accountNumber
      token
    }
  }
`
