import { useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  BlockQuote,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  Stack,
  Text,
  useAlert,
  useDialog,
} from '../../../components'
import { fromAmountField } from '../../../utils'

export function ETransfer() {
  const { data: { me: { name } = { name: '' } } = {} } = useQuery(queries.user.me)
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const { number } = fund
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const [formData, setFormData] = useState({})
  const client = useApolloClient()
  const { fundId } = useParams()

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0.0,
    },
  })

  const closeSuccessDialog = () => {
    resetForm()
    closeDialog()
  }

  const onSubmit = async ({ amount }) => {
    try {
      await client.mutate({
        mutation: mutations.contributions.createContributionRecord,
        variables: {
          fundId: Number(fundId),
          amount: fromAmountField(amount),
          paymentType: 3,
          description: 'Interac e-Transfer',
          taxReceipted: true,
        },
        refetchQueries: [
          { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
          { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
          { query: queries.paymentMethods.myLinkedBanks },
        ],
        awaitRefetchQueries: true,
      })
      setFormData({ amount })
      openDialog()
    } catch (e) {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  const { errors = {} } = formState

  return (
    <Container maxWidth="lg">
      <Stack spacing={0}>
        <CardBox title="Interac e-Transfer">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Fields.Text
                label="Amount"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputComponent: Fields.Amount,
                  inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: 0,
                  },
                }}
                {...register('amount', {
                  required: 'This field is required',
                  validate: {
                    positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                    min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
                  },
                })}
              />
              <Text.Body>
                You can perform a simple transaction from your online banking platform. Simply send an e-Transfer using
                your bank&apos;s online platform, choose the amount, and send it to:
              </Text.Body>
              <BlockQuote>
                <Text.Bold>Recipient</Text.Bold>
                <Text.Body>
                  GiveWise Canada <br />
                  donations@givewise.ca
                </Text.Body>
                <Text.Bold>Notes</Text.Bold>
                <Text.Body>
                  Giving Fund #{number} <br />
                  Donor Name: {name}
                </Text.Body>
              </BlockQuote>
              <Text.Body>
                <strong>*IMPORTANT:</strong> In the notes section, you must remember to enter your first and last name
                and your GiveWise Giving Fund Number, so we can properly receive your funds into the correct Giving
                Fund.
              </Text.Body>
              <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
                Submit
              </Button>
              <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
            </Stack>
          </form>
        </CardBox>
      </Stack>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" />
        <div>
          <p>You have successfully initiated a contribution.</p>
          <p>
            You will now need to create an eTransfer using your bank&apos;s online platform in the amount of $
            {formData.amount} and send it to:
          </p>
          <p>
            <strong>Recipient:</strong>
            <br />
            GiveWise Canada
            <br />
            donations@givewise.ca
          </p>
          <p>
            <strong>Include in &quot;Message For Recipient&quot;:</strong>
            <br />
            Giving Fund #{number}
            <br />
            Donor Name: {name}
          </p>
        </div>
        <Button
          onClick={closeSuccessDialog}
          to={`/funds/${fundId}/activity/giving-wallet`}
          sx={{ width: 'fit-content', position: 'absolute', right: '32px', bottom: '32px' }}
        >
          Done
        </Button>
      </Dialog>
    </Container>
  )
}
