import { Box } from '@mui/material'

import { LoginForm } from './login-form'
import * as Text from '../text/text'

export function LoginDialog({ prefillEmail, onCompleted, close, notification }) {
  return (
    <Box>
      <Text.H6 alignSelf="flex-start" gutterBottom>
        Login
      </Text.H6>
      {notification}
      <LoginForm {...{ prefillEmail, onCompleted, close }} />
    </Box>
  )
}
