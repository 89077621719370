import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Confirm } from 'react-admin'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, CardBox, CardActions, CardContent, Icons, Stack, Text, useAlert } from '../../components'
import { queries } from '../../graphql'

export function ManageZumBankAccounts(props) {
  const { manageBankAccountsRef } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [cannotDeleteOpen, setCannotDeleteOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const zumRailsbankAccountsResponse = useQuery(queries.paymentMethods.linkedZumRailsBanks)
  const myFund = useQuery(queries.funds.myFund)
  const [bankAccounts, setBankAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (zumRailsbankAccountsResponse.data) {
      setBankAccounts(zumRailsbankAccountsResponse.data.linkedZumRailsBanks)
    }
  }, [zumRailsbankAccountsResponse.data])

  const hasBankAccounts = bankAccounts && bankAccounts.length

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/zumrails/delete-bank-account/${deletingId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('givewise_auth_state'))?.state?.rawToken}`,
        },
      })

      if (!response.ok) {
        let errorMessage = 'Failed to delete bank account from ZumRails'
        try {
          const errorData = await response.json()
          errorMessage = errorData.error || errorMessage
        } catch (parseError) {
          errorMessage = response.statusText || errorMessage
        }
        throw new Error(errorMessage)
      }

      setBankAccounts((prevAccounts) => prevAccounts.filter((ba) => ba.zumUserId !== deletingId))
      setAlert({ message: 'Bank account removed successfully.', severity: 'success' })
    } catch (error) {
      setAlert({ message: `An error occurred: ${error.message}`, severity: 'error' })
    } finally {
      setIsLoading(false)
      setOpen(false)
    }
  }

  const handleConfirm = (id) => {
    setOpen(true)
    setDeletingId(id)
  }

  const handleDialogClose = () => {
    setOpen(false)
    setCannotDeleteOpen(false)
    setDeletingId(null)
  }

  return (
    <Stack ref={manageBankAccountsRef}>
      <CardBox>
        <Stack direction="row">
          <Text.H4>Bank Accounts</Text.H4>
        </Stack>
        <br />
        <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
          {hasBankAccounts ? (
            bankAccounts.map((ba) => {
              const srcObj = ba

              return (
                <Card
                  key={ba.Id}
                  variant="outlined"
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    margin: 10,
                    backgroundColor: 'lightblue',
                    borderColor: 'lightblue',
                  }}
                >
                  <CardContent>
                    <Text.H5>{`${srcObj.Institution}`}</Text.H5>
                    <br />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Text.Body>{`••••••••${srcObj.AccountNumber.slice(-4)}`}</Text.Body>
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="error" variant="text" onClick={() => handleConfirm(ba.zumUserId)}>
                      <Icons.DeleteForever fontSize="small" />
                      Remove
                    </Button>
                  </CardActions>
                </Card>
              )
            })
          ) : (
            <Text.H5>
              No saved bank accounts.{' '}
              <Link to={`/funds/${myFund.data?.me?.fund.id}/contribute/bank-transfer`}>Add Funds</Link> to save a bank
              account.
            </Text.H5>
          )}
        </Stack>
        <br />
        <Dialog
          open={cannotDeleteOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This bank account cannot be removed at the moment because it is being used for currently scheduled or
              recurring contributions.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
            <Button onClick={() => navigate(`/funds/${myFund.data?.me?.fund.id}/activity/giving-wallet`)} autoFocus>
              Go to my activity
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={open}
          loading={isLoading}
          title="Remove Bank Account"
          content="Are you sure you want to remove this bank account from your profile?"
          onConfirm={handleDelete}
          onClose={handleDialogClose}
          cancel="Cancel"
          confirm="Yes, remove it"
          confirmColor="secondary"
        />
        <Alert {...alertProps} />
      </CardBox>
    </Stack>
  )
}
