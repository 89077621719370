import { forwardRef } from 'react'
import { AutocompleteInput, ReferenceInput, required } from 'react-admin'
import { FormatButtons, RichTextInputToolbar as RaRichTextInputToolbar, RichTextInput } from 'ra-input-rich-text'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import MuiTextField from '@mui/material/TextField'

import { FilledInput, FormControl, FormHelperText, InputAdornment, InputLabel } from '../../../components'

export {
  AutocompleteInput as Autocomplete,
  AutocompleteArrayInput as AutocompleteArray,
  BooleanInput as Boolean,
  DateInput as Date,
  DateTimeInput as DateTime,
  ImageInput as Image,
  NumberInput as Number,
  ReferenceArrayInput as ReferenceArray,
  ReferenceInput as Reference,
  SelectArrayInput as SelectArray,
  SelectInput as Select,
  TextInput as Text,
  RadioButtonGroupInput as RadioButtonGroup,
} from 'react-admin'

export function MuiText(props) {
  return <MuiTextField {...props} />
}

export function RichTextInputToolbar({ size = 'sm' }) {
  return (
    <RaRichTextInputToolbar sx={{ alignSelf: 'flex-start' }}>
      <FormatButtons size={size} />
    </RaRichTextInputToolbar>
  )
}

export function RichText({ size = 'sm', ...props }) {
  return <RichTextInput variant="" size={size} toolbar={<RichTextInputToolbar size="size" />} {...props} />
}

export function Fund({ source, isRequired = true, ...props }) {
  return (
    <ReferenceInput source={source ?? 'fundId'} reference="MFund">
      <AutocompleteInput
        optionText={(fund) => `${fund.number} - ${fund.name} - ${fund.userName}`}
        validate={[isRequired && required()]}
        fullWidth
        {...props}
      />
    </ReferenceInput>
  )
}

export function Charity({ source, isRequired = true, ...props }) {
  return (
    <ReferenceInput source={source ?? 'charityId'} reference="Charity">
      <AutocompleteInput
        optionText={(charity) => `${charity.id} - ${charity.accountName}`}
        validate={[isRequired && required()]}
        fullWidth
        {...props}
      />
    </ReferenceInput>
  )
}

export function User({ source, isRequired = true, ...props }) {
  return (
    <ReferenceInput source={source ?? 'userId'} reference="User">
      <AutocompleteInput optionText={(user) => user.name} validate={[isRequired && required()]} fullWidth {...props} />
    </ReferenceInput>
  )
}

export const NumberFormatInput = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      isNumericString={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue || 0,
          },
        })
      }}
      mask
      allowEmptyFormatting
      allowLeadingZeros={false}
      thousandSeparator
      displayType="input"
      decimalScale={2}
    />
  )
})

export function Amount({ name = '', label = '', isRequired = true }) {
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
  } = useController({
    name,
    rules: { required: isRequired ? 'Amount is required' : false },
  })
  return (
    <FormControl variant="filled" error={(isTouched || isSubmitted) && invalid} sx={{ mb: 4 }}>
      <InputLabel id={`${name}-label`} htmlFor={name}>
        {label}
      </InputLabel>
      <FilledInput
        name={name}
        id={name}
        aria-describedby={`${name}-label`}
        inputComponent={NumberFormatInput}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        {...field}
      />
      {(isTouched || isSubmitted) && invalid && <FormHelperText id="my-helper-text">{error.message}</FormHelperText>}
    </FormControl>
  )
}
