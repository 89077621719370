import { Link as MuiLink } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'
import { forwardRef } from 'react'

export function LinkWithNoRef({ to, href, label, text, children, sx, ...props }, ref) {
  return (
    <MuiLink
      {...props}
      ref={ref}
      component={to ? RouterLink : undefined}
      href={href}
      to={to}
      label={label}
      color="#000000"
      sx={{ fontWeight: 500, ...sx }}
    >
      {children || text || label}
    </MuiLink>
  )
}

export const Link = forwardRef(LinkWithNoRef)
