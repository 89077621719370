import { Box } from '../../../components'

export function Wrapper({ children, ...props }) {
  return (
    <Box
      pt={{ xs: 2, md: 0 }}
      pl={{ md: 4 }}
      sx={{
        width: { xs: '100%', md: '40%' },
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{children}</Box>
    </Box>
  )
}
