import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

import palette from '../palette.json'
import { mutations, queries } from '../graphql'
import {
  Acknowledge,
  BlockContent,
  BlockQuote,
  Button,
  CardBox,
  Checkbox,
  Container,
  Dialog,
  Divider,
  FavouriteCharityButton,
  Fields,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FundStat,
  Icons,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Recurring,
  Row,
  Scheduled,
  Select,
  Stack,
  Text,
  insufficientFundsExtraMessage,
  longWait,
  useAlert,
  useDialog,
  validations,
  Tooltip,
} from '../components'
import { join, parseAndSetFormErrors } from '../utils'
import { NoMatch } from './noMatch'

const roundTo2DecimalPlaces = (num) =>
  (Math.round(num * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

export function Charity() {
  const { charityId, fundId } = useParams()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [{ dialogProps }, { open: openDialog }] = useDialog()
  const { data: { charity } = {}, loading } = useQuery(queries.charities.charity, {
    variables: { charityId, fundId: Number(fundId) },
  })
  const navigate = useNavigate()
  const [addTipOpen, setAddTipOpen] = useState(true)
  const [totalGrantRequestAmount, setTotalGrantRequestAmount] = useState(0)

  const {
    register,
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    setError,
    reset: resetForm,
    watch,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      isRecurring: false,
      recurringInterval: 'monthly',
      purposeOfGrant: '',
      anonymous: false,
      selfAttestation: false,
      tip: 0.0,
      amount: 0.0,
    },
  })

  const watchProcessingDate = watch('processingDate')
  const isScheduledGrant = !!watchProcessingDate

  const updateTotalAmount = (tip = null) => {
    const amountNum = getValues('amount') ? parseFloat(getValues('amount').replace(',', '')) : 0
    const tipNum = tip ?? getValues('tip') ? parseFloat(getValues('tip')) : 0
    setTotalGrantRequestAmount((tipNum + amountNum).toFixed(2))
  }

  const allocate3PercentTip = () => {
    const grantAmount = getValues('amount').replace(',', '')
    // Initial tip amount set to 3% of grant amount to a max of $25, and can still be changed by the user
    const tipAmount = parseFloat(grantAmount) * 0.03 > 25 ? 25 : parseFloat(grantAmount) * 0.03 || 0
    setAddTipOpen(true)
    setValue('tip', tipAmount.toFixed(2))
    return tipAmount
  }

  const handleTotalGrantRequestAmountChange = () => {
    updateTotalAmount(allocate3PercentTip())
  }

  const handleTipChange = () => {
    updateTotalAmount()
  }

  const handleSetAddTipOpen = () => {
    if (addTipOpen) {
      setValue('tip', 0)
      setAddTipOpen(false)
    } else {
      allocate3PercentTip()
    }
    updateTotalAmount()
  }

  const closeSuccessDialog = () => {
    resetForm()
    navigate(`/funds/${fundId}/grant`)
  }

  const [createGrantRequest] = useMutation(mutations.grantRequests.createGrantRequest, {
    onCompleted: () => {
      openDialog()
    },
    onError: (serverErrors) => {
      parseAndSetFormErrors(serverErrors, setError)
      setAlert({
        timeout: longWait,
        message: 'Gift failed',
        error: serverErrors?.message,
        extraMessage: serverErrors?.message.includes('insufficient') ? insufficientFundsExtraMessage(fundId) : '',
        severity: 'error',
      })
    },
    refetchQueries: [
      { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
      { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
      { query: queries.charities.charity, variables: { fundId: Number(fundId), charityId } },
      { query: queries.funds.myRecurring, variables: { fundId: Number(fundId) } },
    ],
    awaitRefetchQueries: true,
  })

  const { errors = {} } = formState

  const getPurposeOfGiftId = (purposeOfGrant) => {
    if (charity?.purposeOfGifts) {
      const purpose = charity.purposeOfGifts.find((p) => p.reason === purposeOfGrant)
      if (purpose) return purpose.id
    }
    return null
  }

  const onSubmit = ({
    amount,
    tip,
    purposeOfGrant,
    purposeOfGrantNote,
    recurringInterval,
    isRecurring,
    processingDate,
    ...data
  }) => {
    createGrantRequest({
      variables: {
        data: {
          charityId,
          fundId: Number(fundId),
          amount: parseFloat(amount.replaceAll(',', ''), 10),
          tip: tip ? parseFloat(tip.replaceAll(',', ''), 10) : 0,
          grantNote: join([purposeOfGrant, purposeOfGrantNote], ': '),
          isRecurring,
          recurringInterval: isRecurring ? recurringInterval : null,
          purposeOfGiftId: getPurposeOfGiftId(purposeOfGrant),
          processingDate,
          ...data,
        },
      },
    })
  }

  let purposeOfGrantNoteLabel = ''
  switch (getValues('purposeOfGrant')) {
    case 'Fundraising Support':
      purposeOfGrantNoteLabel = 'Name of fundraising project'
      break
    case 'In memory of':
      purposeOfGrantNoteLabel = 'Full Name'
      break
    case 'Other':
      purposeOfGrantNoteLabel = 'Please describe the reason for your gift'
      break
    case 'Thanks for the work you do!':
    default:
      purposeOfGrantNoteLabel = 'Additional note'
      break
  }
  if (loading) return <LinearProgress />
  if (!charity) return <NoMatch title="Charity not found" />
  const {
    accountName,
    id,
    emailAddress,
    contactPhoneNumber,
    mailingAddress,
    postalCode,
    city,
    country,
    province,
    websiteAddress,
    active,
    totalGrantRequestsForCharity,
    totalGrantRequestsForCharityYTD,
  } = charity

  const displayFinalGiftValue = roundTo2DecimalPlaces(parseFloat(`${getValues('amount')}`.replaceAll(',', '')))
  const displayFinalTipValue = roundTo2DecimalPlaces(parseFloat(`${getValues('tip')}`.replaceAll(',', '')))

  function reorderArrayByReason(array, reasons) {
    const specificOrder = reasons.map((reason) => array.find((item) => item.reason === reason))
    const filteredArray = array.filter((item) => !reasons.includes(item.reason))
    return specificOrder.concat(filteredArray)
  }

  const purposeOfGiftDefaults = ['General Support', 'In Memory Of', 'Other']

  const purposeOfGiftOptions = () => {
    if (charity?.purposeOfGifts && charity.purposeOfGifts.length) {
      return reorderArrayByReason(charity.purposeOfGifts, purposeOfGiftDefaults)
        .filter((p) => p.active)
        .map((p) => (
          <MenuItem key={p.reason} value={`${p.reason}`}>
            {p.reason}
          </MenuItem>
        ))
    }
    return purposeOfGiftDefaults.map((p) => <MenuItem value={`${p.reason}`}>{p.reason}</MenuItem>)
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={1}>
          <Text.H1>Gift Details</Text.H1>
          <CardBox title={accountName}>
            <Stack spacing={4}>
              <Row>
                <Text.Bold>BN #{id}</Text.Bold>
                <FavouriteCharityButton charityId={id} />
              </Row>
              <Row
                sx={{
                  alignItems: 'stretch',
                  flexDirection: { xs: 'column', sm: 'row-reverse' },
                }}
              >
                <BlockQuote sx={{ width: { xs: '100%', sm: '40%' } }}>
                  <Text.H6>Gift History for this Charity:</Text.H6>
                  <FundStat
                    Icon={Icons.GrantRequest}
                    text="Lifetime Gifts"
                    amount={totalGrantRequestsForCharity}
                    tooltip="Total Gifts Sent to this Charity (Lifetime)"
                    sx={{ width: 'auto' }}
                  />
                  <FundStat
                    Icon={Icons.CalendarDays}
                    text="Year To Date Gifts"
                    amount={totalGrantRequestsForCharityYTD}
                    tooltip="Total Gifts Sent to this Charity (Year To Date)"
                    sx={{ width: 'auto' }}
                  />
                  <Divider sx={{ pt: 3 }} />
                  <Text.H6 sx={{ pt: 2 }}>Contact the Charity:</Text.H6>
                  {emailAddress && (
                    <>
                      <Text.AltBody>
                        <strong>email: </strong>
                      </Text.AltBody>
                      <Text.Body sx={{ textTransform: 'lowercase', wordBreak: 'break-all' }}>
                        <Link href={`mailto:"${emailAddress}"`}>{emailAddress}</Link>
                      </Text.Body>
                    </>
                  )}
                  {contactPhoneNumber && (
                    <>
                      <Text.AltBody>
                        <strong>tel: </strong>
                      </Text.AltBody>
                      <Text.Body>
                        <Link href={`tel:+${contactPhoneNumber?.replace('[^0-9]', '')}`}>{contactPhoneNumber}</Link>
                      </Text.Body>
                    </>
                  )}
                  {(mailingAddress || city || country || province) && (
                    <>
                      <Text.AltBody>
                        <strong>Address:</strong>
                      </Text.AltBody>
                      <Text.Body>{mailingAddress}</Text.Body>
                      <Text.Body>
                        {city ? `${city}, ` : ''}
                        {province}
                      </Text.Body>
                      <Text.Body>{postalCode}</Text.Body>
                      <Text.Body>{country}</Text.Body>
                    </>
                  )}
                  {websiteAddress && (
                    <>
                      <Text.AltBody>
                        <strong>website: </strong>
                      </Text.AltBody>
                      <Text.Body sx={{ textTransform: 'lowercase', wordBreak: 'break-all' }}>
                        <Link href={`http://${websiteAddress}`} target="_blank">
                          {websiteAddress}
                        </Link>
                      </Text.Body>
                    </>
                  )}
                </BlockQuote>
                {active ? (
                  <Stack sx={{ pr: 4 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Stack spacing={2}>
                        <Text.H6>Gift Details *</Text.H6>
                        <Fields.Text
                          label="Your Gift"
                          name="amount"
                          error={!!errors?.amount}
                          helperText={errors?.amount?.message}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: Fields.Amount,
                          }}
                          {...register('amount', {
                            required: 'This field is required',
                            validate: {
                              positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                              min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
                            },
                            onChange: handleTotalGrantRequestAmountChange,
                          })}
                        />
                        <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
                        <Scheduled
                          register={register}
                          errors={errors}
                          control={control}
                          getValues={getValues}
                          setValue={setValue}
                          entity="Gift"
                        />
                        <Stack spacing={3}>
                          <Row>
                            <FormControlLabel
                              control={<Checkbox checked={addTipOpen} onChange={handleSetAddTipOpen} />}
                              label="I want to help cover GiveWise’s transaction fees."
                            />
                            <Tooltip title="Despite our efficient digital processes that keep transaction costs low, there are still fees for money movement. Your Courtesy Payment is appreciated">
                              {/* <Text.Body> */}
                              <Icons.Help />
                              {/* </Text.Body> */}
                            </Tooltip>
                          </Row>
                          {addTipOpen && (
                            <Fields.Text
                              label="Courtesy Payment"
                              name="tip"
                              error={!!errors?.tip}
                              helperText={errors?.tip?.message}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              {...register('tip', {
                                ...validations.number,
                                onChange: handleTipChange,
                              })}
                            />
                          )}
                        </Stack>
                        <div
                          style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: 'darkgrey',
                            marginTop: '24px',
                          }}
                        />
                        <Text.Body>Purpose of Gift</Text.Body>
                        <Stack>
                          <FormControl error={!!errors?.purposeOfGrant} fullWidth>
                            <InputLabel id="purposeOfGrantLabel">Please select the gift designation</InputLabel>
                            <Select
                              value={getValues('purposeOfGrant')}
                              aria-label="Please select the gift designation"
                              labelId="purposeOfGrantLabel"
                              label="Please select the gift designation"
                              {...register('purposeOfGrant')}
                              onChange={(e) => {
                                setValue('purposeOfGrant', e.target.value, { shouldValidate: true })
                              }}
                            >
                              {purposeOfGiftOptions()}
                            </Select>
                            {errors?.purposeOfGrant && (
                              <FormHelperText>{errors?.purposeOfGrant?.message}</FormHelperText>
                            )}
                          </FormControl>
                          {getValues('purposeOfGrant') &&
                            getValues('purposeOfGrant') !== 'Thanks for the work you do!' && (
                              <Fields.Text
                                label={purposeOfGrantNoteLabel}
                                name="purposeOfGrantNote"
                                error={!!errors?.purposeOfGrantNote}
                                helperText={errors?.purposeOfGrantNote?.message}
                                {...register('purposeOfGrantNote')}
                              />
                            )}
                        </Stack>
                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...register('anonymous')}
                                checked={getValues('anonymous')}
                                onChange={(e) => {
                                  setValue('anonymous', e.target.checked, { shouldValidate: true })
                                }}
                              />
                            }
                            label="I wish to remain anonymous"
                          />
                        </FormControl>
                        <div
                          style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: 'darkgrey',
                            marginTop: '12px',
                          }}
                        />
                        <Text.Bold>Self-attestation *</Text.Bold>
                        <FormControl error={!!errors?.selfAttestation}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  color: errors?.selfAttestation ? palette.red : 'black',
                                }}
                                {...register('selfAttestation', { ...validations.required })}
                                checked={getValues('selfAttestation')}
                                onChange={(e) => {
                                  setValue('selfAttestation', e.target.checked, { shouldValidate: true })
                                }}
                              />
                            }
                            label="I certify that my gift is for a permitted purpose
                            and will not result in a more than incidental benefit to me or persons related to me."
                          />
                          {errors?.selfAttestation && (
                            <FormHelperText>{errors?.selfAttestation?.message}</FormHelperText>
                          )}
                        </FormControl>
                        <div
                          style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: 'darkgrey',
                            margin: '12px 0px',
                          }}
                        />
                        <Text.AltBody sx={{ alignSelf: 'flex-end' }}>
                          Gift to Charity: ${displayFinalGiftValue}
                        </Text.AltBody>
                        <Text.AltBody sx={{ alignSelf: 'flex-end' }}>
                          Courtesy Payment: ${displayFinalTipValue}
                        </Text.AltBody>
                        <Text.AltBody sx={{ alignSelf: 'flex-end' }}>
                          <b>Total:</b> ${roundTo2DecimalPlaces(totalGrantRequestAmount)}
                        </Text.AltBody>
                        <Button
                          disabled={loading}
                          type="submit"
                          sx={{ alignSelf: 'flex-end' }}
                          startIcon={<Icons.Lock style={{ fontSize: '1.25em' }} />}
                        >
                          {isScheduledGrant ? 'Schedule Gift' : 'Give Now'}
                        </Button>
                        <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
                      </Stack>
                    </form>
                  </Stack>
                ) : (
                  <Stack>
                    <BlockQuote>
                      <Text.Body>
                        This organization is no longer listed in the GiveWise charity database. Please contact the
                        organization for more information.
                      </Text.Body>
                      {websiteAddress || emailAddress || contactPhoneNumber ? (
                        <>
                          {websiteAddress && (
                            <Text.Body>
                              <strong>Website: </strong> <Link href={`${websiteAddress}`}>{websiteAddress}</Link>
                            </Text.Body>
                          )}
                          {emailAddress && (
                            <Text.Body>
                              <strong>Email: </strong> <Link href={`mailto:${emailAddress}`}>{emailAddress}</Link>
                            </Text.Body>
                          )}
                          {contactPhoneNumber && (
                            <Text.Body>
                              <strong>Phone: </strong>{' '}
                              <Link href={`tel:+${contactPhoneNumber?.replace('[^0-9]', '')}`}>
                                {contactPhoneNumber}
                              </Link>
                            </Text.Body>
                          )}
                        </>
                      ) : (
                        <Text.Body>No contact information available</Text.Body>
                      )}
                    </BlockQuote>
                  </Stack>
                )}
              </Row>
            </Stack>
          </CardBox>
        </Stack>
      </Container>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success
          title="Success!"
          content={
            <Text.Body>
              Thank you! Your gift of ${getValues('amount')} is now {isScheduledGrant ? 'scheduled' : 'pending'}. You
              can check on the status of your gift at anytime from the{' '}
              <Link to={`/funds/${fundId}/activity`}>Activity</Link> page.
            </Text.Body>
          }
        >
          <Button onClick={closeSuccessDialog}>Done</Button>
        </Acknowledge.Success>
      </Dialog>
    </>
  )
}
