import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { Box, Button, CardBox, FavouriteCharityButton, Link, Skeleton, Stack, Text } from '.'
import { queries } from '../graphql'

export function RecentAndFavouriteCharities({ showAddress = false }) {
  const { fundId } = useParams()
  const { data: { me: { fund: { recentCharities = [] } = {} } = {} } = {}, loading: loadingRecent } = useQuery(
    queries.funds.myFundActivity,
    {
      variables: { fundId: parseInt(fundId, 9) },
    }
  )
  const { data: { me: { favouriteCharities = [] } = {} } = {}, loading: loadingFavourites } = useQuery(queries.user.me)

  if (loadingRecent || loadingFavourites)
    return (
      <>
        <Skeleton height="88px" />
        <Skeleton height="88px" />
        <Skeleton height="88px" />
      </>
    )

  /* avoid showing a charity more than once;
   * each request shouldn't contain the same charity but they could between them */

  const showFavourites = 9 - (recentCharities?.length || 0)
  const isInRecent = (test) => recentCharities.some(({ id }) => id === test)
  const charities = showFavourites
    ? recentCharities.concat(favouriteCharities.filter(({ id }) => !isInRecent(id)).slice(0, showFavourites))
    : recentCharities

  return (
    <>
      {charities.map((charity) => (
        <CharityCardItem key={charity.id} {...charity} showAddress={showAddress} fundId={fundId} />
      ))}
    </>
  )
}

function CharityCardItem({ id, accountName, mailingAddress, city, province, showAddress, fundId, sx, ...props }) {
  const hasAddress = mailingAddress && city && province
  const addressInfo = hasAddress ? `${mailingAddress}, ${city} ${province}` : 'Address Not Available'

  return (
    <CardBox sx={{ padding: 2, boxShadow: 0, border: '1px solid var(--light-grey)', ...sx }} {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <FavouriteCharityButton charityId={id} />
        <Box sx={{ flex: 1 }}>
          <Link to={`/funds/${fundId}/grant/${id}`} sx={{ fontWeight: 'bolder', textDecoration: 'none' }}>
            {accountName}
          </Link>
          <Text.Body>BN #{id}</Text.Body>
          {showAddress && <Text.Body>{addressInfo}</Text.Body>}
        </Box>
        <Button to={`/funds/${fundId}/grant/${id}`}>Give</Button>
      </Stack>
    </CardBox>
  )
}
