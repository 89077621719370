import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { createSearchParams, useNavigate } from 'react-router-dom'
import MuiCarousel from 'react-material-ui-carousel'
import { Circle } from '@mui/icons-material'

import {
  BigImageWithText,
  Button,
  CardBox,
  CharitiesSearchInput,
  Container,
  Grid,
  Icons,
  RecentAndFavouriteCharities,
  Stack,
  Text,
} from '../components'
import { toAmountString } from '../utils'
import { queries } from '../graphql'

export function MyGivingFund() {
  return (
    <Container maxWidth="lg" sx={{ mb: 0 }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Carousel />
        </Grid>
        <Grid item xs={12} md={4}>
          <BigYellowShareFundsLink />
        </Grid>

        <Grid item xs={12} md={8}>
          <GiveToCharity />
        </Grid>
        <Grid item xs={12} md={4}>
          <FundStatistics />
        </Grid>
      </Grid>
    </Container>
  )
}

function Carousel() {
  const { fundId } = useParams()
  const { data: { carousel = [] } = {}, loading } = useQuery(
    gql`
      query Carousel {
        carousel {
          title
          body
          footer
          link
          imageDataUri
        }
      }
    `
  )
  if (loading) return null
  if (!carousel.length) return <PlaceholderCarouselItem />

  return (
    <CardBox sx={{ p: 0, height: '100%', minHeight: '300px' }}>
      <MuiCarousel
        navButtonsAlwaysInvisible
        height="100%"
        sx={{
          height: '100%',
          'button:hover svg': { fill: 'white' },
        }}
        interval={12000}
        duration={200}
        indicatorContainerProps={{
          style: {
            display: carousel.length < 2 ? 'none' : undefined,
            position: 'absolute',
            width: 'auto',
            bottom: '6px',
            right: '6px',
            zIndex: '1',
          },
        }}
        activeIndicatorIconButtonProps={{
          sx: {
            svg: { fill: '#fffa' },
          },
        }}
        indicatorIconButtonProps={{
          sx: {
            svg: { fill: '#3333', stroke: '#fff9' },
          },
        }}
        IndicatorIcon={<Circle sx={{ fontSize: '100%' }} />}
      >
        {carousel
          /* eslint-disable react/no-array-index-key */
          .map(({ title, body, footer, link, imageDataUri }, index) => (
            <BigImageWithText
              key={index}
              title={title}
              body={body}
              footer={footer}
              link={link}
              imageUrl={imageDataUri}
            />
          ))}
      </MuiCarousel>
    </CardBox>
  )
}

function PlaceholderCarouselItem() {
  return (
    <BigImageWithText title="2023, What a year of giving!" footer="Read our Newsletter" link="https://givewise.ca" />
  )
}

function BigYellowShareFundsLink() {
  const { fundId } = useParams()
  return (
    <CardBox
      sx={{
        backgroundImage: 'radial-gradient(circle at top left, var(--light-yellow), var(--yellow))',
        color: 'var(--dark-blue)',
        height: '100%',
      }}
    >
      <Stack alignItems="center" height="100%">
        <Icons.Gift style={{ fontSize: '48px' }} />
        <Text.Bold textAlign="center">
          Give the gift of giving and <span style={{ whiteSpace: 'nowrap' }}>Share Funds</span>
        </Text.Bold>
        <Text.Body textAlign="center" flexGrow="1">
          It is the perfect gift for any occasion, like a charitable gift card. You choose the amount and send via
          email. Once your recipient receives it, they choose a charity to gift the money to.
        </Text.Body>
        <Button to={`/funds/${fundId}/share-funds-with-others`} sx={{ color: 'var(--dark-blue)' }}>
          Share Funds
        </Button>
      </Stack>
    </CardBox>
  )
}

function FundStatistics() {
  const { fundId } = useParams()
  const { data: { me: { fund = {} } = {} } = {} } = useQuery(queries.funds.myFund, {
    variables: { fundId: parseInt(fundId, 10) },
  })
  const {
    netTotalGifts,
    netTotalGiftsYTD,
    netTotalContributionsGiving,
    netTotalContributionsInvestment,
    netTotalContributionsGivingYTD,
    netTotalContributionsInvestmentYTD,
  } = fund
  if (!fund) return null
  return (
    <Stack>
      <Text.H2>History</Text.H2>
      <CardBox>
        <Stack spacing={1}>
          <Text.Bold>Total Contributions to My Fund</Text.Bold>
          <Text.AltBody>
            Lifetime: <b>{toAmountString(netTotalContributionsGiving + netTotalContributionsInvestment)}</b>
          </Text.AltBody>
          <Text.AltBody>
            Year to Date: <b>{toAmountString(netTotalContributionsGivingYTD + netTotalContributionsInvestmentYTD)}</b>
          </Text.AltBody>
          <Button size="small" to="contribute">
            Make a Contribution
          </Button>
        </Stack>
        <Stack mt={3} spacing={1}>
          <Text.Bold>Total Gifts</Text.Bold>
          <Text.AltBody>
            Lifetime: <b>{toAmountString(netTotalGifts)}</b>
          </Text.AltBody>
          <Text.AltBody>
            Year to Date: <b>{toAmountString(netTotalGiftsYTD)}</b>
          </Text.AltBody>
          <Button size="small" to="grant">
            Give to Charity
          </Button>
        </Stack>
      </CardBox>
    </Stack>
  )
}

function GiveToCharity() {
  const navigate = useNavigate()
  const { fundId } = useParams()
  const [searchText, setSearchText] = useState('')

  const onSubmit = (event) => {
    event.preventDefault()
    const search = createSearchParams({ s: searchText }).toString()
    navigate({ pathname: `/funds/${fundId}/grant/search-by-text`, search })
  }

  return (
    <Stack alignItems="stretch">
      <Text.H2>Give To Charity</Text.H2>

      <form onSubmit={onSubmit}>
        <CharitiesSearchInput onChange={(e) => setSearchText(e.target.value)} />
      </form>

      <RecentAndFavouriteCharities />
    </Stack>
  )
}
