import { Box as MuiBox } from '@mui/material'

import { Text } from '..'

export function CardBox({ title = null, children, sx, ...props }) {
  return (
    <MuiBox
      className="card-box"
      sx={{ padding: 4, boxShadow: 3, backgroundColor: 'white', width: '100%', boxSizing: 'border-box', ...sx }}
      {...props}
    >
      {title ? <Text.H4 sx={{ mb: 2 }}>{title}</Text.H4> : null}
      {children}
    </MuiBox>
  )
}
