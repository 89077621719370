import { gql } from '@apollo/client'

export const myTaxReceipt = gql`
  query myTaxReceipt($fundId: Int, $year: Int) {
    me {
      id
      fund(id: $fundId) {
        id
        taxReceipt(year: $year) {
          url
        }
      }
    }
  }
`
