import { useState, useRef, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, useController } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DataGrid, gridRowCountSelector } from '@mui/x-data-grid'

import {
  Acknowledge,
  Button,
  Box,
  Container,
  ContentBox,
  Fields,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  Typography,
  Row,
  Select,
  Stack,
  Switch,
  Text,
  useAlert,
  RadioGroup,
  CardBox,
  Icons,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { MiniGrid } from './mini-grid'
import DonationReasons from './donation-reasons'

export function CharityPortalProjects() {
  const { id: charityId } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { afterLogin } = state ?? {}
  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId: useParams().id },
  })
  const sources = ['gifts', 'disbursements', 'recurring-gifts']

  return (
    <Container maxWidth="lg">
      <Text.H1>Gift Designations</Text.H1>
      {/* <br />
      <Text.H7>Coming soon...</Text.H7>
      <br />
      <br /> */}
      <DonationReasons />
    </Container>
  )
}
