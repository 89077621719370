import { forwardRef, useImperativeHandle, useMemo } from 'react'
import invariant from 'invariant'

import { Stack } from '../../../components'
import * as SecuritiesPayment from '../../../components/payment/securities'
import { mutations } from '../../../graphql'
import { fromAmountField } from '../../../utils'

export const PaymentUI = forwardRef(({ register, control, formState }, ref) => {
  useImperativeHandle(ref, () => ({}))

  return (
    <Stack spacing={2}>
      <SecuritiesPayment.DeliveringCustodianInformation {...{ register, control, formState }} />
      <SecuritiesPayment.Acknowledgement {...{ control }} />
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails, payment }) {
  const { amountType, securities } = giftDetails
  invariant(amountType === 'securities', 'amountType must be securities')
  invariant(Array.isArray(securities), 'invalid securities')

  const results = await Promise.all(
    securities.map((sec) =>
      client
        .mutate({
          mutation: mutations.contributions.createContributionRecord,
          variables: {
            fundId,
            amount: fromAmountField(sec.amount),
            paymentType: 4,
            description:
              `${sec.securitiesDescription} (${sec.fundSymbolCUSIP}) - ${sec.quantity} Units` || 'unspecified security',
            taxReceipted: true,
          },
        })
        .then(({ data }) => data.createContributionRecord)
    )
  )

  return results
}

export function SuccessUI({ me, securities, payment, contributions }) {
  const letterOfDirectionPdf = useMemo(
    () =>
      SecuritiesPayment.fetchGeneratedLetterOfDirectionPdf({
        me,
        securities,
        deliveringCustodianInfo: payment,
        contributionIds: contributions.map((contribution) => contribution.id),
      }),
    [contributions, me, payment, securities]
  )

  return (
    <>
      <p>To complete the contribution, you will now need to initiate the transfer of securities to GiveWise.</p>
      <p>
        For your convenience, here is a link to download a “Gift of Securities - Letter of Direction” that can be shared
        with the Initiating Broker.
      </p>
      <p>
        Please be aware that a tax receipt will not be populated until the security has been received and is liquidated.
        NOTE: There may be adjustments due to fluctuations in value and exchange.
      </p>

      <SecuritiesPayment.GeneratedLetterOfDirection letterOfDirectionPdf={letterOfDirectionPdf} />
    </>
  )
}
