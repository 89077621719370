import { gql } from '@apollo/client'

export const initiateDafTransfer = gql`
  mutation initiateDafTransfer($data: InitiateDafTransferInput!) {
    initiateDafTransfer(data: $data) {
      id
      amount
      fee
      netAmount
      sentTo
      state
      type
      description
      initiatingUserId
      initiatingFundId
      receivingUserId
      receivingFundId
      createdAt
      canceledAt
      receivedAt
    }
  }
`

export const receiveDafTransfer = gql`
  mutation receiveDafTransfer($data: ReceiveDafTransferInput!) {
    receiveDafTransfer(data: $data) {
      id
      amount
      fee
      netAmount
      sentTo
      state
      type
      description
      initiatingUserId
      initiatingFundId
      receivingUserId
      receivingFundId
      createdAt
      canceledAt
      receivedAt
    }
  }
`

export const cancelDafTransfer = gql`
  mutation cancelDafTransfer($id: Int!) {
    cancelDafTransfer(id: $id) {
      id
      amount
      fee
      netAmount
      sentTo
      state
      type
      description
      initiatingUserId
      initiatingFundId
      receivingUserId
      receivingFundId
      createdAt
      canceledAt
      receivedAt
    }
  }
`

export const userUpdateRecurrentDafTransfer = gql`
  mutation userUpdateRecurrentDafTransfer($data: UpdateRecurrentDafTransferInput!) {
    userUpdateRecurrentDafTransfer(data: $data) {
      id
    }
  }
`

export const cancelRecurrentDafTransfer = gql`
  mutation cancelRecurrentDafTransfer($id: Int!) {
    cancelRecurrentDafTransfer(id: $id) {
      id
      nextScheduledTransfer
      state
    }
  }
`

export const dafTransferReminder = gql`
  mutation dafTransferReminder($ids: [Int!]!) {
    dafTransferReminder(ids: $ids)
  }
`

export const cancelDafTransferBulk = gql`
  mutation cancelDafTransferBulk($ids: [Int!]!) {
    cancelDafTransferBulk(ids: $ids)
  }
`
