import { forwardRef, useImperativeHandle, useRef } from 'react'

import { Stack } from '../../../components'
import * as CreditCardGuest from '../../../components/payment/credit-card'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ register }, ref) => {
  const cardApi = useRef()

  useImperativeHandle(ref, () => ({
    getPaymentMethodId: async (values) => {
      try {
        const paymentMethodId = await cardApi.current.postToStripe(values)
        return paymentMethodId
      } catch (e) {
        throw new Error(`This guest card cannot be used to finalize the transaction: ${e.message || e.type}`)
      }
    },
  }))

  return (
    <Stack spacing={2}>
      <CreditCardGuest.GuestForm ref={cardApi} register={register} />
      <CreditCardGuest.FeesNotice />
    </Stack>
  )
})

export async function getPaymentValues({ paymentMethodApi, values }) {
  const paymentMethodId = await paymentMethodApi.current.getPaymentMethodId(values)
  const { stepperState, ...other } = values
  return { ...other, paymentMethodId }
}

export async function createContributions({ client, fundId, giftDetails, payment, userId }) {
  const { isRecurring, recurringInterval, processingDate } = giftDetails
  const { paymentMethodId } = payment

  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createGuestContribution,
      variables: {
        userId,
        data: {
          paymentMethodId,
          fundId,
          amount: amounts.total,
          isRecurring,
          recurringInterval,
          processingDate,
        },
      },
    })
    .then(({ data }) => data.guestContribute)

  return [contribution]
}
