import { useContext, useEffect, useState } from 'react'

import { LocalStorageContext, SHARE_FUND_CODE } from '../local-storage/localStorage'
import { Icons, Link, Stack, Text } from '..'

export function NoticeHeader() {
  const { addSubscriber, getValue, removeSubscriber } = useContext(LocalStorageContext)
  const [val, setVal] = useState(getValue(SHARE_FUND_CODE))
  const val2 = getValue(SHARE_FUND_CODE)

  useEffect(() => {
    const listener = () => setVal(getValue(SHARE_FUND_CODE))
    addSubscriber(SHARE_FUND_CODE, listener)
    return () => {
      removeSubscriber(SHARE_FUND_CODE, listener)
    }
  })

  return <Stack>{val2 && <ClaimShareFundNotice {...JSON.parse(val2 ?? {})} />}</Stack>
}

function ClaimShareFundNotice({ id: shareId, code }) {
  return (
    <LinkNotice link={`/receive-a-daf-transfer/${shareId}/${code}`} sx={{ width: '100%' }}>
      <Icons.Gift />
      <Text.Body sx={{ paddingLeft: 1, paddingRight: 1 }}>Claim Your Shared Funds</Text.Body>
      <Icons.Gift />
    </LinkNotice>
  )
}

function LinkNotice({ link, children, sx }) {
  return (
    <Link
      to={link}
      sx={{
        display: 'flex',
        flexGrow: 1,
        backgroundColor: 'var(--light-yellow)',
        padding: 1,
        justifyContent: 'center',
        ...sx,
      }}
    >
      {children}
    </Link>
  )
}
