import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'

import SearchResultCard from './search-result-card'
import { Text, ToggleButtons } from '../../components'
import { TroubleMessage } from './trouble-message'

export default function SearchResultCardsContainer({
  title,
  charitiesAndFunds,
  viewToggleOnClicks,
  viewToggleState,
  cardCount,
  page,
  onPageChange,
}) {
  const [pageIndex, setPageIndex] = useState(0)
  const scrollToElement = () => {
    const targetElement = document.getElementById('search-result-header')
    if (targetElement) {
      const targetElementPosition = targetElement.getBoundingClientRect()
      const scrollToY = window.pageYOffset + targetElementPosition.top - 70 // Adjusted for top nav header
      window.scrollTo({
        top: scrollToY,
        behavior: 'smooth',
      })
    }
  }
  const handleChangePage = (event, newPage) => {
    onPageChange(newPage)
    setPageIndex(newPage)
    scrollToElement()
  }
  useEffect(() => {
    setPageIndex(page)
  }, [page])

  return (
    <>
      <div id="search-result-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* {title ? <Text.H2>{title}</Text.H2> : filters} */}
        <Text.H2>{title || 'Results'}</Text.H2>
        <ToggleButtons onClicks={viewToggleOnClicks} viewToggleState={viewToggleState} />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <TroubleMessage />
      </div>

      <Grid container spacing={2}>
        {charitiesAndFunds.slice(0, 9).map((result) => (
          <Grid item xs={12} md={4} key={result.id}>
            {/* eslint-disable-next-line no-underscore-dangle */}
            {result.__typename === 'Charity' ? (
              <SearchResultCard charity={result} />
            ) : (
              <SearchResultCard fund={result} />
            )}
          </Grid>
        ))}
      </Grid>

      <TablePagination
        component="div"
        count={cardCount}
        page={pageIndex}
        onPageChange={handleChangePage}
        pageSize={9}
        rowsPerPage={9}
        rowsPerPageOptions={[]}
      />
    </>
  )
}
