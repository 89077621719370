import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { formatContributionDescription, toAmountString, toDateString } from '../../utils'
import { queries } from '../../graphql'
import { CardBox, Container, DataGrid, FormControlLabel, FormGroup, Icons, Switch } from '../../components'
import { Filters } from './filters'

export function InvestmentAccount() {
  const [showContributions, setShowContributions] = useState(true)
  const [showBalanceAdjustements, setShowBalanceAdjustements] = useState(true)
  const { fundId } = useParams()
  const { loading, data: { me: { fund: { contributions = [], balanceAdjustments = [] } = {} } = {} } = {} } = useQuery(
    queries.funds.myFundActivity,
    {
      variables: { fundId: Number(fundId) },
    }
  )
  const IWContributions = useMemo(
    () => contributions.filter((c) => c.walletType === 'investment') || [],
    [contributions]
  )
  const IWBalanceAdjustments = useMemo(
    () => balanceAdjustments.filter((ba) => ba.walletType === 'investment') || [],
    [balanceAdjustments]
  )
  const rows = [
    ...(showBalanceAdjustements
      ? IWBalanceAdjustments.map((iwba) => ({
          ...iwba,
          id: `ba${iwba.id}`,
          recordId: iwba.id,
          date: iwba.createdAt,
          state: 'posted',
        }))
      : []),
    ...(showContributions
      ? IWContributions.map((iwc) => ({
          ...iwc,
          id: `c${iwc.id}`,
          recordId: iwc.id,
          date: iwc.receiptedDate,
          description: formatContributionDescription(iwc),
        }))
      : []),
  ]

  const columns = [
    {
      field: '__typename',
      headerName: '',
      pinnable: true,
      hideable: true,
      flex: 0.5,
      align: 'center',
      minWidth: 50,
      renderCell: ({ row } = {}) => <Icons.ActivityItem row={row} />,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      pinnable: true,
      hideable: true,
      flex: 1,
      minWidth: 125,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'fee',
      headerName: 'Fee',
      hideable: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'netAmount',
      headerName: 'Net Amount',
      hideable: true,
      flex: 1,
      minWidth: 130,
      valueFormatter: ({ value } = {}) => toAmountString(value),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      minWidth: 300,
    },
    {
      field: 'taxReceipted',
      headerName: 'Tax Receipted',
      hideable: true,
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ value, row: { __typename } = {} } = {}) =>
        __typename === 'Contribution' ? <Icons.Boolean boolean={value} /> : '',
    },
    {
      field: 'isRecurring',
      headerName: 'Is Recurring',
      hideable: true,
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: ({ row: { recurrentContributionId, recurrentGrantRequestid, __typename } = {} } = {}) =>
        __typename === 'Contribution' ? (
          <Icons.Boolean boolean={!!recurrentContributionId || !!recurrentGrantRequestid} />
        ) : (
          ''
        ),
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 2,
      minWidth: 125,
      type: 'date',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value } = {}) => <Icons.State state={value} />,
    },
  ]

  return (
    <Container maxWidth="lg">
      <CardBox>
        <DataGrid
          rows={rows}
          columns={columns}
          autoRowHeight
          getRowId={({ __typename, id }) => `${__typename}-${id}`}
          loading={loading}
          filters={
            <Filters
              showContributions={showContributions}
              setShowContributions={setShowContributions}
              showBalanceAdjustements={showBalanceAdjustements}
              setShowBalanceAdjustements={setShowBalanceAdjustements}
            />
          }
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {
                fee: false,
                taxReceipted: false,
                isRecurring: false,
              },
            },
          }}
        />
      </CardBox>
    </Container>
  )
}
