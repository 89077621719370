import { add, minimum, multiply } from 'dinero.js'

import { fromAmountField } from '../../utils'
import { cadFromDecimal, decimalFromCad } from './dinero-cad'
import { calculateSumForSecurities } from '../../components/payment/securities'

function getAmountSum(giftDetails) {
  if (giftDetails.amountType === 'securities') {
    // Use sum of securities values when selected
    return calculateSumForSecurities(giftDetails.securities)
  }

  // Default amount algorithm
  return giftDetails.amount ? parseFloat(fromAmountField(giftDetails.amount)) : 0
}

/**
 * Calculate the tip value for a giftDetails object
 */
export function calculateTipAmount(giftDetails) {
  // Securities do not support tips
  if (giftDetails.amountType === 'securities') return 0

  if (giftDetails.addTip) {
    if (giftDetails.customTip) return fromAmountField(giftDetails.customTip)
    const amount = getAmountSum(giftDetails)
    // Default tip amount set to 3% of grant amount to a max of $25, and can be overridden by the user

    const threePercentOfAmount = multiply(cadFromDecimal({ amount }), {
      amount: 3,
      scale: 2,
    })
    const twentyFiveDollars = cadFromDecimal({ amount: 25 })
    return decimalFromCad(minimum([threePercentOfAmount, twentyFiveDollars]))
  }

  return 0
}

/**
 * Calculate the decimal number amount, tip, and total for a giftDetails object
 */
export function calculateGiftAmount(giftDetails) {
  const amount = getAmountSum(giftDetails)
  const tip = calculateTipAmount(giftDetails)
  const total = decimalFromCad(add(cadFromDecimal({ amount }), cadFromDecimal({ amount: tip })))

  return { amount, tip, total }
}
