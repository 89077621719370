import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import { Button, Container, ContentBox, Link, Row, Stack, Text, Typography } from '../components'
import { LoginForm } from '../components/login/login-form'

export function Login({ confirmAccountLogin = false, charityUser = false, afterLogin: afterLoginProp }) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { afterLogin: afterLoginState, prefillEmail } = state ?? {}
  const afterLogin = afterLoginProp ?? afterLoginState

  const onCompleted = () => {
    let a = charityUser ? '/charities/confirm' : '/'

    if (afterLogin) {
      a = afterLogin
    }

    console.log(`navigating to ${a}`)
    console.log(charityUser)
    navigate(a)
  }

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        {confirmAccountLogin ? (
          <>
            <Text.H1>{charityUser ? 'My Charity Account' : 'My Giving Fund'}</Text.H1>
            <Text.H5 component="h1" align="left" data-testid="welcomeTitle">
              To Confirm Account, Please Log In
            </Text.H5>
          </>
        ) : (
          <Text.H5 component="h1" align="center" data-testid="welcomeTitle">
            Welcome Back To GiveWise Canada!
          </Text.H5>
        )}

        <ContentBox border>
          <LoginForm {...{ prefillEmail, onCompleted }} />
        </ContentBox>

        {!confirmAccountLogin && (
          <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={4} px={4}>
            <Typography variant="body2" data-testid="notHaveAccountText">
              Don&rsquo;t have an account with us yet?
            </Typography>
            <Button
              component={RouterLink}
              to="/sign-up"
              state={{ afterLogin }}
              size="large"
              color="info"
              data-testid="signUpButton"
            >
              Sign Up
            </Button>
          </Row>
        )}

        <Row alignSelf="flex-end" justifyContent="flex-end" alignItems="center" spacing={4} pr={4}>
          <Text.Body variant="body2" data-testid="haveAccountText">
            To delete a GiveWise Giving Fund, please email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
          </Text.Body>
        </Row>
      </Stack>
    </Container>
  )
}
