export const required = {
  required: 'This field is required',
}

export const number = {
  pattern: {
    value: /[0-9]+(\.[0-9][0-9])?/,
    message: 'Invalid currency format',
  },
}

export const email = {
  pattern: {
    value:
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i, // eslint-disable-line no-control-regex
    message: 'Invalid email format',
  },
}

export const postalCode = {
  pattern: {
    value: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z][\s-]?[0-9][A-Z][0-9]$/i,
    message: 'Invalid postal code format. Must follow pattern: N1N1N1, N1N-1N1, or N1N 1N1',
  },
}

export const phoneNumber = {
  pattern: {
    value: /^\(?([0-9A-Z]{3})\)?[\s-.●]?([0-9A-Z]{3})[\s-.●]?([0-9A-Z]{4})$/i,
    message: 'Invalid phone number format. Must follow pattern: 123 456 7890, (123) 456-7890, or 123.456.7890',
  },
}

export const description = {
  pattern: {
    value: /^[a-zA-Z0-9\s,.'-]{0,1000}$/i,
    message: 'Invalid description format. Can include letters, numbers, and basic punctuation.',
  },
  maxLength: {
    value: 1000,
    message: 'Description must be at most 1000 characters long',
  },
}
