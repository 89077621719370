import { useApolloClient, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  Dialog,
  Icons,
  Link,
  Row,
  Stack,
  useAlert,
  useDialog,
} from '../../../components'
import * as SecuritiesPayment from '../../../components/payment/securities'
import { mutations, queries } from '../../../graphql'
import { fromAmountField } from '../../../utils'
import { openApiFileWithData } from '../../../utils/open-api-file'

export function Securities() {
  const client = useApolloClient()
  const { fundId } = useParams()
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const { data: { me } = {}, loading } = useQuery(queries.user.me)
  const { profile } = me ?? {}
  const { number } = fund
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [formData, setFormData] = useState({})
  const {
    register,
    handleSubmit,
    formState,
    setError,
    getValues,
    setValue,
    watch,
    reset: resetForm,
    control,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      address: { province: '' },
      securities: [{ securitiesDescription: '', fundSymbolCUSIP: '', quantity: 0, amount: 0 }],
      pageLanguageConfirmed: false,
    },
  })

  const { isSubmitting, errors = {} } = formState
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()

  const { address = {} } = {}
  const province = watch('address.province')
  useEffect(() => {
    setValue('address.province', getValues('address.province'))
  }, [province])

  const pageLanguageConfirmed = watch('pageLanguageConfirmed')

  const [letterOfDirectionPdf, setLetterOfDirectionPdf] = useState(null)
  const createContributionRecords = async (data) => {
    try {
      if (data && Array.isArray(data.securities)) {
        const promises = data.securities.map((sec) =>
          client.mutate({
            mutation: mutations.contributions.createContributionRecord,
            variables: {
              fundId: Number(fundId),
              amount: Number(sec.amount),
              paymentType: 4,
              description:
                `${sec.securitiesDescription} (${sec.fundSymbolCUSIP}) - ${sec.quantity} Units` ||
                'unspecified security',
              taxReceipted: true,
            },
            refetchQueries: [
              { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
              { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
              { query: queries.paymentMethods.myLinkedBanks },
            ],
            awaitRefetchQueries: true,
          })
        )
        const results = await Promise.all(promises)
        const ids = results.map((result) => result.data.createContributionRecord.id)

        setLetterOfDirectionPdf(
          SecuritiesPayment.fetchGeneratedLetterOfDirectionPdf({
            me,
            securities: data?.securities,
            deliveringCustodianInfo: data,
            contributionIds: ids,
          })
        )
        openDialog()
      } else {
        throw new Error('Invalid securities data')
      }
    } catch (e) {
      setAlert({
        message: `Failed to create a securities contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  const onSubmit = async (data) => {
    const convertedData = {
      ...data,
      securities: data.securities.map((sec) => ({
        ...sec,
        quantity: parseFloat(fromAmountField(sec.quantity)),
        amount: parseFloat(fromAmountField(sec.amount)),
      })),
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...convertedData,
    }))
    createContributionRecords(convertedData)
  }

  const closeSuccessDialog = () => {
    resetForm()
    closeDialog()
    setLetterOfDirectionPdf(null)
  }

  return (
    <Container maxWidth="lg">
      <CardBox title="Securities">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <SecuritiesPayment.DeliveringCustodianInformation {...{ register, control, formState }} />
            <SecuritiesPayment.SecuritiesToTransferInKind {...{ register, control, formState }} />
            <SecuritiesPayment.Acknowledgement {...{ control }} />

            <Row sx={{ marginTop: '2rem!important' }} alignSelf="flex-end">
              <Button
                type="submit"
                disabled={isSubmitting || !pageLanguageConfirmed}
                data-testid="createSecuritiesContribution"
              >
                Submit
              </Button>
            </Row>
            <Alert {...alertProps} />
          </Stack>
        </form>
      </CardBox>

      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" />
        <div style={{ paddingBottom: '64px' }}>
          <p>You have successfully initiated a contribution.</p>
          <p>To complete the contribution, you will now need to initiate the transfer of securities to GiveWise.</p>
          <p>
            For your convenience, here is a link to download a “Gift of Securities - Letter of Direction” that can be
            shared with the Initiating Broker.
          </p>
          <p>
            Please be aware that a tax receipt will not be populated until the security has been received and is
            liquidated. NOTE: There may be adjustments due to fluctuations in value and exchange.
          </p>

          <SecuritiesPayment.GeneratedLetterOfDirection me={me} letterOfDirectionPdf={letterOfDirectionPdf} />
        </div>
        <Button
          onClick={closeSuccessDialog}
          to={`/funds/${fundId}/activity/giving-wallet`}
          sx={{ width: 'fit-content', position: 'absolute', right: '32px', bottom: '16px' }}
        >
          Done
        </Button>
      </Dialog>
    </Container>
  )
}
