import { useEffect } from 'react'

import { useAuth } from '../hooks'

export function Logout() {
  const [, { logout }] = useAuth()
  useEffect(() => {
    async function logoutUser() {
      await logout()
    }
    logoutUser()
  })
  return null
}
