import { gql } from '@apollo/client'

export const myCharity = gql`
  query MyCharity {
    myCharity {
      id
    }
  }
`

export const charity = gql`
  query Charity($charityId: String!, $fundId: Int, $charityToken: String) {
    charity(id: $charityId, token: $charityToken) {
      id
      designationCode
      categoryCode
      subcategoryCode
      accountName
      legalName
      mailingAddress
      city
      province
      country
      postalCode
      contactName
      contactPhoneNumber
      emailAddress
      websiteAddress
      active
      logo {
        imagePath
        logoPath
        logo
      }
      purposeOfGifts {
        id
        reason
        active
        charityId
      }
      socialMediaLinks {
        id
        link
        platform
        charityId
      }
      totalGrantRequestsForCharity(fundId: $fundId)
      totalGrantRequestsForCharityYTD(fundId: $fundId)
    }
  }
`

export const getCharityBankAccounts = gql`
  query getCharityBankAccounts($charityId: String!) {
    getCharityBankAccounts(charityId: $charityId) {
      id
      paymentType
      details
      status
      default
      canDelete
      userId
      charityId
    }
  }
`

export const validateInviteToken = gql`
  query ValidateInviteToken($data: ValidateInviteTokenInput!) {
    validateInviteToken(data: $data)
  }
`

export const getAvailableXwikiTemplates = gql`
  query GetAvailableXwikiTemplates {
    getAvailableXwikiTemplates
  }
`

export const getAvailableXwikiGenerated = gql`
  query GetAvailableXwikiGenerated {
    getAvailableXwikiGenerated
  }
`

export const getSavedDescription = gql`
  query getSavedDescription($id: String!) {
    getSavedDescription(id: $id) {
      charityId
      description
      tagline
    }
  }
`

export const getSavedDescriptionSocialMedia = gql`
  query getSavedDescriptionSocialMedia($id: String!) {
    getSavedDescriptionSocialMedia(id: $id) {
      id
      link
      platform
      charityId
    }
  }
`

// @note This API is for admins
export const getCharityLogo = gql`
  query getCharityLogo($id: String!) {
    getCharityLogo(id: $id) {
      charityId
      imagePath
      logoPath
      logo
    }
  }
`

export const getMyCharityDescription = gql`
  query getMyCharityDescription($id: String!) {
    getMyCharityDescription(id: $id) {
      charityId
      description
      tagline
    }
  }
`

export const getMyCharityDescriptionSocialMedia = gql`
  query getMyCharityDescriptionSocialMedia($id: String!) {
    getMyCharityDescriptionSocialMedia(id: $id) {
      id
      link
      platform
      charityId
    }
  }
`

export const getMyCharityLogo = gql`
  query getMyCharityLogo($id: String!) {
    getMyCharityLogo(id: $id) {
      charityId
      imagePath
      logoPath
      logo
    }
  }
`

export const searchCharities = gql`
  query SearchCharities($search: String!, $page: Int!, $onlyShowFavourites: Boolean, $categoryCodes: [String!]) {
    searchCharities(
      search: $search
      limit: 9
      page: $page
      onlyShowFavourites: $onlyShowFavourites
      categoryCodes: $categoryCodes
    ) {
      count
      data {
        id
        accountName
        mailingAddress
        city
        province
        categoryCode
        logo {
          imagePath
          logoPath
          logo
        }
      }
    }
  }
`

export const getCharityInvites = gql`
  query getCharityInvites($charityId: String!) {
    getCharityInvites(id: $charityId) {
      id
      charityId
      status
      sentAt
      charityUserId
      userId
      token
      user {
        id
        email
        confirmationToken
      }
    }
  }
`

export const getCharityInvitesCharityPortal = gql`
  query getCharityInvitesCharityPortal($charityId: String!) {
    getCharityInvitesCharityPortal(id: $charityId) {
      id
      charityId
      status
      sentAt
      charityUserId
      userId
      token
      user {
        id
        email
        confirmationToken
      }
    }
  }
`

export const searchCharitiesByLocation = gql`
  query SearchCharitiesByLocation(
    $city: String!
    $province: String!
    $postalCode: String!
    $page: Int!
    $onlyShowFavourites: Boolean
  ) {
    searchCharitiesByLocation(
      city: $city
      province: $province
      postalCode: $postalCode
      limit: 9
      page: $page
      onlyShowFavourites: $onlyShowFavourites
    ) {
      count
      data {
        id
        accountName
        mailingAddress
        city
        province
      }
    }
  }
`

export const myCharityActivity = gql`
  query Charity($charityId: String!, $fundId: Int, $charityToken: String) {
    charity(id: $charityId, token: $charityToken) {
      id
      designationCode
      categoryCode
      subcategoryCode
      accountName
      legalName
      mailingAddress
      city
      province
      country
      postalCode
      contactName
      contactPhoneNumber
      emailAddress
      websiteAddress
      active
      charityPortalProgress
      pendingGiftsTotal
      lastDisbursementValue
      monthlyRecurringGiftsTotal
      purposeOfGifts {
        id
        reason
        active
        isDefault
        charityId
      }
      grants {
        id
        state
        sentAt
      }
      recurringGrantRequests {
        id
        userId
        state
        fund {
          id
          name
        }
        amount
        anonymous
        frequency
        grantNote
        nextScheduledGrant
        createdAt
      }
      grantSummaries {
        service
        currency
        amount
        beneficiary
        address
        city
        province
        country
        postalCode
        clientReferenceNumber
        institutionNumber
        transitNumber
        accountNumber
        state
        createdAt
        sentAt
        grantId
      }
      grantRequests {
        id
        userId
        fundId
        charityId
        grantNote
        amount
        netAmount
        state
        processingDate
        recurrentGrantRequestId
        tip
        anonymous
        processingNote
        checkNumber
        clearedDate
        grantId
        approvedAt
        processedAt
        sentAt
        legacyId
        createdAt
        updatedAt
      }
      socialMediaLinks {
        id
        link
        platform
        charityId
      }
      totalGrantRequestsForCharity(fundId: $fundId)
      totalGrantRequestsForCharityYTD(fundId: $fundId)
    }
  }
`
export const getCharityCategories = gql`
  query CharityCategory {
    charityCategory {
      id
      name
      iconName
      categoryCodes
    }
  }
`
