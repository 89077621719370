import { useParams } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import { Button, FavouriteCharityButton, Link, CharityLogo, FundLogo, Text } from '../../components'

export default function SearchResultCard({ charity, fund }) {
  const { fundId: myFundId } = useParams()

  if ((!charity || Object.keys(charity).length === 0) && (!fund || Object.keys(fund).length === 0)) {
    return null // Render nothing if charity is null or empty
  }

  const type = charity ? 'Charity' : 'Generosity Fund'

  const address = `${charity?.mailingAddress ?? ''} ${charity?.city ?? ''}, ${charity?.province ?? ''}`
  const businessNumber = `BN #${charity?.id ?? ''}`

  return (
    <Card sx={{ height: '100%' }}>
      <CardMedia sx={{ height: 140, margin: '5px 5px 0 5px' }}>
        {charity && <CharityLogo charityId={charity.id} categoryCode={charity.categoryCode} logo={charity.logo} />}
        {fund && <FundLogo fundId={fund.id} />}
      </CardMedia>

      <CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 0, marginLeft: 0 }}>
          <b style={{ color: '#61bee8' }}>{type}</b>
          <Button
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ minWidth: 0, width: '24px', height: '24px', p: 0, backgroundColor: 'var(--white)' }}
            type="submit"
          >
            {charity && <FavouriteCharityButton charityId={charity?.id} />}
          </Button>
        </CardActions>

        <Text.H7>
          {charity && (
            <Link to={`../${charity?.id}`}>
              <strong>{charity?.accountName}</strong>
            </Link>
          )}
          {fund && (
            <Link to={`/funds/${myFundId}/share-funds-with-generosity-fund/${fund?.id}`}>
              <strong>{fund?.accountName}</strong>
            </Link>
          )}
        </Text.H7>
        {charity && (
          <>
            <Text.Body sx={{ margin: '5px 0' }}>{businessNumber}</Text.Body>
            <Text.Body>{address}</Text.Body>
          </>
        )}
      </CardContent>
    </Card>
  )
}
