import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { validations } from '../form'
import { mutations, queries } from '../../graphql'
import {
  Amount,
  Box,
  Button,
  CardBox,
  Dialog,
  Divider,
  Fields,
  IconButton,
  Icons,
  Link,
  Row,
  Stack,
  Text,
  Tooltip,
  useAlert,
  useDialog,
} from '..'
import { parseAndSetFormErrors } from '../../utils'
import palette from '../../palette.json'

export function FundSummary({ sx, cardProps, ...props }) {
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const { fundId } = useParams()
  const { data: { me: { fund = {} } = {} } = {} } = useQuery(queries.funds.myFund, {
    variables: { fundId: parseInt(fundId, 10) },
  })
  const { name, number, totalBalance, givingWalletBalance, investmentWalletBalance } = fund
  if (!fund) return null
  return (
    <>
      <CardBox sx={{ ...sx }} {...props} {...cardProps}>
        <Row sx={{ mb: 1 }} justifyContent="space-between">
          <Stack spacing={0} sx={{ width: 'auto' }}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              <Tooltip title="Fund names have no impact on your tax receipt. They are used for your own personal purposes only.">
                <Text.H4>{name}</Text.H4>
              </Tooltip>
              <IconButton onClick={openDialog}>
                <Icons.Edit />
                <Text.SR>edit fund name</Text.SR>
              </IconButton>
            </Box>
            <Text.Bold>Fund #{number}</Text.Bold>
          </Stack>
        </Row>
        <Stack>
          <Divider light />
          <Row spacing={2}>
            <FundSummaryItem
              value={totalBalance}
              copy="Giving Fund Balance"
              subcopy="Total Fund Balance"
              tooltip="Represents total value of Giving Wallet plus Investment Account"
            />
            <FundSummaryItem
              value={givingWalletBalance}
              copy="Giving Wallet"
              subcopy="Funds Available to Give"
              tooltip="Represents the value of funds available to give right now"
            />
            <FundSummaryItem
              value={investmentWalletBalance}
              copy="Investment Account"
              subcopy="Funds Held in Investment"
              tooltip="Represents the value of funds that are 'locked' as they are in investment"
            />
          </Row>
        </Stack>
      </CardBox>
      <Dialog {...dialogProps}>
        <UpdateFundNameForm fundId={fundId} name={name} close={closeDialog} />
      </Dialog>
    </>
  )
}

export function FundSummaryItem({ value, copy, subcopy, tooltip }) {
  return (
    <Stack spacing={0}>
      <Text.H5>
        <Amount value={value} />
      </Text.H5>
      <Text.Body>{copy}</Text.Body>
      <Tooltip title={tooltip}>
        <Text.AltBody>
          {`${subcopy} `}
          {tooltip && <Icons.HelpOutline sx={{ fontSize: '1rem', cursor: 'pointer' }} />}
        </Text.AltBody>
      </Tooltip>
    </Stack>
  )
}

function UpdateFundNameForm({ close, name, fundId }) {
  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [updateEmail] = useMutation(mutations.funds.updateFund, {
    onCompleted: () => {
      setAlert({
        message: 'Fund name successfully updated',
        severity: 'success',
        action: { label: 'close', onClick: close },
      })
    },
    onError: (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      setAlert({ message: 'Fund name update failed', severity: 'error' })
    },
  })

  const onSubmit = async ({ name: updatedName }) => {
    updateEmail({
      variables: { data: { id: parseInt(fundId, 10), name: updatedName } },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems="flex-end">
        <Text.H6 alignSelf="flex-start">Change Fund Name</Text.H6>
        <Stack mx={2}>
          <Text.Body>
            Fund names have no impact on your tax receipt. They are used for your own personal purposes only.
          </Text.Body>
          <Fields.Text
            type="text"
            label="Name *"
            defaultValue={name}
            error={!!errors?.name}
            helperText={errors?.name?.message}
            {...register('name', { ...validations.required })}
          />
        </Stack>
        <Row justifyContent="flex-end" alignItems="center" m={4}>
          <Button color="info" onClick={close}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit">
            update
          </Button>
        </Row>
        <Alert {...alertProps} />
      </Stack>
    </form>
  )
}
