import { useMutation, useQuery } from '@apollo/client'
import { COUNTRY_LIST, PROVINCES_LIST } from 'constants'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Button,
  CardBox,
  Fields,
  FormControl,
  Icons,
  InputLabel,
  MenuItem,
  Row,
  Select,
  Stack,
  Text,
  Tooltip,
  useAlert,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

function CharityUserProfileFormContent({ charity = {}, profile = {}, charityUser = {}, firstLogin, afterLogin }) {
  const navigate = useNavigate()
  const { register, handleSubmit, formState, setError, getValues, setValue, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: profile.firstName === charity.accountName ? '' : profile.firstName,
      middleInitials: profile.middleInitials,
      lastName: profile.lastName === charity.id ? '' : profile.lastName,
      phone: profile.phone,
      title: charityUser?.title ?? ' ',
    },
  })
  const { isSubmitting, errors = {} } = formState

  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [updateProfile] = useMutation(mutations.user.updateProfileWithoutAddress, {
    onCompleted: () =>
      firstLogin ? navigate(afterLogin ?? '/funds') : setAlert({ message: 'Profile Updated', severity: 'success' }),
    onError: (serverErrors) => {
      parseAndSetFormErrors(serverErrors, setError)
      setAlert({ message: 'Update Profile Failed', severity: 'error' })
    },
  })

  const onSubmit = async (data) => {
    updateProfile({
      variables: { data },
    })
  }

  return (
    <CardBox>
      <Text.H4>User Details</Text.H4>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Text.H6 sx={{ display: 'flex' }}>
            Name <Icons.Help sx={{ alignSelf: 'center', marginLeft: '.5rem' }} />
          </Text.H6>

          <Fields.Text
            inputProps={{ 'data-testid': 'firstName' }}
            label="First Name *"
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            {...register('firstName', { ...validations.required })}
          />

          <Fields.Text
            inputProps={{ 'data-testid': 'lastName' }}
            label="Last Name *"
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            {...register('lastName', { ...validations.required })}
          />

          <Fields.Text
            inputProps={{ 'data-testid': 'title' }}
            label="Job Title/Role"
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register('title')}
          />

          <Text.H6>Contact</Text.H6>
          <Fields.Text
            inputProps={{ 'data-testid': 'phoneNumber' }}
            type="text"
            label="Phone Number"
            error={!!errors.phone}
            helperText={errors?.phone?.message}
            defaultValue={profile?.phone}
            {...register('phone', { ...validations.phoneNumber })}
          />

          <Row sx={{ marginTop: '2rem!important' }} alignSelf="flex-end">
            <Button type="submit" disabled={isSubmitting} data-testid="updateProfileButton">
              Update Profile
            </Button>
          </Row>
          <Alert {...alertProps} />
        </Stack>
      </form>
    </CardBox>
  )
}

export function CharityUserProfileForm({ profile = {}, charityUser = {}, firstLogin, afterLogin }) {
  const { id: charityId } = useParams()
  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.charity, {
    variables: { charityId },
  })

  if (!charity) return ''

  return (
    <CharityUserProfileFormContent
      charity={charity}
      profile={profile}
      charityUser={charityUser}
      firstLogin={firstLogin}
      afterLogin={afterLogin}
    />
  )
}
