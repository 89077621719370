import { useQuery } from '@apollo/client'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Stack, Typography } from '../../../components'
import * as BankTransfer from '../../../components/payment/bank-transfer'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ register }, ref) => {
  const {
    data: { me: { linkedBanks = [] } = {} } = {},
    loading,
    refetch,
  } = useQuery(queries.paymentMethods.myLinkedBanks)

  const [isNewBankAccount, setIsNewBankAccount] = useState(false)

  useImperativeHandle(ref, () => ({}))

  useEffect(() => {
    refetch()
    if (loading || isNewBankAccount) return
    if (!linkedBanks || linkedBanks?.length < 1) {
      setIsNewBankAccount(true)
    }
  }, [loading, linkedBanks, isNewBankAccount, setIsNewBankAccount, refetch])

  return (
    <Stack spacing={2}>
      {isNewBankAccount ? (
        <BankTransfer.NewAccount setIsNewBankAccount={setIsNewBankAccount} linkedBanks={linkedBanks} />
      ) : (
        <BankTransfer.SavedAccounts
          register={register}
          linkedBanks={linkedBanks}
          setIsNewBankAccount={setIsNewBankAccount}
        />
      )}
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  // Use paymentMethodId from values
  return values
}

export async function createContributions({ client, fundId, giftDetails, payment }) {
  const { isRecurring, recurringInterval, processingDate } = giftDetails
  const { paymentMethodId } = payment

  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContribution,
      variables: {
        data: {
          paymentMethodId,
          fundId,
          amount: amounts.total,
          isRecurring,
          recurringInterval,
          processingDate,
        },
      },
      refetchQueries: [
        { query: queries.funds.myFund, variables: { fundId } },
        { query: queries.funds.myFundActivity, variables: { fundId } },
        { query: queries.funds.myRecurring, variables: { fundId } },
        { query: queries.paymentMethods.myLinkedBanks },
      ],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.contribute)

  return [contribution]
}

export function SuccessUI() {
  return <Typography>The bank transfer may take a couple days to clear.</Typography>
}
