import { Avatar, Badge } from '@mui/material'
import { faEnvelope, faWarning } from '@fortawesome/pro-solid-svg-icons'

import { Container, Icons, Link, Stack, Text } from '../../components'

export function SignupSuccessModal() {
  return (
    <Container maxWidth="sm" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Stack alignItems="center" spacing={4}>
        <Avatar
          sx={{
            width: `calc(3rem + 24px * 2)`,
            height: `calc(3rem + 24px * 2)`,
            bgcolor: 'black',
            color: 'background.default',
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            componentsProps={{ badge: { sx: { color: 'warning.main' } } }}
            badgeContent={<Icons.FaIcon style={{ fontSize: '2rem' }} icon={faWarning} />}
          >
            <Icons.FaIcon style={{ fontSize: '3rem' }} icon={faEnvelope} />
          </Badge>
        </Avatar>

        <Stack spacing={2}>
          <Text.H5 data-testid="signupSuccessMessage">Check your email to continue!</Text.H5>

          <Text.Body>Please use the link we sent you to confirm your email before you continue this gift.</Text.Body>
          <Text.Body>
            If you do not receive a confirmation email, please check your junk mail or email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> for assistance.
          </Text.Body>
          <Text.Body>
            After you&rsquo;ve confirmed your email, you can add a payment method and complete your gift.
          </Text.Body>
        </Stack>
      </Stack>
    </Container>
  )
}
