import { forwardRef } from 'react'
import NumberFormat from 'react-number-format'

const MAX_VAL = 999999.99
const lessThanOneMillion = ({ floatValue }) => floatValue <= MAX_VAL || floatValue == null

export const Amount = forwardRef((props, ref) => {
  const { onChange, setValue, getValue, value, ...other } = props
  return (
    <NumberFormat
      {...other}
      value={typeof getValue === 'function' ? getValue() : value ?? undefined}
      getInputRef={ref}
      isAllowed={lessThanOneMillion}
      onValueChange={(values) => {
        if (typeof setValue === 'function') {
          setValue(values.value)
        } else {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }
      }}
      mask
      allowEmptyFormatting
      allowLeadingZeros={false}
      thousandSeparator
      displayType="input"
      decimalScale={2}
    />
  )
})
