import { forwardRef } from 'react'

import { Button, Fields, Icons, InputAdornment, Text } from '.'

export const CharitiesSearchInput = forwardRef((props, ref) => (
  <Fields.Text
    fullWidth
    label="Search"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Button
            sx={{ minWidth: 0, width: '40px', height: '40px', p: 0, backgroundColor: 'var(--white)' }}
            type="submit"
          >
            <Icons.Search />
            <Text.SR>Submit</Text.SR>
          </Button>
        </InputAdornment>
      ),
    }}
    ref={ref}
    {...props}
  />
))
