import { ExportButton, FilterButton, TopToolbar as RaTopToolBar } from '..'

export function Toolbar({
  customExporter = null,
  customExportButton = null,
  maxResults = 100000,
  children,
  disabled = false,
  label = undefined,
  onClick = undefined,
}) {
  return (
    <RaTopToolBar>
      <FilterButton />
      {children}
      {customExportButton ?? (
        <ExportButton
          exporter={customExporter}
          maxResults={maxResults}
          disabled={disabled}
          label={label}
          onClick={onClick}
        />
      )}
    </RaTopToolBar>
  )
}
