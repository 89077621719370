import { BlockQuote, CardBox, Container, Link, Stack, Text } from '../components'

export function Successor() {
  return (
    <Container maxWidth="lg" sx={{ mb: 0 }}>
      <Stack>
        <Text.H1>Successor Instructions</Text.H1>
        <Text.AltBody>
          Currently, you are the advisor of your GiveWise Giving Fund. In the event of your death or incapacity, there
          are two ways to divide the remaining assets in the Giving Fund: naming one or more successors or allocating
          funds to one or more charities. You may also choose a combination of these two options.
        </Text.AltBody>
        <CardBox title="Instructions for Submitting Succession Instructions:">
          <Stack>
            <BlockQuote>
              <ul style={{ lineHeight: 3 }}>
                <li>
                  <Text.Body>
                    Download the Succession Instruction form available{' '}
                    <Link
                      target="_blank"
                      href="https://www.givewise.ca/application/files/6316/5993/3508/Succession_Instructions_-_GiveWise.pdf"
                    >
                      here.
                    </Link>
                  </Text.Body>
                </li>
                <li>
                  <Text.Body>Complete pages 1 and 2 of the form</Text.Body>
                </li>
                <li>
                  <Text.Body>
                    If the number of Successors or Charities exceeds the space available, use additional space found on
                    page 3 and 4 of the Succession Instruction form.
                  </Text.Body>
                </li>
                <li>
                  <Text.Body>Ensure all combined allocation assignments do not exceed 100%</Text.Body>
                </li>
                <li>
                  <Text.Body>
                    Email the completed form to: <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
                  </Text.Body>
                </li>
              </ul>
            </BlockQuote>
            <Text.Body>
              For more information or if you have any questions, please email{' '}
              <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> and we will be happy to help.
            </Text.Body>
          </Stack>
        </CardBox>
      </Stack>
    </Container>
  )
}
