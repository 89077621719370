import { CORPORATION_USER_TYPE, PAYMENT_TYPE, PAYMENT_TYPE_DISPLAY } from '../constants'
import { bankDetailsJsonToString, cardDetailsJsonToString } from './index'

export function join(array = [], joiner = ' ') {
  return array.filter(Boolean).join(joiner)
}

export const camelToTitleCase = (string) =>
  string
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (str) => str.toUpperCase())

export const titleCase = (string) => {
  if (!string) return null
  const lowerCaseString = string.toLowerCase()
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

/* duplicated in api/src/pdf/tax-receipt-pdf.service.ts */
export function formatContributionDescription(contribution) {
  const paymentMethod = contribution?.paymentMethod
  const details = paymentMethod?.details
  const paymentType = contribution?.paymentType ?? paymentMethod?.paymentType

  let paymentDetails = ''
  switch (paymentType) {
    case PAYMENT_TYPE.card:
      paymentDetails = details ? cardDetailsJsonToString(details) : ''
      break

    case PAYMENT_TYPE.eft:
    case PAYMENT_TYPE.bankTransfer:
      paymentDetails = details ? bankDetailsJsonToString(details) : ''
      break

    default:
  }

  const paymentTypeDisplay = PAYMENT_TYPE_DISPLAY[paymentType] || paymentType

  return join([paymentTypeDisplay, paymentDetails, contribution.description], ': ')
}

export const formatRecurrentGrantRequestDescription = (recurrentGrantRequest) => {
  const { grantNote, charity: { accountName } = {} } = recurrentGrantRequest
  return join([accountName, grantNote], ': ')
}

export const formatDafTransferDescription = (dafTransfer, { type }) => {
  const { initiatingUser, receivingUser, sentTo } = dafTransfer
  const prefix = type === 'initiated' ? 'To' : 'From'
  const name = type === 'initiated' ? `${receivingUser?.name ?? sentTo}` : `${initiatingUser?.name ?? 'Unknown'}`
  return join([prefix, name], ' ')
}

export const userName = (userProfile, appendString = '') => {
  if (!userProfile) return null
  const { userType, corporateName, firstName, middleInitials, lastName } = userProfile
  const nameArray = userType === CORPORATION_USER_TYPE ? [corporateName] : [firstName, middleInitials, lastName]
  return join([...nameArray, appendString], ' ')
}

// Formats the description only if it's for a grant request
export const grantRequestValueFormatter = (descriptionValue, isGrantRequest) => {
  if (isGrantRequest) {
    const [charity, description, processingNote] = descriptionValue
    const formattedDescription = join([charity?.accountName, description], ': ')
    return join([formattedDescription, processingNote], ', ')
  }

  return descriptionValue
}
