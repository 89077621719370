import { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { CircularProgress, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material'

import { queries } from '../../graphql'
import { Amount, Box, Button, Collapse, Container, FundSummary, IconButton, Icons, Link, Text, Tooltip } from '..'
import { CircularProgressWithLabel } from './circular-progress-with-label'

export function Header() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const isSmallerView = useMediaQuery(theme.breakpoints.down(1720))
  const shouldCollapseHeader = useMediaQuery(theme.breakpoints.down('lg'))
  const defaultExpanded = /^\/funds\/\d+$/.test(pathname)
  const charityId = useParams().id
  const { matches: isWideDisplay } = window.matchMedia('(min-width: 600px)')
  const [isOpenByUser, setIsOpenByUser] = useState(null)
  const isOpen = useMemo(
    () => isOpenByUser ?? (defaultExpanded && isWideDisplay),
    [isOpenByUser, defaultExpanded, isWideDisplay]
  )

  const { data: { getCharityBankAccounts } = {}, loading: loadingCharityBankAccounts } = useQuery(
    queries.charities.getCharityBankAccounts,
    {
      variables: { charityId },
    }
  )

  const [charityPortalProgress, setCharityPortalProgress] = useState(0)

  const {
    data: { charity } = {},
    charityLoading,
    refetch: refetchCharity,
  } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId: useParams().id },
    onCompleted: (data) => {
      setCharityPortalProgress(data.charity.charityPortalProgress)
    },
  })

  // useEffect(() => {
  //   console.log(`charity: ${JSON.stringify(charity, null, 2)}`)
  //   if (charity) {
  //     setCharityPortalProgress(charity.charityPortalProgress);
  //   }
  // }, [charity])

  const { data: { getMyCharityDescriptionSocialMedia: mySocialMedia } = {}, loading: socialMediaLoading } = useQuery(
    queries.charities.getMyCharityDescriptionSocialMedia,
    {
      variables: { id: charityId },
    }
  )

  const addPaylinkButton = (
    <Button
      size="medium"
      color="warning"
      onClick={() => navigate(`/charities/${charityId}/direct-deposit`)}
      sx={{ borderRadius: '25px', textTransform: 'none', margin: '5px' }}
    >
      <Icons.WarningTriangle style={{ marginRight: '10px', fontSize: '1.5rem' }} />
      Set Up Direct Deposit
    </Button>
  )

  if (!charity) {
    return <LinearProgress />
  }

  return (
    <Box component="header" sx={{ backgroundColor: 'var(--dark-blue)', m: 0, py: 3 }}>
      <Container sx={{ mb: 0, minWidth: '80%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'noWrap',
            justifyContent: 'space-between',
            flexDirection: shouldCollapseHeader ? 'column' : 'row',
            color: 'var(--white)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <Text.Caption sx={{ fontWeight: 'bold', color: '#61bee8' }}>CHARITY</Text.Caption>
              <Text.Body sx={{ fontSize: '1.5rem', fontWeight: '500' }}>{charity ? charity.accountName : ''}</Text.Body>
              <Text.Caption sx={{ fontSize: '1rem' }}>#{charity ? charity.id : ''}</Text.Caption>

              {mySocialMedia && mySocialMedia.length > 0 && (
                <Box sx={{ marginTop: '20px', display: 'flex' }}>
                  {mySocialMedia.map((social) => (
                    <Box key={social.id} sx={{ margin: '10px' }}>
                      <Text.Caption>
                        <a
                          href={social.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'white' }}
                        >
                          {social.platform === 'Facebook' ? <Facebook /> : ''}
                          {social.platform === 'Instagram' ? <Instagram /> : ''}
                          {social.platform === 'Linkedin' ? <LinkedIn /> : ''}
                        </a>
                      </Text.Caption>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ padding: '10px', marginRight: '15px' }}>
              {!isSmallerView ? (
                <Box sx={{ padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                  {getCharityBankAccounts && getCharityBankAccounts.length === 0 ? addPaylinkButton : ''}
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{ textTransform: 'none', margin: '5px' }}
                    onClick={() => navigate(`/charities/${charityId}/confirm`)}
                  >
                    Edit details
                  </Button>
                  {/* <Button size="medium" variant="outlined" sx={{ textTransform: 'none', margin: '5px' }}>
                    View public profile
                  </Button> */}
                </Box>
              ) : (
                ''
              )}
              <Box sx={{ padding: '10px' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                  <Balance Icon={Icons.Account} amount={charity.pendingGiftsTotal} tooltip="Pending Gifts" />
                  <Balance
                    Icon={Icons.Wallet}
                    amount={charity.lastDisbursementValue}
                    tooltip="Last Disbursement Value"
                  />
                  <Balance
                    Icon={Icons.Investments}
                    amount={charity.monthlyRecurringGiftsTotal}
                    tooltip="Monthly Recurring Gifts Total"
                  />
                </Box>
              </Box>
            </Box>
            {!shouldCollapseHeader ? (
              <Box
                sx={{
                  padding: '15px',
                  backgroundColor: '#232a4d',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '10px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  transition: 'transform 0.15s ease-in-out',
                  '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
                }}
                onClick={() => navigate(`/charities/${charityId}/direct-deposit`)}
              >
                <CircularProgressWithLabel
                  value={charity.charityPortalProgress}
                  color={charity.charityPortalProgress === 100 ? 'success' : 'warning'}
                  thickness={2.0}
                  size="100px"
                />
                <Text.Caption sx={{ fontSize: '1rem', marginTop: '10px', fontWeight: '450' }} />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

function Balance({ Icon, amount, tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <Text.Body sx={{ fontSize: '1.5rem', color: 'var(--white)', display: 'flex', ml: 4, alignItems: 'center' }}>
        <Icon style={{ marginRight: '.5rem', fontSize: '1rem' }} />
        <Amount value={amount} />
      </Text.Body>
    </Tooltip>
  )
}

function ContactCallOut() {
  return (
    <Text.AltBody sx={{ color: 'var(--white)', textAlign: 'center', m: '16px auto 0' }}>
      We are always striving to improve. If you see anything out of place or have a question, please don’t hesitate to
      contact us:{' '}
      <Link
        sx={{
          color: 'var(--white)',
          textDecoration: 'none',
          '&:hover': { color: 'var(--white)', textDecoration: 'underline' },
        }}
        href="mailto:support@givewise.ca"
      >
        <b>support@givewise.ca</b>
      </Link>
    </Text.AltBody>
  )
}
