import { forwardRef, useImperativeHandle } from 'react'

import { BlockQuote, Stack, Text } from '../../../components'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef((_, ref) => {
  useImperativeHandle(ref, () => ({}))

  return (
    <Stack spacing={2}>
      <Text.Body>Prepare a cheque with the information below to be mailed to GiveWise Foundation Canada.</Text.Body>
      <BlockQuote>
        <Text.Bold>Payee</Text.Bold>
        <Text.Body>GiveWise Foundation Canada</Text.Body>
        <Text.Bold>Memo (Must be included)</Text.Bold>
        <Text.Body>Giving Fund #000</Text.Body>
        <Text.Bold>Mailing Address</Text.Bold>
        <Text.Body>
          3251 Boxwood Court <br />
          Abbotsford BC <br />
          V3G 0A6
        </Text.Body>
      </BlockQuote>

      <Text.Body>
        Your contribution will post to your account as soon as your cheque clears; however, your tax receipt will
        reflect the post mark date on the envelope.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ fundId, giftDetails }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await new Promise((resolve) => {
    resolve({
      createContributionRecord: {
        fundId,
        amount: amounts.total,
        paymentType: 2,
        description: 'Cheque',
        taxReceipted: true,
        state: 'cleared',
        netAmount: 100,
        fee: 5,
      },
    })
  }).then(({ createContributionRecord }) => createContributionRecord)

  return [contribution]
}

export function SuccessUI({ grantTotal }) {
  return (
    <>
      <p>
        To complete the contribution, you will now need to mail a cheque of {toAmountString(grantTotal)} with the
        following details:
      </p>
      <p>
        <strong>Payee:</strong>
        <br />
        GiveWise Foundation Canada
      </p>
      <p>
        <strong>Memo (Must be included):</strong>
        <br />
        Giving Fund #000
      </p>
      <p>
        <strong>Mail Cheque To:</strong>
        <br />
        3251 Boxwood Court
        <br />
        Abbotsford, BC
        <br />
        V3G 0A6
      </p>
    </>
  )
}
