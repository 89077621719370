import { join, titleCase } from './strings'

/* duplicated in api/src/utils/strings.ts */
export function cardDetailsJsonToString(details) {
  if (!details) return ''
  const jsonDetails = typeof details === 'string' ? JSON.parse(details) : details
  if (typeof jsonDetails !== 'object') return ''
  const { card } = jsonDetails
  if (!card) return ''
  const { brand, last4, exp_month: expMonth, exp_year: expYear } = card
  const exp = expMonth && expYear ? `${expMonth}/${expYear}` : ''
  const line1 = titleCase(brand)
  const line2 = last4 ? `****${last4}` : ''
  const line3 = exp
  return join([line1, line2, line3], ' ')
}

/* duplicated in api/src/utils/strings.ts */
export function bankDetailsJsonToString(details) {
  if (!details || !details.Institution || !details.AccountNumber) return ''
  const { Institution, AccountNumber } = details
  return `${titleCase(Institution)} **** ${AccountNumber.slice(-4)}`
}
