import { Button as MuiButton, IconButton as MuiIconButton } from '@mui/material'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import palette from '../../palette.json'

export const Button = forwardRef(({ unstyled, sx, children, ...props }, ref) => (
  <MuiButton
    ref={ref}
    variant={unstyled ? 'text' : 'contained'}
    sx={{
      ...sx,
      ...(unstyled && {
        color: palette.black,
        backgroundColor: 'transparent',
        px: 0,
        textTransform: 'initial',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
      }),
    }}
    component={props.to ? Link : undefined}
    {...props}
  >
    {children}
  </MuiButton>
))

export const IconButton = forwardRef(({ unstyled, children, ...props }, ref) => (
  <MuiIconButton ref={ref} component={props.to ? Link : undefined} {...props}>
    {children}
  </MuiIconButton>
))
