import { useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'
import { Confirm } from 'react-admin'
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, CardBox, CardActions, CardContent, Icons, Stack, Text, useAlert } from '../../components'
import { queries, mutations } from '../../graphql'

export function ManageCreditCards(props) {
  const { manageCreditCardsRef } = props
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [cannotDeleteOpen, setCannotDeleteOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const card = useQuery(queries.paymentMethods.myCreditCards)
  const myFund = useQuery(queries.funds.myFund)

  const [mutate, { isLoading }] = useMutation(mutations.paymentMethods.deactivateCreditCard, {
    onCompleted: () => {
      setAlert({ message: `Removed payment method.`, severity: 'success' })
      setDeletingId(null)
    },
    onError: (newErrors) => {
      setAlert({ message: `An error occured.`, severity: 'error' })
      setDeletingId(null)
    },
    refetchQueries: [{ query: queries.paymentMethods.myCreditCards }],
  })

  const savedCards = card.data?.me?.creditCards.filter((cc) => cc.status === 'active')
  const hasSavedCards = savedCards && savedCards.length

  const handleDelete = () => {
    mutate({
      variables: {
        deactivateCreditCardInput: {
          ids: [deletingId],
        },
      },
    })

    setOpen(false)
  }

  const handleConfirm = (id) => {
    setOpen(true)
    setDeletingId(id)
  }
  const handleDialogClose = () => {
    setOpen(false)
    setCannotDeleteOpen(false)
    setDeletingId(null)
  }

  return (
    <Stack ref={manageCreditCardsRef}>
      <CardBox>
        <Stack direction="row">
          <Text.H4>Credit Cards</Text.H4>
        </Stack>
        <br />
        <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
          {hasSavedCards ? (
            savedCards.map((cc) => {
              const srcObj = cc.details.card ? cc.details.card : cc.details.sources.data[0]

              return (
                <Card
                  key={cc.id}
                  variant="outlined"
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                    margin: 10,
                    backgroundColor: 'lightgrey',
                    borderColor: 'lightgrey',
                  }}
                >
                  <CardContent>
                    <Text.H5>{`${srcObj.brand}`}</Text.H5>
                    <br />
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Text.Body>{`•••• •••• •••• ${srcObj.last4}`}</Text.Body>
                      <Text.Body>{`exp: ${srcObj.exp_month}/${srcObj.exp_year}`}</Text.Body>
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="error"
                      variant="text"
                      onClick={cc.canDelete ? () => handleConfirm(cc.id) : () => setCannotDeleteOpen(true)}
                    >
                      <Icons.DeleteForever fontSize="small" />
                      Remove
                    </Button>
                  </CardActions>
                </Card>
              )
            })
          ) : (
            <Text.H5>
              No saved credit cards.{' '}
              <Link to={`/funds/${myFund.data?.me?.fund.id}/contribute/credit-card`}>Add Funds</Link> to save a credit
              card.
            </Text.H5>
          )}
        </Stack>
        <br />
        <Dialog
          open={cannotDeleteOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This credit card cannot be removed at the moment because it is being used for currently scheduled or
              recurring contributions.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
            <Button onClick={() => navigate(`/funds/${myFund.data?.me?.fund.id}/activity/giving-wallet`)} autoFocus>
              Go to my activity
            </Button>
          </DialogActions>
        </Dialog>
        <Confirm
          isOpen={open}
          loading={isLoading}
          title="Remove Credit Card"
          content="Are you sure you want to remove this credit card from your profile?"
          onConfirm={handleDelete}
          onClose={handleDialogClose}
          cancel="Cancel"
          confirm="Yes, remove it"
          confirmColor="secondary"
        />
        <Alert {...alertProps} />
      </CardBox>
    </Stack>
  )
}
