import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

import { Button, Fields, Row, Stack, Text, useAlert } from '../../components'
import { mutations } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

export function ChangePasswordForm({ close }) {
  const [passwordUpdated, setPasswordUpdated] = useState(false)
  const { register, handleSubmit, formState, setError, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [updatePassword] = useMutation(mutations.user.updatePassword, {
    onCompleted: () => {
      setPasswordUpdated(true)
      setAlert({ message: 'Password successfully updated', severity: 'success' })
    },
    onError: (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      setAlert({ message: 'Password update failed', severity: 'error' })
    },
  })

  const onSubmit = async (data) => {
    updatePassword({
      variables: { data },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Text.H6 data-testid="dialogTitle">Change Password</Text.H6>
        <Stack spacing={4} mx={2}>
          <Fields.Password register={register} errors={errors} label="Old Password *" name="oldPassword" />
          <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
        </Stack>
        <Row justifyContent="flex-end" alignItems="center" m={4}>
          {passwordUpdated ? (
            <Button color="info" onClick={close}>
              Done
            </Button>
          ) : (
            <>
              <Button color="info" onClick={close} data-testid="cancelButton">
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit" variant="contained" data-testid="changeButton">
                Change Password
              </Button>
            </>
          )}
        </Row>
        <Alert {...alertProps} />
      </Stack>
    </form>
  )
}
