import { Box as MuiBox } from '@mui/material'

import { Stack } from '..'
import palette from '../../palette.json'

export function BlockQuote({ children, sx, ...props }) {
  return (
    <MuiBox
      sx={{
        borderLeft: `4px solid ${palette.grey}`,
        padding: 3,
        backgroundColor: 'whiteSmoke',
        width: '100%',
        boxSizing: 'border-box',
        ...sx,
      }}
      {...props}
    >
      <Stack spacing={1}>{children}</Stack>
    </MuiBox>
  )
}
