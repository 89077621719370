import { forwardRef, useImperativeHandle, useRef } from 'react'

import { Stack } from '../../../components'
import * as CreditCard from '../../../components/payment/credit-card'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ register }, ref) => {
  const cardApi = useRef()

  useImperativeHandle(ref, () => ({
    getPaymentMethodId: async (values) => {
      if (cardApi.current.isNewCard) {
        try {
          return await cardApi.current.postToStripeAndSavePaymentMethod({ multiUse: values.multiUse })
        } catch (e) {
          throw new Error(`This card cannot be used to finalize the transaction: ${e.message || e.type}`)
        }
      }

      return values.paymentMethodId
    },
  }))

  return (
    <Stack spacing={2}>
      <CreditCard.Select ref={cardApi} register={register} />
      <CreditCard.FeesNotice />
    </Stack>
  )
})

export async function getPaymentValues({ paymentMethodApi, values }) {
  const paymentMethodId = await paymentMethodApi.current.getPaymentMethodId(values)

  return {
    ...values,
    paymentMethodId,
  }
}

export async function createContributions({ client, fundId, giftDetails, payment }) {
  const { isRecurring, recurringInterval, processingDate } = giftDetails
  const { paymentMethodId } = payment

  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createContribution,
      variables: {
        data: {
          paymentMethodId,
          fundId,
          amount: amounts.total,
          isRecurring,
          recurringInterval,
          processingDate,
        },
      },
      refetchQueries: [
        { query: queries.funds.myFund, variables: { fundId } },
        { query: queries.funds.myFundActivity, variables: { fundId } },
        { query: queries.funds.myRecurring, variables: { fundId } },
        { query: queries.paymentMethods.myCreditCards },
      ],
      awaitRefetchQueries: true,
    })
    .then(({ data }) => data.contribute)

  return [contribution]
}
