import { useGetOne } from '..'
import { BlockContent, Stack, Text } from '../../../components'

export function Detail({ title, children }) {
  return (
    <Stack spacing={1} sx={{ width: { xs: '100%', sm: '50%', md: '100%', lg: '50%' }, mb: 2 }}>
      <Text.H7>
        <strong>{title}</strong>
      </Text.H7>
      <BlockContent sx={{ flexGrow: 1 }}>{children}</BlockContent>
    </Stack>
  )
}

function PaymentMethodInternal({ id }) {
  const { data: paymentMethod } = useGetOne('PaymentMethod', { id })
  if (!paymentMethod?.details) return null
  const details =
    typeof paymentMethod.details === 'string' ? JSON.parse(paymentMethod.details) : paymentMethod.details ?? {}
  return (
    <Detail title="Card">
      <Stack spacing={0}>
        <Text.AltBody>{details?.paymentVendor}</Text.AltBody>
        <Text.AltBody>************{details?.last4}</Text.AltBody>
        <Text.AltBody>
          Exp {details?.expMonth}/{details?.expYear}
        </Text.AltBody>
      </Stack>
    </Detail>
  )
}
export function PaymentMethod({ id }) {
  if (!id) return null
  return <PaymentMethodInternal id={id} />
}
