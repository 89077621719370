import { useState } from 'react'

import { BlockQuote, IconButton, Icons, Link, Stack, Text } from '../../components'

export function TroubleMessage() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <BlockQuote>
      <Stack direction="row" alignItems="center">
        <Text.Bold>Having trouble finding a charity?</Text.Bold>
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </IconButton>
      </Stack>
      {isOpen && (
        <Text.Body>
          Search for any Registered Canadian Charity using the charity name or registration number. If the intended
          charity doesn&apos;t appear in your search:
          <ul>
            <li>Ensure the Business Number format is: #########RR### (Example: 701032526RR001)</li>
            <li>Contact the charity and ask about the Legal or Registered name</li>
            <li>
              Check to see if the organization is a non-profit or a qualified donee by searching{' '}
              <Link
                target="_blank"
                href="https://www.canada.ca/en/revenue-agency/services/charities-giving/list-charities/list-charities-other-qualified-donees.html"
              >
                here
              </Link>
            </li>
            <li>
              Still can&apos;t find the charity you&apos;re looking for? Email{' '}
              <Link href="mailto:grants@givewise.ca">grants@givewise.ca</Link> and we can help
            </li>
          </ul>
        </Text.Body>
      )}
    </BlockQuote>
  )
}
