import { useApolloClient } from '@apollo/client'
import { fromUnixTime, isFuture } from 'date-fns'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { create as createStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const TOKEN_NAME = 'givewise_auth_state'
export const IMPERSONATOR_TOKEN_NAME = 'givewise_impersonator_token'

const useTokenStore = createStore(
  persist(
    (set) => ({
      rawToken: undefined,
      payload: undefined,
      setToken: (token) => {
        let payload
        try {
          payload = token ? jwtDecode(token) : null
        } catch (error) {
          // eslint-disable-next-line no-console -- log intentionally silent error
          console.error('jwtDecode failed', error)
        }

        return set({ rawToken: token, payload })
      },
      impersonatorToken: undefined,
      setImpersonator: (token) => set({ impersonatorToken: token }),
    }),
    { name: TOKEN_NAME, storage: createJSONStorage(() => localStorage) }
  )
)

{
  // Pull the token from the url (used by the mobile app to auto-log-in in webviews)
  const initialSearchParams = new URLSearchParams(window.location.search)
  const initialToken = initialSearchParams.get('token')
  if (initialToken) {
    useTokenStore.getState().setToken(initialToken)
  }
}

const useToken = () => {
  const token = useTokenStore((state) => state.rawToken)
  return token
}

export function getAuthToken() {
  return useTokenStore.getState().rawToken
}

function useImpersonation({ login, currentToken }) {
  const impersonatorToken = useTokenStore((state) => state.impersonatorToken)
  const setImpersonator = useTokenStore((state) => state.setImpersonator)

  async function impersonate(newToken) {
    // Store old token so we can switch back on logout
    setImpersonator(currentToken)
    return login(newToken)
  }
  async function switchBack() {
    if (!impersonatorToken) return
    await login(impersonatorToken)
    setImpersonator(null)
  }
  return [{ isImpersonating: !!impersonatorToken }, { impersonate, switchBack }]
}

export const useAuth = () => {
  const token = useToken()
  const setToken = useTokenStore((state) => state.setToken)
  let auth = null

  if (token) {
    try {
      auth = jwtDecode(token)
    } catch (error) {
      console.error('failed jwtDecode', error)
    }
  }

  const isLoggedIn = auth?.exp && isFuture(fromUnixTime(auth?.exp))
  const isAdmin = auth?.admin

  const client = useApolloClient()
  const navigate = useNavigate()
  async function login(newToken) {
    await client.clearStore()
    setToken(newToken)
  }
  const [{ isImpersonating }, { impersonate, switchBack }] = useImpersonation({ login, currentToken: token })
  async function logout({ skipNavigation = false } = {}) {
    await client.clearStore()
    setToken(null)
    if (isImpersonating) {
      await switchBack()
    }

    if (isImpersonating) {
      navigate('/management/User')
    } else if (!skipNavigation) {
      navigate('/login')
    }
  }

  return [
    { isLoggedIn, isAdmin },
    { login, logout, impersonate },
  ]
}
