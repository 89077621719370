import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Fields, Row, Stack, Text, useAlert } from '../../components'
import { validations } from '../../components/form'
import { mutations } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

export function AddEmailForm({ close }) {
  const [addEmailRequestSent, setAddEmailRequestSent] = useState(false)
  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [addNewEmail] = useMutation(mutations.user.addNewEmail, {
    onCompleted: () => {
      setAddEmailRequestSent(true)
      setAlert({ message: 'Add email request submitted successfully', severity: 'success' })
    },
    onError: (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      setAlert({ message: `Request failed: ${newErrors.message}`, severity: 'error' })
    },
  })

  const onSubmit = async (data) => {
    addNewEmail({
      variables: { data },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {addEmailRequestSent ? (
        <Stack>
          <Text.H6>Add Email Request Submitted Successfully!</Text.H6>
          <Text.Body data-testid="dialogContent">
            We have sent an email to the new email address you submitted. Please click the link there to confirm the
            email and complete the process.
          </Text.Body>
          <Row justifyContent="flex-end" alignItems="center" m={4}>
            <Button color="info" onClick={close}>
              OK
            </Button>
          </Row>
        </Stack>
      ) : (
        <Stack>
          <Text.H6 data-testid="dialogTitle">Add a New Email Address</Text.H6>
          <Stack mx={2}>
            <Text.Body data-testid="dialogContent">
              Please note that this will not change the email you use for login. That can be done from the Change
              Primary Email form once the process for adding the new email has been completed.
            </Text.Body>
            <Fields.Text
              inputProps={{ 'data-testid': 'emailToAdd' }}
              type="text"
              label="New Email *"
              error={!!errors.email}
              helperText={errors?.email?.message}
              {...register('emailToAdd', { ...validations.required, ...validations.email })}
            />
            <Fields.Password register={register} errors={errors} />
          </Stack>
          <Row justifyContent="flex-end" alignItems="center" m={4}>
            <Button color="info" onClick={close}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit">
              Submit
            </Button>
          </Row>
          <Alert {...alertProps} />
        </Stack>
      )}
    </form>
  )
}
