import { Box } from '@mui/material'
import { useListContext, useRecordContext, useResourceContext } from 'react-admin'

import { ReferenceManyField } from '..'
import { Note } from './note'
import { NewNote } from './new-note'

function NotesIterator({ parent }) {
  const { data, isLoading } = useListContext()
  if (isLoading) return null
  return (
    <>
      <NewNote parent={parent} />
      <Box mt="0.5em">
        {data.map((note, index) => (
          <Note note={note} isLast={index === data.length - 1} key={note?.id} parent={parent} />
        ))}
      </Box>
    </>
  )
}

export function NotesField() {
  const resource = useResourceContext()
  const record = useRecordContext()
  const parent = { type: resource, id: record?.id }
  if (!record || !record.id) return null
  return (
    <Box sx={{ width: '100%' }}>
      <ReferenceManyField
        reference="AdminNote"
        target="objectId"
        filter={{ objectType: parent.type }}
        sort={{ field: 'updatedAt', order: 'DESC' }}
      >
        <NotesIterator parent={parent} />
      </ReferenceManyField>
    </Box>
  )
}
