import { useApolloClient, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { Button, Fields, Link, Row, Stack, useAlert } from '..'
import { mutations, queries } from '../../graphql'
import { useAuth } from '../../hooks'
import { validations } from '../form'

export function LoginForm({ prefillEmail, onCompleted, close }) {
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [, { login }] = useAuth()
  const { register, handleSubmit, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { email: prefillEmail },
  })
  const { errors = {} } = formState
  const client = useApolloClient()
  const [loginMutation, { loading }] = useMutation(mutations.auth.login, {
    onCompleted: async ({ login: { access_token: token } = {} }) => {
      await login(token)
      await client.refetchQueries({ include: [{ query: queries.user.me }] })

      onCompleted?.()
      close?.()
    },
    onError: (e) => {
      if (e.networkError?.result?.message === 'LOGIN_FAILED_USER_IS_GUEST') {
        setAlert({
          message: `This email may have been used for Guest Giving and is not an Active Fund. Click “Sign\u00A0Up” to register account or email support@givewise.ca for assistance.`,
          severity: 'info',
          timeout: 16000,
        })
      } else if (e.networkError?.result?.message === 'LOGIN_FAILED_USER_UNCONFIRMED') {
        setAlert({
          message:
            'Your account has not yet been confirmed. Please check your email for the link to confirm your account, or contact support@givewise.ca for assistance.',
          severity: 'error',
        })
      } else {
        // eslint-disable-next-line no-console -- intentional printing of error
        console.error(`Unexpected error treated as username/password error:\n${e.message}`, e)
        setAlert({ message: 'Username or password is incorrect.', severity: 'error' })
      }
    },
  })

  const onSubmit = ({ email, password }) => {
    loginMutation({ variables: { email, password } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4} alignItems="flex-end">
        <Fields.Text
          data-testid="email"
          type="email"
          autoComplete="username"
          label="Email *"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register('email', { ...validations.required, ...validations.email })}
        />
        <Fields.Password autoComplete="current-password" register={register} errors={errors} />
        <Row justifyContent="flex-end" alignItems="center" spacing={4}>
          <Link to="/forgot-password" data-testid="forgPasswordLink">
            Forgot password?
          </Link>
          <Button type="submit" disabled={loading} data-testid="signInButton">
            Sign In
          </Button>
        </Row>
        <Alert {...alertProps} />
      </Stack>
    </form>
  )
}
