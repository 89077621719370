import { Button, Confirm, useNotify, useRefresh } from 'react-admin'
import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { mutations } from '../../../graphql'

export function DeactivatePaylink({ paymentMethodId, activeStatus }) {
  const refresh = useRefresh()
  const notify = useNotify()
  const [open, setOpen] = useState(false)

  const [deactivateCharityPaymentMethod, { isLoading }] = useMutation(
    mutations.paymentMethods.deactivateCharityPaymentMethod,
    {
      onCompleted: () => {
        refresh()
        notify('This payment method has been deactivated.')
      },
      onError: (_error) => {
        notify('Error: payment method not deactivated.', { type: 'warning' })
      },
    }
  )

  const handleDialogOpen = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = async () => {
    deactivateCharityPaymentMethod({ variables: { id: paymentMethodId } })
    setOpen(false)
  }

  return (
    <>
      <Button label="Deactivate" disabled={isLoading || activeStatus !== 'active'} onClick={handleDialogOpen} />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Deactivate Paylink"
        content="This operation cannot be undone. Are you sure you want to deactivate the payment method?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
