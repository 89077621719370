import { createContext } from 'react'

// This component allows the local storage to be used while also notifying
// other components in the same context of changes.
//
// CAVEATS
// This will currently not send a notification if the local storage was modified
// manually without using this context.
//
// Subscribtion must be done using an effect and unsubsribe during the effectCleanup
// otherwise there is the possibility of a memory leak.
/**
  const {addSubscriber, getValue, removeSubscriber} = useContext(LocalStorageContext)
  const [val, setVal] = useState(getValue(SHARE_FUND_CODE))

  useEffect(()=>{
    const listener = ()=>setVal(getValue(SHARE_FUND_CODE))
    addSubscriber(SHARE_FUND_CODE,  listener)
    return ()=>{
      removeSubscriber(SHARE_FUND_CODE, listener)
    };
  })
 */

// Keys used in local storage
export const SHARE_FUND_CODE = 'shareFundCode'

const subscribers = {}

function notify(key) {
  subscribers[key].forEach((element) => {
    element()
  })
}

function getValue(key) {
  return localStorage.getItem(key)
}
function setValue(key, value) {
  localStorage.setItem(key, value)
  notify(key)
}
function clearValue(key) {
  localStorage.removeItem(key)
  notify(key)
}

function addSubscriber(key, func) {
  if (subscribers[key] === undefined) {
    subscribers[key] = [func]
  } else {
    subscribers[key] = [func, ...subscribers[key]]
  }
}
function removeSubscriber(key, func) {
  const funcs = subscribers[key]
  if (funcs !== undefined) {
    funcs.pop(func)
    subscribers[key] = [...funcs]
  }
}

const contextValues = {
  setValue,
  clearValue,
  getValue,
  addSubscriber,
  removeSubscriber,
}
export const LocalStorageContext = createContext(contextValues)

export function LocalStorage({ children }) {
  return <LocalStorageContext.Provider value={contextValues}>{children}</LocalStorageContext.Provider>
}
