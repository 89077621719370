import { Stack as MuiStack } from '@mui/material'

export function Row({ children, sx, ...props }) {
  return (
    <MuiStack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 2 }}
      sx={{
        alignItems: { xs: 'flex-start', sm: 'center' },
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiStack>
  )
}
