import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useQuery } from 'react-query'

import {
  Button,
  Checkbox,
  Fields,
  FormControl,
  IconButton,
  Icons,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Row,
  Select,
  Stack,
  Text,
  useAlert,
  validations,
} from '..'
import { PROVINCES_LIST } from '../../constants'
import { fromAmountField } from '../../utils'
import { openApiFileWithData } from '../../utils/open-api-file'

/**
 * @typedef SecurityValues
 * @property {string} securitiesDescription - Securities Description
 * @property {string} fundSymbolCUSIP - Fund Symbol/CUSIP
 * @property {string|number} quantity - Quantity
 * @property {string|number} amount - Approx Value
 */

/** @type {SecurityValues[]} */
export const defaultSecurities = [
  {
    securitiesDescription: '',
    fundSymbolCUSIP: '',
    quantity: 0,
    amount: 0,
  },
]

/**
 * Utility for calculating the total approx value of a list of securities
 *
 * @param {SecurityValues[]} securities - The list of securities
 * @returns {number} The total approx value
 */
export function calculateSumForSecurities(securities) {
  return securities.reduce((total, sec) => {
    const quantity = fromAmountField(sec.quantity || '0')
    const amount = fromAmountField(sec.amount || '0')

    return total + quantity * amount
  }, 0)
}

export function DeliveringCustodianInformation({ register, control, formState }) {
  const { isSubmitting, errors = {} } = formState

  return (
    <Stack sx={{ mb: 2 }}>
      <Text.H6 sx={{ display: 'flex' }}>Delivering Custodian Information</Text.H6>

      <Fields.Text
        inputProps={{ 'data-testid': 'custodianName' }}
        type="text"
        label="Name of Securities Firm *"
        error={!!errors?.custodianName}
        helperText={errors?.custodianName?.message}
        {...register('custodianName', {
          required: 'This field is required',
          validate: {
            noSpepcialCharacters: (value) => /^[a-zA-Z0-9\s]*$/.test(value) || 'No special characters allowed',
          },
        })}
      />
      <Row>
        <Fields.Text
          inputProps={{ 'data-testid': 'advisorEmail' }}
          type="text"
          label="Advisor's Email *"
          error={!!errors?.advisorEmail}
          helperText={errors?.advisorEmail?.message}
          {...register('advisorEmail', { ...validations.required, ...validations.email })}
        />
        <Fields.Text
          inputProps={{ 'data-testid': 'advisorPhone' }}
          type="text"
          label="Advisor's Phone Number *"
          error={!!errors.advisorPhone}
          helperText={errors?.advisorPhone?.message}
          {...register('advisorPhone', { ...validations.required, ...validations.phoneNumber })}
        />
      </Row>
      <Fields.Text
        inputProps={{ 'data-testid': 'advisorName' }}
        type="text"
        label="Contact Name (Advisor) *"
        error={!!errors.advisorName}
        helperText={errors?.advisorName?.message}
        {...register('advisorName', { ...validations.required })}
      />

      <Fields.Text
        inputProps={{ 'data-testid': 'custodianAddress' }}
        type="text"
        label="Securities Dealer/ Custodian Address *"
        error={!!errors.address?.lineOne}
        helperText={errors?.address?.lineOne?.message}
        {...register('address.lineOne', { ...validations.required })}
      />
      <Row>
        <Fields.Text
          inputProps={{ 'data-testid': 'city' }}
          type="text"
          label="City *"
          error={!!errors.address?.city}
          helperText={errors?.address?.city?.message}
          {...register('address.city', { ...validations.required })}
        />
        <Controller
          control={control}
          name="address.province"
          rules={{
            ...validations.required,
          }}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="provinceLabel">Province *</InputLabel>
              <Select
                {...field}
                inputProps={{ 'data-testid': 'province' }}
                label="Province *"
                labelId="provinceLabel"
                aria-label="Province *"
                error={!!errors.address?.province}
                helpertext={errors?.address?.province?.message}
              >
                {PROVINCES_LIST.map((p) => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Fields.Text
          inputProps={{ 'data-testid': 'postalCode' }}
          type="text"
          label="Postal Code *"
          error={!!errors.address?.postalCode}
          helperText={errors?.address?.postalCode?.message}
          {...register('address.postalCode', { ...validations.required, ...validations.postalCode })}
        />
      </Row>
      <Fields.Text
        inputProps={{ 'data-testid': 'accountNumber' }}
        type="text"
        label="Account Number *"
        error={!!errors.accountNumber}
        helperText={errors?.accountNumber?.message}
        {...register('accountNumber', { ...validations.required })}
      />
    </Stack>
  )
}

export function SecuritiesToTransferInKind({ required = false, register, control, formState }) {
  const { isSubmitting, errors = {} } = formState
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'securities',
  })

  return (
    <Stack sx={{ mb: 2 }}>
      <Text.H6 sx={{ display: 'flex' }}>Securities To Transfer In Kind{required && ' *'}</Text.H6>

      {fields.map((field, index) => (
        <Row key={field.id}>
          <Fields.Text
            inputProps={{ 'data-testid': `securities[${index}].securitiesDescription` }}
            type="text"
            multiline
            sx={{ minWidth: '300px' }}
            label="Securities Description *"
            error={!!errors?.securities?.[index]?.securitiesDescription}
            helperText={errors?.securities?.[index]?.securitiesDescription?.message}
            {...register(`securities[${index}].securitiesDescription`, { ...validations.required })}
          />
          <Fields.Text
            inputProps={{ 'data-testid': `securities[${index}].fundSymbolCUSIP` }}
            type="text"
            label="Fund Symbol/CUSIP *"
            error={!!errors?.securities?.[index]?.fundSymbolCUSIP}
            helperText={errors?.securities?.[index]?.fundSymbolCUSIP?.message}
            {...register(`securities[${index}].fundSymbolCUSIP`, { ...validations.required })}
          />
          <Controller
            control={control}
            name={`securities[${index}].quantity`}
            defaultValue={0}
            rules={{
              required: 'This field is required',
              validate: {
                positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
              },
            }}
            // eslint-disable-next-line no-shadow
            render={({ field: { ref, ...field } }) => (
              <Fields.Text
                {...field}
                inputRef={ref}
                label="Quantity *"
                error={!!errors?.securities?.[index]?.quantity}
                helperText={errors?.securities?.[index]?.quantity?.message}
                InputProps={{
                  inputComponent: Fields.Amount,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`securities[${index}].amount`}
            defaultValue={0}
            rules={{
              required: 'This field is required',
              validate: {
                positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
              },
            }}
            // eslint-disable-next-line no-shadow
            render={({ field: { ref, ...field } }) => (
              <Fields.Text
                {...field}
                inputRef={ref}
                label="Approx Value *"
                name={`securities[${index}].amount`}
                error={!!errors?.securities?.[index]?.amount}
                helperText={errors?.securities?.[index]?.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputComponent: Fields.Amount,
                }}
              />
            )}
          />
          {fields.length > 1 && (
            <IconButton onClick={() => remove(index)} size="small">
              <Icons.Trash fontSize="small" />
            </IconButton>
          )}
        </Row>
      ))}

      <Button
        type="button"
        disabled={isSubmitting}
        onClick={() => append({ securitiesDescription: '', fundSymbolCUSIP: '', quantity: 0, amount: 0 })}
      >
        <Icons.Plus style={{ marginRight: '10px' }} />
        Add Additional Security
      </Button>
    </Stack>
  )
}

export function Acknowledgement({ control }) {
  return (
    <Controller
      control={control}
      name="pageLanguageConfirmed"
      render={({ field: { value, onChange, ...field } }) => (
        <Row justifyContent="space-between" spacing={4} sx={{ marginTop: '2rem!important' }}>
          <Checkbox
            {...field}
            sx={{ marginTop: -1, marginRight: -1 }}
            checked={value}
            onChange={(e) => {
              onChange(e.target.checked)
            }}
          />
          <Text.Body>
            I acknowledge that GiveWise Foundation Canada (GWFC) cannot control when a security is received. To ensure
            receipting within a specific calendar year, please allow sufficient time, as GWFC is not responsible for
            delays. I understand that a cost recovery fee according to the fee schedule will be deducted from the
            donation proceeds.
          </Text.Body>
        </Row>
      )}
    />
  )
}

/**
 * Fetch Letter of Direction PDF contents
 */
export function fetchGeneratedLetterOfDirectionPdf({ me, securities, deliveringCustodianInfo, contributionIds }) {
  const { profile } = me ?? {}

  return openApiFileWithData(`/pdf/generate/letter-of-direction/${contributionIds?.[0]}`, {
    data: {
      donorProfile: {
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        middleInitials: profile?.middleInitials,
        phone: profile?.phone,
        email: me?.email,
        address: {
          lineOne: profile?.address?.lineOne,
          lineTwo: profile?.address?.lineTwo,
          city: profile?.address?.city,
          province: profile?.address?.province,
          postalCode: profile?.address?.postalCode,
          country: profile?.address?.country,
        },
      },
      accountNumber: deliveringCustodianInfo?.accountNumber,
      address: deliveringCustodianInfo?.address,
      advisorEmail: deliveringCustodianInfo?.advisorEmail,
      advisorName: deliveringCustodianInfo?.advisorName,
      advisorPhone: deliveringCustodianInfo?.advisorPhone,
      custodianName: deliveringCustodianInfo?.custodianName,
      securities,
    },
  })
}

export function GeneratedLetterOfDirection({ letterOfDirectionPdf }) {
  const [fetchedPdf, setFetchedPdf] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const [{ Alert, alertProps }, { setAlert }] = useAlert()

  const setAlertRef = useRef()
  useLayoutEffect(() => {
    setAlertRef.current = setAlert
  })
  useEffect(() => {
    // eslint-disable-next-line no-shadow -- useAlert does not implement proper memoization
    const setAlert = setAlertRef.current
    if (!letterOfDirectionPdf) return undefined

    const abortController = new AbortController()

    setFetchedPdf(null)
    setIsFetching(true)
    letterOfDirectionPdf
      .then((pdf) => {
        if (!abortController.signal.aborted) {
          setFetchedPdf(pdf)
        }
      })
      .catch((e) => {
        if (!abortController.signal.aborted) {
          setAlert({
            message: `Failed to create the Letter of Direction PDF: ${e.message ?? ''}`,
            error: e.message,
            severity: 'error',
          })
        }
      })
      .finally(() => {
        setIsFetching(false)
      })

    return () => {
      abortController.abort()
    }
  }, [letterOfDirectionPdf])

  return (
    <>
      {
        isFetching && <p>...generating prepopulated pdf letter...</p>
        // replace with animation later ... not necessary because generation is fast enough?
      }

      {fetchedPdf && (
        <Link
          href={`data:application/pdf;base64,${fetchedPdf}`}
          download="Letter_of_Direction.pdf"
          target="_blank"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Icons.FileOpen sx={{ marginRight: '.5rem' }} />
          Gift of Publicly Traded Securities - Letter of Direction (PDF)
        </Link>
      )}

      <Alert {...alertProps} />
    </>
  )
}
