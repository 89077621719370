// eslint-disable-next-line prefer-const, import/no-mutable-exports -- Allow commented code to override
export let showUnfinishedFeatures =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_SITE_URL === 'https://fund-stg.givewise.ca'
// Uncomment the following to test production behaviour of the UI in development
// showUnfinishedFeatures = false

// Function used to
export const isInDevOrForImpersonatorsInProd = () => {
  const storedState = localStorage.getItem('givewise_auth_state')

  let impersonatorToken = null

  if (storedState) {
    const parsedState = JSON.parse(storedState)
    impersonatorToken = parsedState.state.impersonatorToken
  }
  return showUnfinishedFeatures || impersonatorToken
}

export const onlyShowInDev = process.env.NODE_ENV === 'development'
