import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { addDays, startOfDay } from 'date-fns'

import { validations } from '../form'
import { parseAndSetFormErrors } from '../../utils'
import {
  AdapterDateFns,
  Box,
  Button,
  Checkbox,
  DatePicker,
  Dialog,
  Fields,
  FormControl,
  FormControlLabel,
  Icons,
  InputAdornment,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  Row,
  Select,
  Stack,
  Text,
  useAlert,
  useDialog,
} from '..'

export function EditButton({ type, variables, mutation, refetchQueries }) {
  const [{ dialogProps }, { open: openEditDialog, close: closeEditDialog }] = useDialog()

  return (
    <>
      <Button sx={{ background: 'none', p: 0, '&:hover': { background: 'none !important' } }} onClick={openEditDialog}>
        <Box sx={{ display: 'flex' }}>
          <Icons.Edit />
          <Text.SR>Edit</Text.SR>
        </Box>
      </Button>
      <Dialog {...dialogProps}>
        <EditRecurringPaymentForm
          close={closeEditDialog}
          mutation={mutation}
          refetchQueries={refetchQueries}
          type={type}
          variables={variables}
        />
      </Dialog>
    </>
  )
}

function EditRecurringPaymentForm({ close, mutation, type, refetchQueries, variables }) {
  const [recurringPaymentUpdated, setRecurringPaymentUpdated] = useState(false)
  const { register, handleSubmit, formState, setError, getValues, setValue, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const { isSubmitting, errors = {} } = formState
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [mutate] = useMutation(mutation, {
    onCompleted: () => {
      setRecurringPaymentUpdated(true)
      setAlert({ message: `Recurring ${type} updated successfully.`, severity: 'success' })
    },
    onError: (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      setAlert({ message: `Recurring ${type} update failed.`, severity: 'error' })
    },
    refetchQueries,
  })

  const {
    id,
    amount: existingAmount,
    tip: existingTip,
    nextScheduledGrant: existingNextScheduledGrant,
    nextScheduledTransfer: existingNextScheduledTransfer,
    nextScheduledCharge: existingNextScheduledCharge,
    frequency: existingFrequency,
    grantNote: existingGrantNote,
    anonymous: existingAnonymous,
  } = variables

  // const grantOrTransfer = type === 'Gift' ? 'Grant' : 'Transfer'
  let activityType
  switch (type) {
    case 'Gift':
      activityType = 'Grant'
      break
    case 'Contribution':
      activityType = 'Contribution'
      break
    default:
      activityType = 'Transfer'
      break
  }

  const [datePickerValue, setDatePickerValue] = useState(
    existingNextScheduledGrant || existingNextScheduledTransfer || existingNextScheduledCharge
  )

  useEffect(() => setValue(`nextScheduled${activityType}`, datePickerValue), [datePickerValue, setValue])

  useEffect(() => {
    console.log('new datePickerValue:', datePickerValue)
  }, [datePickerValue])

  const minScheduledDate = startOfDay(addDays(new Date(), 1))

  const onSubmit = async (data) => {
    const {
      amount,
      tip,
      nextScheduledGrant,
      nextScheduledTransfer,
      nextScheduledCharge,
      frequency,
      grantNote,
      anonymous,
    } = data
    const mutateData = {
      id,
      frequency,
      amount: parseFloat(amount.replaceAll(',', ''), 10),
    }

    mutate({
      variables: {
        data: (() => {
          switch (type) {
            case 'Gift':
              return {
                ...mutateData,
                tip: tip ? parseFloat(tip.replaceAll(',', ''), 10) : 0,
                nextScheduledGrant,
                grantNote,
                anonymous,
              }
            case 'Contribution':
              return {
                ...mutateData,
                nextScheduledCharge: datePickerValue,
              }
            default:
              return {
                ...mutateData,
                nextScheduledTransfer,
              }
          }
        })(),
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Text.H6>Update Recurring {type}</Text.H6>
        {recurringPaymentUpdated ? (
          <Button color="info" onClick={close}>
            Done
          </Button>
        ) : (
          <>
            <Stack mx={2}>
              <Fields.Text
                label="Amount"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  inputComponent: Fields.Amount,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: existingAmount,
                  },
                }}
                {...register('amount', {
                  ...{ min: { value: 1, message: 'Must be at least 1' } },
                  ...validations.required,
                  ...validations.number,
                })}
              />
              {type !== 'Shared Fund' && type !== 'Contribution' && (
                <Fields.Text
                  label="Tip"
                  name="tip"
                  error={!!errors?.tip}
                  helperText={errors?.tip?.message}
                  InputProps={{
                    inputComponent: Fields.Amount,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: {
                      setValue: (value) => setValue('tip', value),
                      getValue: () => getValues('tip'),
                      defaultValue: existingTip,
                    },
                  }}
                  {...register('tip', {
                    ...validations.number,
                  })}
                />
              )}
              <FormControl fullWidth>
                <InputLabel id="frequencyLabel">Frequency</InputLabel>
                <Select
                  value={getValues('frequency')}
                  onChange={(e) => {
                    setValue('frequency', e.target.value, { shouldValidate: true })
                  }}
                  label="Frequency"
                  labelId="frequencyLabel"
                  aria-label="Frequency"
                  error={!!errors.frequency?.province}
                  helpertext={errors?.frequency?.province?.message}
                  defaultValue={existingFrequency}
                  {...register('frequency', { ...validations.required })}
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="quarterly">Quarterly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
              <Controller
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      label={`Next Scheduled ${activityType}:`}
                      value={datePickerValue}
                      onChange={(newValue) => {
                        setDatePickerValue(typeof newValue === 'string' ? new Date(newValue) : newValue)
                      }}
                      disablePast
                      minDate={minScheduledDate}
                      renderInput={(params) => (
                        <Fields.Text
                          {...params}
                          {...register(`nextScheduled${activityType}`, {
                            validate: {
                              notInPast: (v) =>
                                new Date(v) >= minScheduledDate || 'Contribution date must not be in the past.',
                            },
                          })}
                          error={!!errors?.nextScheduledGrant}
                          helperText={errors?.nextScheduledGrant?.message}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
                control={control}
                name={`Next Scheduled ${activityType}:`}
                defaultValue={datePickerValue}
              />
              {type !== 'Shared Fund' && type !== 'Contribution' && (
                <>
                  <Fields.Text
                    type="text"
                    label="Grant Note"
                    error={!!errors.grantNote}
                    helperText={errors?.grantNote?.message}
                    defaultValue={existingGrantNote}
                    {...register('grantNote')}
                  />
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register('anonymous')}
                          checked={getValues('anonymous') || existingAnonymous}
                          onChange={(e) => {
                            setValue('anonymous', e.target.checked, { shouldValidate: true })
                          }}
                        />
                      }
                      label="I wish to remain anonymous"
                    />
                  </FormControl>
                </>
              )}
            </Stack>
            <Row justifyContent="flex-end" alignItems="center" m={4}>
              <>
                <Button color="info" onClick={close} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button disabled={isSubmitting} type="submit">
                  Update
                </Button>
              </>
            </Row>
          </>
        )}
        <Alert {...alertProps} />
      </Stack>
    </form>
  )
}
