import { Alert as MuiAlert } from '@mui/material'
import { useRef, useState } from 'react'

import { Box, Button, IconButton, Icons, Link, Text } from '..'

function Alert({ message = '', severity, error = '', action = {}, extraMessage = '', handleClose, sx, ...props }) {
  return message ? (
    <MuiAlert
      variant="filled"
      action={
        action?.label && (
          <>
            <Button color="secondary" size="small" onClick={action.onClick}>
              {action.label}
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <Icons.Close fontSize="small" />
            </IconButton>
          </>
        )
      }
      severity={severity}
      onClose={handleClose}
      sx={{ width: 'auto', ...sx }}
      {...props}
    >
      <Box>
        <Text.Body>{message}</Text.Body>
        {error && (
          <>
            <Text.AltBody>
              <i>{error}</i>
            </Text.AltBody>
            {extraMessage}
            <Text.AltBody>
              For support, please contact us at <Link href="mailto:support@givewise.ca">support@givewise.ca</Link>
            </Text.AltBody>
          </>
        )}
      </Box>
    </MuiAlert>
  ) : null
}

// Defining two options for the timeout length of an alert message
export const longWait = 60000
export const shortWait = 8000

export function useAlert() {
  const initialState = {
    message: '',
    severity: undefined,
    action: { label: undefined, onClick: undefined },
  }

  const [alert, setAlertState] = useState(initialState)

  const timerAutoHide = useRef()

  const handleClose = () => {
    setAlertState(initialState)
  }

  const setAlert = ({ timeout, ...newAlert }) => {
    clearTimeout(timerAutoHide.current)
    setAlertState(newAlert)
    timerAutoHide.current = setTimeout(() => handleClose(), timeout || shortWait)
  }

  return [{ Alert, alertProps: { ...alert, handleClose } }, { setAlert }]
}

export function insufficientFundsExtraMessage(fundId) {
  return (
    <Text.AltBody>
      <Link to={`/funds/${fundId}/contribute`}>Click here </Link>to add money to your fund.
    </Text.AltBody>
  )
}
