import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { queries } from '../../graphql'
import {
  BlockQuote,
  Button,
  CardBox,
  Container,
  Fields,
  FormControlLabel,
  IconButton,
  Icons,
  Link,
  RecentAndFavouriteCharities,
  Row,
  Stack,
  Switch,
  Text,
  useAlert,
} from '../../components'
import { useSearchParams } from '../../hooks'
import { Results } from './search-result'

export function SearchByLocation() {
  const [isOpen, setIsOpen] = useState(false)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const [search, setSearchParams] = useSearchParams()

  const fav = search.f === 'true'
  const p = Number(search.p)
  const page = !Number.isNaN(p) ? p : 0
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors = [] } = {},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const city = getValues('city') || ''
  const province = getValues('province') || ''
  const postalCode = getValues('postalCode') || ''

  const onlyShowRecentAndFavourites = city === '' && province === '' && postalCode === ''

  const [searchCharitiesByLocation, { loading, data } = {}] = useLazyQuery(
    queries.charities.searchCharitiesByLocation,
    {
      errorPolicy: 'all',
      onError: (r) => {
        setAlert({ message: 'Could not complete this search', error: r.message, severity: 'error' })
      },
    }
  )

  useEffect(() => {
    if (!onlyShowRecentAndFavourites || fav) {
      searchCharitiesByLocation({ variables: { city, province, postalCode, page, onlyShowFavourites: fav } })
    }
  }, [city, province, postalCode, page, fav, searchCharitiesByLocation])

  const onSubmit = async () => {
    setSearchParams({ city, province, postalCode, p: 0, f: fav })
  }

  return (
    <Container maxWidth="lg">
      <Stack spacing={1}>
        <CardBox>
          <Stack>
            <Text.H4>Search For a Charity By Location</Text.H4>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Stack>
                <Row>
                  <Fields.Text
                    label="City"
                    error={!!errors.city}
                    helperText={errors?.city?.message}
                    {...register('city')}
                  />
                  <Fields.Text
                    label="Province"
                    error={!!errors.province}
                    helperText={errors?.province?.message}
                    {...register('province')}
                  />
                  <Fields.Text
                    label="Postal Code"
                    error={!!errors?.postalCode}
                    helperText={errors?.postalCode?.message}
                    {...register('postalCode')}
                  />
                </Row>
                <Row sx={{ marginTop: '2rem' }} alignSelf="flex-end">
                  <Button type="submit">
                    <Icons.Search />
                    Submit
                  </Button>
                </Row>
                <Row justifyContent="space-between">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={fav}
                        onChange={(e) => {
                          setSearchParams({
                            city,
                            province,
                            postalCode,
                            p: page,
                            f: e.target.checked,
                          })
                        }}
                      />
                    }
                    label="Only show my favourites"
                  />
                  <Button to="?f=true">
                    <Icons.FavoriteBorder style={{ color: 'var(--dark-blue)', marginRight: '10px' }} />
                    All My Favourites
                  </Button>
                </Row>
                <Alert sx={{ alignSelf: 'flex-end' }} {...alertProps} />
              </Stack>
            </form>
            <BlockQuote>
              <Stack direction="row" alignItems="center">
                <Text.Bold>Having trouble finding a charity?</Text.Bold>
                <IconButton onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                </IconButton>
              </Stack>
              {isOpen && (
                <Text.Body>
                  Search for any Registered Canadian Charity using the charity name or registration number. If the
                  intended charity doesn&apos;t appear in your search:
                  <ul>
                    <li>Ensure the Business Number format is: #########RR### (Example: 701032526RR001)</li>
                    <li>Contact the charity and ask about the Legal or Registered name</li>
                    <li>
                      Check to see if the organization is a non-profit or a qualified donee by searching{' '}
                      <Link
                        target="_blank"
                        href="https://www.canada.ca/en/revenue-agency/services/charities-giving/list-charities/list-charities-other-qualified-donees.html"
                      >
                        here
                      </Link>
                    </li>
                    <li>
                      Still can&apos;t find the charity you&apos;re looking for? Email{' '}
                      <Link href="mailto:grants@givewise.ca">grants@givewise.ca</Link> and we can help
                    </li>
                  </ul>
                </Text.Body>
              )}
            </BlockQuote>
            {onlyShowRecentAndFavourites && !fav ? (
              <RecentAndFavouriteCharities showAddress />
            ) : (
              <Results
                loading={loading}
                page={page}
                onPageChange={(newPage) => {
                  setSearchParams({ city, province, postalCode, p: newPage, f: fav })
                }}
                charities={data?.searchCharitiesByLocation?.data}
                count={data?.searchCharitiesByLocation?.count}
              />
            )}
          </Stack>
        </CardBox>
      </Stack>
    </Container>
  )
}
