import { gql } from '@apollo/client'

export const initiateGuestCreditCard = gql`
  mutation initiateGuestCreditCard($userId: Int!) {
    initiateGuestCreditCard(userId: $userId)
  }
`

export const finalizeGuestCreditCard = gql`
  mutation finalizeGuestCreditCard($userId: Int!, $data: FinalizeCreditCardInput!) {
    finalizeGuestCreditCard(userId: $userId, data: $data) {
      id
      paymentType
      details
      multiUse
      status
      default
    }
  }
`
