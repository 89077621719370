import { useState } from 'react'
import { ReferenceField, TextField, useDelete, useNotify, useResourceContext, useUpdate } from 'react-admin'
import { Box, Button, FilledInput, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import TrashIcon from '@mui/icons-material/Delete'

import { Fields } from '..'
import palette from '../../../palette.json'
import { Text } from '../../../components'

export function Note({ note }) {
  const [isHover, setHover] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [noteText, setNoteText] = useState(note.note)
  const resource = useResourceContext()
  const notify = useNotify()

  const [update, { isLoading }] = useUpdate()

  const [deleteNote] = useDelete(
    resource,
    { id: note.id, previousData: note },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('Note deleted', { type: 'info', undoable: true })
      },
    }
  )

  const handleDelete = () => {
    deleteNote()
  }

  const handleEnterEditMode = () => {
    setEditing(true)
  }

  const handleCancelEdit = () => {
    setEditing(false)
    setNoteText(note.note)
    setHover(false)
  }

  const handleTextChange = (event) => {
    setNoteText(event.target.value)
  }

  const handleNoteUpdate = (event) => {
    event.preventDefault()
    update(
      resource,
      { id: note.id, data: { note: noteText }, previousData: note },
      {
        onSuccess: () => {
          setEditing(false)
          setNoteText(noteText)
          setHover(false)
        },
      }
    )
  }

  return (
    <Box mb={2} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {isEditing ? (
        <form onSubmit={handleNoteUpdate}>
          <FilledInput
            value={noteText}
            onChange={handleTextChange}
            fullWidth
            multiline
            sx={{
              paddingTop: '16px',
              paddingLeft: '14px',
              paddingRight: '60px',
              paddingBottom: '14px',
              lineHeight: 1.3,
            }}
            autoFocus
          />
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button sx={{ mr: 1 }} onClick={handleCancelEdit} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
              Update Note
            </Button>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            bgcolor: palette.light.grey,
            padding: '0 1em',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'stretch',
            marginBottom: 1,
          }}
        >
          <Box flex={1}>
            {note.note.split('\n').map((paragraph) => (
              <Box
                component="p"
                fontFamily="fontFamily"
                fontSize="body1.fontSize"
                lineHeight={1.3}
                marginBottom={2.4}
                key={paragraph.replace(/[^a-zA-Z0-9]/, '-')}
              >
                {paragraph}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              marginLeft: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              visibility: isHover ? 'visible' : 'hidden',
            }}
          >
            <Tooltip title="Edit note">
              <IconButton size="small" onClick={handleEnterEditMode}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete note">
              <IconButton size="small" onClick={handleDelete}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
      <Box mb={1} display="flex" justifyContent="flex-end" color="text.secondary">
        <Text.Caption>
          Last edited{' - '}
          <Fields.Date source="updatedAt" record={note} showTime />
          {' - '}
          <ReferenceField record={note} source="userId" reference="User">
            <TextField source="name" />
          </ReferenceField>
        </Text.Caption>
      </Box>
    </Box>
  )
}
