import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { queries } from '../../graphql'

export function CategoryFilter({ setSelectedCategoryCodes }) {
  const [charityCategories, setCharityCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [allCategoryCodes, setAllCategoryCodes] = useState([])

  const { error, data } = useQuery(queries.charities.getCharityCategories)

  useEffect(() => {
    if (data && data.charityCategory) {
      setCharityCategories(data.charityCategory)
      // setSelectedCategories(data.charityCategory)
      const allCodes = data.charityCategory.flatMap((category) => category.categoryCodes)
      setAllCategoryCodes(allCodes)
    }
  }, [data])

  useEffect(() => {
    const allCodes = charityCategories.flatMap((category) => category.categoryCodes)
    setAllCategoryCodes(allCodes)
  }, [charityCategories])

  useEffect(() => {
    const codes = selectedCategories.flatMap((category) => category.categoryCodes)
    if (codes.length === 0) {
      setSelectedCategoryCodes(allCategoryCodes)
    } else {
      setSelectedCategoryCodes(codes)
    }
  }, [selectedCategories, setSelectedCategoryCodes, charityCategories, allCategoryCodes])

  if (error) return <p>Error: {error.message}</p>

  return (
    <div>
      {charityCategories && (
        <div>
          <Autocomplete
            fullwidth
            sx={{ mt: 2 }}
            multiple
            id="tags-standard"
            options={charityCategories}
            getOptionLabel={(option) => option.name}
            value={selectedCategories}
            onChange={(event, newValue) => {
              setSelectedCategories(newValue)
            }}
            renderInput={(params) => (
              <TextField
                fullwidth
                style={{ minWidth: '300px', paddingRight: '120px' }}
                {...params}
                variant="outlined"
                label="Select Categories"
                placeholder="Select Categories"
              />
            )}
          />
        </div>
      )}
    </div>
  )
}
