import { gql } from '@apollo/client'

export const sendPaylink = gql`
  mutation sendPaylink($id: String!, $email: String!, $note: String!) {
    sendPaylink(id: $id, email: $email, note: $note) {
      id
    }
  }
`

export const updateCharityCustom = gql`
  mutation updateCharityCustom($id: String!, $data: UpdateCharityInput!) {
    updateCharityCustom(id: $id, data: $data) {
      id
      designationCode
      categoryCode
      subcategoryCode
      accountName
      legalName
      mailingAddress
      city
      province
      country
      postalCode
      contactPhoneNumber
      emailAddress
      websiteAddress
      active
    }
  }
`

export const removeCharityBankAccount = gql`
  mutation removeCharityBankAccount($id: String!, $paymentMethodId: Float!) {
    removeCharityBankAccount(id: $id, paymentMethodId: $paymentMethodId)
  }
`

export const inviteCharityToSignUp = gql`
  mutation inviteCharityToSignUp($charityId: String!, $email: String!, $note: String!) {
    inviteCharityToSignUp(charityId: $charityId, email: $email, note: $note) {
      id
      email
    }
  }
`

export const inviteCharityToSignUpCharityPortal = gql`
  mutation inviteCharityToSignUpCharityPortal($charityId: String!, $email: String!, $note: String!) {
    inviteCharityToSignUpCharityPortal(charityId: $charityId, email: $email, note: $note) {
      id
      email
    }
  }
`

export const revokeInvite = gql`
  mutation revokeInvite($id: Float!) {
    revokeInvite(id: $id) {
      id
    }
  }
`
export const revokeInviteCharityPortal = gql`
  mutation revokeInviteCharityPortal($id: Float!) {
    revokeInviteCharityPortal(id: $id) {
      id
    }
  }
`

export const setActiveOverride = gql`
  mutation setActiveOverride($id: String!, $isActiveOverride: String!) {
    setActiveOverride(id: $id, isActiveOverride: $isActiveOverride) {
      id
    }
  }
`

export const getCharityAiDescription = gql`
  mutation getCharityAiDescription($id: String!) {
    getCharityAiDescription(id: $id)
  }
`

export const getCharityAiLogos = gql`
  mutation getCharityAiLogos($id: String!) {
    getCharityAiLogos(id: $id)
  }
`

export const updateCharityDescription = gql`
  mutation updateCharityDescription($data: UpdateCharityDescriptionInput!) {
    updateCharityDescription(description: $data) {
      charityId
      description
      tagline
    }
  }
`

export const updateCharityLogo = gql`
  mutation updateCharityLogo($data: UpdateCharityAssetInput!) {
    updateCharityLogo(data: $data) {
      charityId
      logoPath
      imagePath
      logo
    }
  }
`
export const updateMyCharityDescription = gql`
  mutation updateMyCharityDescription($data: UpdateCharityDescriptionInput!) {
    updateMyCharityDescription(description: $data) {
      charityId
      description
      tagline
    }
  }
`

export const updateMyCharityLogo = gql`
  mutation updateMyCharityLogo($data: UpdateCharityAssetInput!) {
    updateMyCharityLogo(data: $data) {
      charityId
      logoPath
      imagePath
      logo
    }
  }
`

export const generateLandingPage = gql`
  mutation generateLandingPage($id: String!, $templateName: String!) {
    generateLandingPage(id: $id, templateName: $templateName)
  }
`

export const createPurposeOfGift = gql`
  mutation createPurposeOfGift($data: CreatePurposeOfGiftInput!) {
    createPurposeOfGift(data: $data) {
      id
      reason
      active
    }
  }
`

export const updatePurposeOfGift = gql`
  mutation updatePurposeOfGift($data: [UpdatePurposeOfGiftInput!]!) {
    updatePurposeOfGift(data: $data) {
      id
      reason
      active
    }
  }
`

export const deletePurposeOfGift = gql`
  mutation deletePurposeOfGift($id: Float!) {
    deletePurposeOfGift(id: $id) {
      id
      reason
      active
    }
  }
`

export const updateSocialMediaLink = gql`
  mutation updateSocialMediaLink($id: Int!, $link: String!, $platform: String!, $charityId: String!) {
    updateSocialMediaLink(id: $id, link: $link, platform: $platform, charityId: $charityId) {
      id
      link
      platform
      charityId
    }
  }
`
export const createSocialMediaLink = gql`
  mutation createSocialMediaLink($data: CreateSocialMediaLinkInput!) {
    createSocialMediaLink(data: $data) {
      link
      platform
      charityId
    }
  }
`
