import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { LocalStorageContext, SHARE_FUND_CODE } from '../../components/local-storage/localStorage'
import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Row,
  Select,
  Stack,
  Text,
  validations,
} from '../../components'
import { NoMatch } from '..'
import { mutations, queries } from '../../graphql'
import { toAmountString } from '../../utils'

export function ReceiveADafTransfer() {
  const [, { logout }] = useAuth()
  const { id, receiveToken } = useParams()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors = {} } = {},
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      fundId: '',
    },
  })
  const currentFundId = Number(watch('fundId'))
  const [receiveDafTransfer, { data, error, reset, isLoading }] = useMutation(
    mutations.dafTransfers.receiveDafTransfer,
    {
      refetchQueries: [
        {
          query: queries.funds.myFund,
          variables: {
            fundId: currentFundId,
          },
        },
        {
          query: queries.funds.myFundActivity,
          variables: {
            fundId: currentFundId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  )
  const { data: { me: { funds = [] } = {} } = {}, loading } = useQuery(queries.funds.myFunds)
  const multipleFunds = funds.length > 1

  const Success = useCallback(
    () => (
      <Acknowledge.Success
        title="Gift Received!"
        content={
          <Text.Body>
            <b>Amount</b>: {toAmountString(data?.receiveDafTransfer?.amount)}
            <br />
            <b>To</b>: {data?.receiveDafTransfer?.sentTo}
          </Text.Body>
        }
      >
        <Button to={`/funds/${data?.receiveDafTransfer?.receivingFundId}/activity/giving-wallet`}>Done</Button>
      </Acknowledge.Success>
    ),
    [data]
  )
  const Error = useCallback(
    () => (
      <Acknowledge.Error title="Uh Oh!" message={error?.message}>
        <Button onClick={reset}>Back</Button>
      </Acknowledge.Error>
    ),
    [reset, error]
  )
  const localStorageContext = useContext(LocalStorageContext)

  const onSubmit = async ({ fundId }) => {
    receiveDafTransfer({ variables: { data: { fundId: Number(fundId), id: Number(id), receiveToken } } })

    // Clear shareFundCode whether success or failure
    localStorageContext.clearValue(SHARE_FUND_CODE)
  }

  const handleSignOut = async (_e) => {
    await logout()
  }

  if (loading) {
    return <LinearProgress />
  }
  if (!receiveToken) return <NoMatch />
  return (
    <Container maxWidth="md">
      <CardBox>
        <Acknowledge.Switch success={!!data && <Success />} error={!!error && <Error />}>
          <Stack>
            <Text.H1>Claim Your Shared Funds</Text.H1>
            {multipleFunds ? (
              <Text.Body>
                These Shared Funds will be posted to the Giving Fund you select from the dropdown below. If you prefer a
                different fund, sign out and log into the appropriate fund.
              </Text.Body>
            ) : (
              <Text.Body>
                These Shared Funds will be posted to the Giving Fund listed below. If you prefer a different fund, sign
                out and log into the appropriate fund.
              </Text.Body>
            )}
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Stack spacing={2}>
                <FormControl fullWidth error={!!errors?.fundId?.message}>
                  {multipleFunds ? (
                    <>
                      <InputLabel id="fundIdLabel" htmlFor="fundIdSelect">
                        Fund
                      </InputLabel>
                      <Select
                        labelId="fundIdLabel"
                        id="fundIdSelect"
                        aria-label="Fund"
                        label="Fund"
                        defaultValue=""
                        {...register('fundId', { ...validations.required })}
                      >
                        {funds.map(({ name, number, id: fundId }) => (
                          <MenuItem key={fundId} value={fundId}>
                            {name} - {number}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <RadioGroup {...register('fundId', { ...validations.required })} defaultValue={funds[0].id}>
                      <FormControlLabel
                        value={funds[0].id}
                        control={<Radio sx={{ fontWeight: 'bold' }} />}
                        label={`${funds[0].name} - ${funds[0].number}`}
                      />
                    </RadioGroup>
                  )}
                  {errors?.fundId && <FormHelperText>{errors?.fundId?.message}</FormHelperText>}
                </FormControl>
                <Row justifyContent="flex-end">
                  <Button disabled={isLoading} type="submit">
                    Accept
                  </Button>
                  <Button onClick={handleSignOut} color="info">
                    Sign Out
                  </Button>
                </Row>
              </Stack>
            </form>
          </Stack>
        </Acknowledge.Switch>
      </CardBox>
    </Container>
  )
}
