import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { validations } from '../components/form'
import {
  Acknowledge,
  Button,
  Container,
  ContentBox,
  Fields,
  FormHelperText,
  Link,
  Row,
  Stack,
  Text,
} from '../components'
import { mutations } from '../graphql'

export function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { errors = {} } = formState

  const [requestPasswordReset, { loading, data, reset: resetMutation }] = useMutation(
    mutations.user.requestPasswordReset,
    {
      onCompleted: () => {
        resetForm()
      },
    }
  )

  const onSubmit = ({ email }) => {
    requestPasswordReset({ variables: { data: { email } } })
  }

  const Success = useCallback(
    () => (
      <Acknowledge.Success
        title="Thanks!"
        content={
          <Text.Body>
            You have been emailed a link to reset your password. If you do not receive this email, please check your
            junk mail or email <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> for assistance.
          </Text.Body>
        }
      >
        <Button color="info" onClick={resetMutation}>
          Back
        </Button>
        <Button to="/login">Log In</Button>
      </Acknowledge.Success>
    ),
    [resetMutation]
  )

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <Acknowledge.Switch success={!!data && <Success />}>
          <Text.H5 data-testid="forgotPasswordTitle">Forgot Password?</Text.H5>
          <Text.Body data-testid="contentText">
            Please enter the email address you used to register your account, and we will send you instructions to reset
            your password.
          </Text.Body>

          <ContentBox border>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <Fields.Text
                  inputProps={{ 'data-testid': 'email' }}
                  label="Your Email *"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...register('email', { ...validations.required, ...validations.email })}
                />

                <Row justifyContent="flex-end" alignItems="center">
                  <Text.AltBody>
                    Still need help? Contact{' '}
                    <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} data-testid="supportLink">
                      support
                    </Link>
                  </Text.AltBody>

                  <Button type="submit" disabled={loading} data-testid="sendButton">
                    Send
                  </Button>
                  {errors.form && <FormHelperText error={!!errors.form}>{errors.form.message}</FormHelperText>}
                </Row>
              </Stack>
            </form>
          </ContentBox>
        </Acknowledge.Switch>
        <Row justifyContent="flex-end" alignItems="center" px={4} m={4}>
          <Link href="/login" data-testid="backLoginLink">
            Back to Login
          </Link>
        </Row>
      </Stack>
    </Container>
  )
}
