import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Tab as MuiTab, Tabs as MuiTabs, useMediaQuery, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { Button, Icons, Link, Menu, MenuItem } from '..'

export function NavTabs({ tabs, breakpoint = 'sm', mobileMenuButton = false, sx, children, tabProps, ...props }) {
  const theme = useTheme()
  const isDesktopView = useMediaQuery(theme.breakpoints.up(breakpoint))
  const { pathname } = useLocation()
  const [active, setActive] = useState(tabs.filter(({ to }) => pathname.includes(to)).slice(-1)[0])
  useEffect(() => {
    setActive(tabs.filter(({ to }) => pathname.includes(to)).slice(-1)[0])
  }, [pathname, tabs])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  if (isDesktopView) {
    return (
      <div>
        <MuiTabs value={active?.to ?? false} onChange={(e, to) => setActive(to)} sx={sx} {...props}>
          {tabs.map(({ text, label, to }) => (
            <MuiTab value={to} component={Link} {...tabProps} label={label} to={to} key={label} text={text} />
          ))}
          {children}
        </MuiTabs>
      </div>
    )
  }
  return (
    <>
      <Button
        size={mobileMenuButton ? 'large' : 'medium'}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={mobileMenuButton ? null : <Icons.KeyboardArrowDown />}
      >
        {mobileMenuButton ? <MenuIcon /> : active?.text ?? active?.label ?? <MenuIcon />}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {tabs.map(({ text, label, to }) => (
          <MenuItem component={Link} onClick={() => setAnchorEl(null)} label={label} to={to} key={label}>
            {text ?? label}
          </MenuItem>
        ))}
        {children}
      </Menu>
    </>
  )
}
