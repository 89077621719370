import { Outlet, Navigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { queries } from '../../graphql'
import { Container, NavTabs, Text, LinearProgress } from '../../components'
import { NoMatch } from '../noMatch'

export function Fund() {
  const { fundId } = useParams()
  const { data: { me: { fund = {} } = {} } = {}, loading } = useQuery(queries.funds.myFund, {
    skip: !fundId,
    variables: { fundId: parseInt(fundId, 10) },
  })
  if (!fundId) return <NoMatch />
  if (loading) return <LinearProgress />
  if (!fund?.id) return <NoMatch />
  return <Outlet />
}

export function FundActivity() {
  const navigation = [
    { label: 'Giving Wallet', to: 'giving-wallet' },
    { label: 'Investment Account', to: 'investment-account' },
    { label: 'Recurring', to: 'recurring' },
  ]
  return (
    <>
      <Container maxWidth="lg" sx={{ mb: 0 }}>
        <Text.H1>Activity</Text.H1>
        <NavTabs tabs={navigation} />
      </Container>
      <Outlet />
    </>
  )
}

export function RedirectToDefaultFund({ using }) {
  const params = useParams()
  const { data: { me: { fund } = {} } = {}, loading } = useQuery(queries.funds.myFund)
  if (loading) return <LinearProgress />
  if (!fund) return <NoMatch />
  return <Navigate to={`/funds/${fund.id}/${using(params)}`} replace />
}
