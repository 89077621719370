import palette from '../../palette.json'
import { Box } from '..'
import { join } from '../../utils'

export function Iframe({ title = 'iframe', src, embedUrl, iframeKey, style = {} }) {
  return (
    <Box sx={{ position: 'relative', width: '100%', ...style }}>
      <iframe
        title={title}
        src={src || join([embedUrl, iframeKey ? `key=${iframeKey}` : null], '?')}
        style={{
          width: '100%',
          height: '100%',
          borderStyle: 'solid',
          borderWidth: '3px',
          borderColor: palette.light.grey,
          borderRadius: '15px',
        }}
      />
    </Box>
  )
}
