import { useMutation, useQuery } from '@apollo/client'

import { mutations, queries } from '../../graphql'
import { IconButton, Icons } from '..'

export function FavouriteCharityButton({ charityId, ...props }) {
  const { data: { me: { favouriteCharities = [] } = { favouriteCharities: [] } } = {} } = useQuery(queries.user.me)
  const [favouriteACharity] = useMutation(mutations.user.favouriteACharity, {
    variables: { charityId },
    refetchQueries: [{ query: queries.user.me }],
    awaitRefetchQueries: true,
  })
  const [unfavouriteACharity] = useMutation(mutations.user.unfavouriteACharity, {
    variables: { charityId },
    refetchQueries: [{ query: queries.user.me }],
    awaitRefetchQueries: true,
  })

  const isFavourited = favouriteCharities ? favouriteCharities.some((charity) => charity.id === charityId) : false

  const handleButtonClick = isFavourited ? unfavouriteACharity : favouriteACharity

  return (
    <IconButton size="small" onClick={handleButtonClick} {...props}>
      {isFavourited ? (
        <Icons.Favorite style={{ color: 'var(--red)' }} />
      ) : (
        <Icons.FavoriteBorder style={{ color: 'var(--dark-blue)' }} />
      )}
    </IconButton>
  )
}
