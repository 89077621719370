import { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { parseAndSetFormErrors } from '../../utils'
import {
  Acknowledge,
  Button,
  Container,
  ContentBox,
  Fields,
  FormHelperText,
  Link,
  Row,
  Stack,
  Text,
  useAlert,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { Icons } from '../../components'

export function SignUpSuccess() {
  return (
    <Container maxWidth="sm">
      <Acknowledge.Success
        title="Welcome to GiveWise and our community of generous givers!"
        content={
          <Text.Body>
            Please check your email for a link to confirm your email and log in to your account. If you do not receive a
            confirmation email, please check your junk mail or email{' '}
            <Link href="mailto:support@givewise.ca">support@givewise.ca</Link> for assistance.
          </Text.Body>
        }
      />
    </Container>
  )
}

export function ClaimCharity() {
  const { id: charityId, token } = useParams()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const navigate = useNavigate()
  const { data: { charity } = {} } = useQuery(queries.charities.charity, {
    variables: { charityId },
  })
  const { data: { validateInviteToken } = {}, loading: validateTokenLoading } = useQuery(
    queries.charities.validateInviteToken,
    {
      variables: {
        data: {
          charityId,
          token,
        },
      },
    }
  )
  const { register, handleSubmit, formState, setError, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  const { errors = {} } = formState
  const alertGraphQlError = (newErrors) => {
    if (newErrors.graphQLErrors) {
      setAlert({
        message: newErrors.graphQLErrors[0].message.slice(0, 25),
        severity: 'error',
      })
    } else if (newErrors.networkError) {
      setAlert({ message: newErrors.networkError, severity: 'error' })
    } else if (newErrors.clientErrors) {
      setAlert({ message: newErrors.clientErrors[0].message, severity: 'error' })
    }
  }
  const [charityUserSignUp, { data, loading }] = useMutation(mutations.user.charityUserSignUp, {
    onCompleted: () => {
      navigate('/sign-up/success')
    },
    onError: async (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      alertGraphQlError(newErrors)
      if (newErrors?.message.includes('Email taken')) {
        setAlert({ message: 'An account with this email address already exists.', severity: 'error' })
      }
    },
  })

  const onSubmit = ({ email, password, passwordConfirmation }) => {
    charityUserSignUp({
      variables: {
        data: {
          charityId,
          email,
          password,
          passwordConfirmation,
          token,
        },
      },
    })
  }

  if (validateInviteToken === 'false' || validateInviteToken === 'already_accepted') {
    return (
      <Container style={{ maxWidth: '630px' }}>
        <Row alignItems="center" spacing={4}>
          {/* <Icons.WarningTriangle sx={{ fontSize: '3rem' }} color="success" /> */}
          <Stack spacing={2}>
            <Text.H5 data-testid="signupSuccessMessage">Invitation Invalid</Text.H5>
            <Text.Body>
              It appears you are using an Invitation that has already been accepted or is no longer valid. Email{' '}
              <a href="mailto:grants@givewise.ca">grants@givewise.ca</a> to request another invitation or for support.
            </Text.Body>
          </Stack>
        </Row>
      </Container>
    )
  }

  return (
    <Container style={{ maxWidth: '630px' }}>
      <Stack spacing={4}>
        <Text.H1 style={{ color: '#62bee8', fontWeight: 'bold' }}>Welcome</Text.H1>
        <Text.H6 component="h1" data-testid="title">
          {charity?.accountName}
        </Text.H6>
        <Text.H6 style={{ marginTop: 0, fontWeight: 350 }}>#{charity?.id}</Text.H6>
        <Text.Body data-testid="content">
          You have been invited to join GiveWise, a community dedicated to growing generosity.
        </Text.Body>
        <ContentBox border>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="flex-start">
              <Text.Bold style={{ marginBottom: 10 }}>Create Account</Text.Bold>
              <Fields.Text
                inputProps={{ 'data-testid': 'email' }}
                label="Email *"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email', { ...validations.required, ...validations.email })}
              />
              <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
              <Row justifyContent="space-between" spacing={4} style={{ marginTop: 30 }}>
                <Text.Body variant="body2" sx={{ maxWidth: '300px', textAlign: 'left' }}>
                  By clicking &ldquo;Sign Up&rdquo;, you agree to our{' '}
                  <Link
                    href="https://www.givewise.ca/terms-conditions"
                    target="_blank"
                    variant="body2"
                    data-testid="termConditionLink"
                  >
                    Terms and Conditions
                  </Link>
                </Text.Body>
                <Button type="submit" disabled={loading} sx={{ whiteSpace: 'nowrap' }} data-testid="signUpButton">
                  Sign Up
                </Button>
                {errors.form && <FormHelperText error={!!errors.form}>{errors.form.message}</FormHelperText>}
              </Row>
            </Stack>
          </form>
        </ContentBox>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Text.Body>
            Not your information? <Link href="mailto:support@givewise.ca">Contact Us</Link>
          </Text.Body>
        </Row>
      </Stack>
      <Alert {...alertProps} />
    </Container>
  )
}
