import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { GridRow, useGridApiContext } from '@mui/x-data-grid'

import {
  formatContributionDescription,
  formatDafTransferDescription,
  getTodaysDateFileName,
  grantRequestValueFormatter,
  toAmountString,
  toDateString,
} from '../../utils'
import { mutations, queries } from '../../graphql'
import { Button, CancelButton, CardBox, Container, DataGrid, Icons, Link, Stack, Text, Tooltip } from '../../components'
// import { Filters } from './filters'

function useIsNarrowerThan(sz) {
  const theme = useTheme()
  return !useMediaQuery(theme.breakpoints.up(sz))
}

/* Shown as column on rows to toggle expand / collapse. */
function RowExpandButton({ id, isExpanded, toggleExpanded, sx, ...props }) {
  return (
    <Button sx={{ flexGrow: 1, p: 0, minWidth: 0, ...sx }} unstyled {...props} onClick={() => toggleExpanded(id)}>
      {isExpanded(id) ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
    </Button>
  )
}

function useColumnsForExpandPanel() {
  const apiRef = useGridApiContext()
  /* showInExpandedRowPanel can exclude some columns from rendering in the expanded panel.
   * Otherwise, show columns that have been hidden. */
  return apiRef.current
    .getAllColumns()
    .filter(({ showInExpandedRowPanel = true, hide = false }) => showInExpandedRowPanel && hide)
}

/* A GridRow component with that conditionally shows an ExpandedRowPanel. */
function ExpandableRow({ isExpanded, rowId, ...props }) {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  return (
    <div>
      <GridRow rowId={rowId} {...props} />
      {isExpanded(rowId) && isNarrowerThanMd && <ExpandedRowPanel data-expanded={rowId} rowId={rowId} />}
    </div>
  )
}

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

function ExpandedRowPanel({ rowId, sx, ...props }) {
  const apiRef = useGridApiContext()
  const fields = useColumnsForExpandPanel()
    .map(({ field, headerName, renderCell }) => {
      const cellParams = apiRef.current.getCellParams(rowId, field)
      const content =
        renderCell?.({ ...cellParams, api: apiRef.current }) ??
        cellParams.formattedValue?.toString() ??
        cellParams.value?.toString()
      return { key: field, headerName, content }
    })
    .filter(({ content }) => content)

  const justify = useIsNarrowerThan('sm') ? 'space-between' : 'start'

  return (
    <Stack spacing={0} sx={{ pl: 7, ...sx }} {...props}>
      {fields.map(({ key, headerName, content }) => (
        <Stack key={key} direction="row" justifyContent={justify} minHeight="28px" alignItems="center">
          <div style={{ flex: 1, maxWidth: '140px' }}>{headerName}</div>
          <div>{content}</div>
        </Stack>
      ))}
    </Stack>
  )
}

export function RecurringGifts() {
  const isNarrowerThanMd = useIsNarrowerThan('md')
  const isNarrowerThanSm = useIsNarrowerThan('sm')

  const gridRef = useRef()
  const gridApi = useRef()
  const charityId = Number(useParams().id)

  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId: useParams().id },
  })

  const { data: { allUsers } = {}, usersLoading } = useQuery(queries.user.getAllUsers)

  const [expandMap, setExpandMap] = useState({})
  const isExpanded = useCallback((rowId) => expandMap[rowId] || false, [expandMap])
  const toggleExpanded = useCallback((rowId) => setExpandMap({ ...expandMap, [rowId]: !expandMap[rowId] }), [expandMap])

  function getTypeName(value) {
    switch (value) {
      case 'GrantRequest':
        return 'Gifts'
      case 'DafTransfer':
        return 'Shared Funds'
      default:
        return value
    }
  }

  if (!allUsers) {
    return <LinearProgress />
  }

  const rows = charity
    ? charity.recurringGrantRequests
        .filter((rg) => rg.state === 'active')
        .map((rg) => {
          const user = allUsers?.find((u) => u.id === rg.userId)
          return {
            ...rg,
            donorName: user.name,
            email: user.email,
            fundName: user.funds[0]?.name,
          }
        })
    : []

  // /* hide means is hidden from the columnar display and shown in ExpandedRowPanel.
  //  * showInExpandedRowPanel is a boolean that, when false, excludes showing that column in the expanded panel.
  //  *
  //  * Some columns have both because they are never shown in the datagrid but should appear in the csv export... */
  const columns = [
    {
      /* field is required so we have to provide a value, but no value makes
       * sense because this does not map to a property on the row */
      field: '__expand',
      disableExport: true,
      hide: !isNarrowerThanMd,
      sortable: false,
      filterable: false,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      renderCell: ({ row } = {}) => (
        <RowExpandButton id={row.id} isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
      ),
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: '__typename',
      headerName: 'Type',
      flex: 0.5,
      align: 'center',
      minWidth: 44,
      maxWidth: 44,
      valueFormatter: ({ value }) => getTypeName(value),
      renderCell: ({ row } = {}) => <Icons.ActivityItem row={row} />,
      renderHeader: () => null,
      showInExpandedRowPanel: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 80,
      valueFormatter: ({ api: { getRow } = {}, id, value }) => toAmountString(value, getRow(id)?.showAsDebit),
      renderCell: ({ value, row: { showAsDebit } = {} } = {}) => (
        <Text.Body>{toAmountString(value, showAsDebit)}</Text.Body>
      ),
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 80,
      renderCell: ({ row }) => capitalizeFirstLetter(row.frequency) ?? '',
    },
    {
      field: 'donorName',
      headerName: 'Donor',
      hide: isNarrowerThanSm,
      flex: 3,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        if ('anonymous' in row && row.anonymous) {
          return 'Anonymous'
        }
        return row.donorName ?? ''
      },
      valueFormatter: ({ api: { getRow } = {}, id, value }) =>
        grantRequestValueFormatter(value, getRow(id)?.isGrantRequest),
    },
    {
      /** HIDDEN */
      hide: true,
      field: 'fundName',
      headerName: 'Fund Name',
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      renderCell: ({ value, row }) => {
        let rval = value

        if ('anonymous' in row && row.anonymous) {
          return 'Anonymous'
        }

        if (value.length > 30) {
          rval = `${value.slice(0, 30)}...`
        }

        return <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{rval}</span>
      },
    },
    {
      /** HIDDEN */
      hide: true,
      field: 'email',
      headerName: 'Email Address',
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: ({ value, row }) => {
        if ('anonymous' in row && row.anonymous) {
          return ''
        }
        return value
      },
    },
    {
      field: 'nextScheduledGrant',
      headerName: 'Next Date',
      flex: 1,
      minWidth: 100,
      type: 'date',
      align: isNarrowerThanSm && 'right',
      headerAlign: isNarrowerThanSm && 'right',
      valueFormatter: ({ value } = {}) => toDateString(value),
    },
    {
      field: 'state',
      headerName: 'State',
      hide: isNarrowerThanMd,
      flex: 1,
      minWidth: 100,
      renderCell: ({ value } = {}) => <Icons.State state={value} />,
    },
    {
      /** HIDDEN */
      hide: true,
      field: 'grantNote',
      headerName: 'Grant Note',
      flex: 1,
      minWidth: 100,
    },
  ]

  return (
    <Container maxWidth="lg">
      <CardBox>
        {charity ? (
          <DataGrid
            ref={gridRef}
            apiRef={gridApi}
            exportFileName={`Recurring Gift - ${getTodaysDateFileName()}`}
            disableColumnSelector
            rows={rows}
            columns={columns}
            loading={charityLoading}
            initialState={{
              sorting: {
                sortModel: [{ field: 'nextScheduledGrant', sort: 'desc' }],
              },
            }}
            components={{ Row: ExpandableRow }}
            componentsProps={{ row: { isExpanded } }}
            autoRowHeight
            sx={{
              '& .MuiDataGrid-cell': {
                p: '3px',
              },
            }}
          />
        ) : (
          ''
        )}
      </CardBox>
    </Container>
  )
}

// Renders if description is from a grant request, else returns the string value
function GrantRequestDescriptionRender({ descriptionValue, fundId, isGrantRequest }) {
  if (isGrantRequest) {
    const [charity, description] = descriptionValue
    return (
      <Text.AltBody>
        {charity && <Link to={`/funds/${fundId}/grant/${charity?.id}`}>{charity?.accountName}</Link>}
        {description && `${charity && ', '}${description}`}
      </Text.AltBody>
    )
  }

  return descriptionValue
}
