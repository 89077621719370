import AppBar from '@mui/material/AppBar'
import { useLocation } from 'react-router'

import { Container } from '..'
import logo from './givewise-logo-wordmark.png'

export function Header() {
  const { pathname } = useLocation()
  const isCharitySignUpPage = pathname.indexOf('/charities') > -1 && pathname.indexOf('/claim') > -1
  const logoAlign = isCharitySignUpPage ? 'left' : 'center'

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none',
        backgroundColor: 'var(--lightest-grey)',
      }}
    >
      <Container maxWidth="xl" sx={{ mb: 0, padding: isCharitySignUpPage ? '25px' : 'auto' }} data-testid="logo">
        <a style={{ textAlign: logoAlign, display: 'block', margin: '1rem auto' }} href="https://www.givewise.ca">
          <img src={logo} alt="GiveWise Home" width="220" height="39" />
          <span className="sr-only">GiveWise Home</span>
        </a>
      </Container>
    </AppBar>
  )
}
