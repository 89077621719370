import { useCallback, useEffect, useState, lazy, Suspense } from 'react'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { onlyShowInDev, showUnfinishedFeatures } from './gift/showUnfinishedFeatures'
import {
  Acknowledge,
  BlockQuote,
  Button,
  Collapse,
  Container,
  Icons,
  Iframe,
  LinearProgress,
  Link,
  Row,
  Stack,
  Text,
} from '../components'
import { NoMatch } from '.'
import { mutations, queries } from '../graphql'
import { useSearchParams } from '../hooks'
import { VOPAY_EMBED_URL } from '../constants'
import { BankTransferZum } from './funds/contribute'

export function CharityPaylink({ showCharityName = true }) {
  const { id: charityId, token: charityToken } = useParams()
  const [{ Token: vopayToken, MaskedAccount, Bank }] = useSearchParams()
  const { data: { charity } = {}, loading: loadingCharity } = useQuery(queries.charities.charity, {
    variables: { charityId, charityToken },
  })
  const [
    charityAddLinkedBank,
    { data: linkedBankData, error: linkedBankError, loading: linkedBankLoading, called: linkedBankCalled },
  ] = useMutation(mutations.paymentMethods.charityAddLinkedBank)
  const { data: iq11Data = {}, loading: iq11Loading } = useQuery(queries.paymentMethods.iq11Url, {
    variables: {
      args: {
        charityId,
        charityToken,
        showPadAgreementPrompt: false,
      },
    },
  })
  const { EmbedURL: embedUrl, IframeKey: iframeKey } = iq11Data?.iq11Url || {}
  const [addLinkedBankResponse, setAddLinkedBankResponse] = useState(null)
  const apolloClient = useApolloClient()

  const messageHandler = useCallback(async (event = {}) => {
    const { data, origin } = event

    console.log(event)

    if (!data || origin !== VOPAY_EMBED_URL) return
    // const { Step: step, Token: token, Institution: institution, AccountNumber: accountNumber } = data
    // if (step !== 'LINK') return
    // charityAddLinkedBank({
    //   variables: {
    //     data: {
    //       token,
    //       charityId,
    //       charityToken,
    //       institution,
    //       last4: accountNumber?.slice(-4) ?? '',
    //       vopayIframeMessageData: JSON.stringify(data),
    //     },
    //   },
    // })

    // Use REST instead of GraphQL
    await fetch(`${process.env.REACT_APP_API_HOST}/vopay/charityAddLinkedBank`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('givewise_auth_state'))?.state?.rawToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        charityId,
        charityToken,
        data,
        requestSource: 'regular',
      }),
    })

    if (!data?.Step || (data?.Step && data?.Step !== 'LINK')) return

    await apolloClient.refetchQueries({
      include: ['Charity', 'getCharityBankAccounts', 'myCharityActivity'],
    })
    setAddLinkedBankResponse(true)
  })
  useEffect(async () => {
    if (vopayToken && charityId && charityToken) {
      // charityAddLinkedBank({
      //   variables: {
      //     data: {
      //       token: vopayToken,
      //       charityId,
      //       charityToken,
      //       institution: Bank,
      //       last4: MaskedAccount,
      //     },
      //   },
      // })

      await fetch(`${process.env.REACT_APP_API_HOST}/vopay/charityAddLinkedBank`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('givewise_auth_state'))?.state?.rawToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: vopayToken,
          charityId,
          charityToken,
          institution: Bank,
          last4: MaskedAccount,
          requestSource: 'useEffect',
        }),
      })

      await apolloClient.refetchQueries({
        include: ['Charity', 'getCharityBankAccounts', 'myCharityActivity'],
      })

      setAddLinkedBankResponse(true)
    }
    window.addEventListener('message', messageHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [])
  const Success = useCallback(
    () => <Acknowledge.Success title="Thanks!" content="Your funds will be arriving shortly!" />,
    []
  )
  const Error = useCallback(
    () => (
      <Acknowledge.Error
        title="Uh Oh!"
        content="We could not save your bank account"
        message={linkedBankError?.message}
      />
    ),
    [linkedBankError?.message]
  )
  if (iq11Loading || loadingCharity) return <LinearProgress />
  if (!charity)
    return (
      <NoMatch title="">
        <Text.Body>
          The link you are trying to access is no longer valid. It has either been completed or has been replaced with a
          new link.
          <br />
          For more information or for assistance with a new link, please contact{' '}
          <Link href="mailto:grants@givewise.ca">grants@givewise.ca</Link>.
        </Text.Body>
      </NoMatch>
    )
  const { accountName: name, id: charityBn } = charity
  return (
    <Container maxWidth="lg">
      <Stack sx={{ width: '100%' }}>
        {showCharityName ? (
          <>
            <Text.H1>{name}</Text.H1>
            <Text.H2>
              <i>#{charityBn}</i>
            </Text.H2>
          </>
        ) : (
          ''
        )}

        <Acknowledge.Switch
          success={(addLinkedBankResponse || !!linkedBankData) && <Success />}
          error={!!linkedBankError && <Error />}
        >
          <BankTransferZum />
        </Acknowledge.Switch>
      </Stack>
    </Container>
  )
}
