import { CAD } from '@dinero.js/currencies'
import { dinero, toDecimal, toSnapshot } from 'dinero.js'

/**
 * @typedef {Object} CadFromDecimalParams
 * @property {number} amount - Decimal amount of CAD
 * @property {number} [scale=CAD.exponent] - If amount is not a decimal number of Dollars scale, the scale to use
 */

/**
 * Get a Dinero object with CAD amount from a decimal amount
 *
 * @param {CadFromDecimalParams} params
 * @returns {import("dinero.js").Dinero}
 */
export function cadFromDecimal({ amount, scale }) {
  const factor = CAD.base ** CAD.exponent || scale
  return dinero({ amount: Math.round(amount * factor), currency: CAD, scale })
}

/**
 * Get a decimal number in CAD for a Dinero object containing a CAD amount
 *
 * @param {import("dinero.js").Dinero} cadValue Dinero value with CAD currency
 * @return {number}
 */
export function decimalFromCad(cadValue) {
  if (toSnapshot(cadValue).currency.code !== CAD.code) throw new Error('Cannot convert currency')
  return toDecimal(cadValue, ({ value }) => Number(value))
}
