import React, { useEffect, useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { mutations, queries } from '../../../graphql'
import { Button, CardBox, Stack, Container, Fields, useAlert, InputAdornment, validations } from '../../../components' // Adjust the import path as necessary
import { fromAmountField } from '../../../utils'

export function PayButton({ customer }) {
  const { fundId } = useParams()
  const client = useApolloClient()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const { register, handleSubmit, watch, getValues, formState, setValue } = useForm({
    defaultValues: {
      amount: '0', // Default price
    },
  })

  const price = watch('amount') // Watch the amount field to get its current value
  const [contributionProcessed, setContributionProcessed] = useState(false)

  // Function to handle messages from React Native app
  const handleMessage = async (event) => {
    const data = JSON.parse(event.data)
    if (data.status === 'success' && !contributionProcessed) {
      console.log(`Payment successful: ${data.amount}`)

      await client.mutate({
        mutation: mutations.contributions.createContributionRecord,
        variables: {
          fundId: Number(fundId),
          amount: fromAmountField(data.amount),
          paymentType: 12,
          description: 'Apple Pay',
          taxReceipted: true,
          state: 1, // state for successful payment
        },
      })
      setContributionProcessed(true)
      window.removeEventListener('message', handleMessage)
    } else if (data.status === 'fail' && !contributionProcessed) {
      console.error(`Payment failed. Error code: ${data.errorCode}, Message: ${data.errorMessage}`)
      await client.mutate({
        mutation: mutations.contributions.createContributionRecord,
        variables: {
          fundId: Number(fundId),
          amount: fromAmountField(data.amount),
          paymentType: 12,
          description: 'Apple Pay',
          taxReceipted: true,
          state: 4, // state for failed payment
        },
      })
      setContributionProcessed(true)
      window.removeEventListener('message', handleMessage)
    }
  }

  // Sends data to React Native app
  const handlePay = () => {
    setContributionProcessed(false)
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        price: getValues().amount,
      })
    )
  }

  const onSubmit = async ({ amount }) => {
    try {
      window.addEventListener('message', handleMessage)
      handlePay()
      console.log('Payment initiated')
    } catch (e) {
      console.log('Payment initiation failed', e.message)

      setAlert({
        message: `Failed to initiate payment: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
    }
  }

  const { errors = {} } = formState

  return (
    <Container maxWidth="lg">
      <CardBox title="Pay with Apple Pay">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Fields.Text
              label="Amount"
              name="amount"
              error={!!errors?.amount}
              helperText={errors?.amount?.message}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              {...register('amount', {
                required: 'Amount is required',
                validate: {
                  positive: (value) => parseFloat(value) > 0 || 'Must be a positive number',
                  min: (value) => parseFloat(value) >= 1 || 'Must be at least 1',
                },
              })}
            />
            <Button type="submit" sx={{ padding: '10px 20px', fontSize: '16px' }}>
              Pay with Apple Pay
            </Button>
          </Stack>
        </form>
      </CardBox>
    </Container>
  )
}
