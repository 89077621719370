import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useApolloClient, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  Acknowledge,
  Button,
  Container,
  ContentBox,
  Dialog,
  Fields,
  InputAdornment,
  LinearProgress,
  Row,
  Recurring,
  Stack,
  Text,
  insufficientFundsExtraMessage,
  longWait,
  useAlert,
  useDialog,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { fromAmountField, toAmountString } from '../../utils'

export function ShareFundsWithGenerosityFund() {
  const client = useApolloClient()
  const [success, setSuccess] = useState(null)
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: '',
    },
  })
  const { isSubmitting, errors = {} } = formState

  const params = useParams()
  const userFundId = Number(params.fundId)
  const targetFundId = Number(params.generosityFundId)

  const { data: { generosityFund } = {}, loading } = useQuery(queries.funds.generosityFund, {
    variables: { fundId: targetFundId },
  })

  const onSubmit = async (form) => {
    let dafTransfer = null

    try {
      const { data: { giveToGenerosityFundAsUser } = {} } = await client.mutate({
        mutation: mutations.funds.giveToGenerosityFundAsUser,
        variables: {
          data: {
            amount: fromAmountField(form.amount),
            userFundId,
            targetFundId,
            isRecurring: form.isRecurring,
            recurringInterval: form.recurringInterval,
          },
        },
        refetchQueries: [
          { query: queries.funds.myFund, variables: { fundId: userFundId } },
          { query: queries.funds.myFundActivity, variables: { fundId: userFundId } },
          { query: queries.funds.myRecurring, variables: { fundId: userFundId } },
        ],
      })
      dafTransfer = giveToGenerosityFundAsUser
    } catch (error) {
      console.error({ ...error })
      setAlert({
        timeout: longWait,
        message: 'Something went wrong',
        error: error.message,
        extraMessage: error?.message.includes('insufficient') ? insufficientFundsExtraMessage(userFundId) : '',
        severity: 'error',
      })
      return
    }

    setSuccess(dafTransfer)
    openDialog()
  }

  const closeSuccessDialog = () => {
    setSuccess(null)
    resetForm()
    closeDialog()
  }

  if (loading) return <LinearProgress />

  return (
    <Container maxWidth="sm">
      <Stack>
        <Text.H1>
          <small style={{ fontWeight: 'normal' }}>Give to</small>
          <br />
          {generosityFund?.name}
        </Text.H1>
        <ContentBox border>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Fields.Text
                label="Amount"
                name="amount"
                error={!!errors?.amount}
                helperText={errors?.amount?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputComponent: Fields.Amount,
                  inputProps: {
                    setValue: (value) => setValue('amount', value),
                    getValue: () => getValues('amount'),
                    defaultValue: '',
                  },
                }}
                {...register('amount', {
                  ...{ min: { value: 1, message: 'Must be at least 1' } },
                  ...validations.required,
                  ...validations.number,
                })}
              />
              <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
              <Row justifyContent="flex-end">
                <Button type="submit" disabled={loading || isSubmitting}>
                  Send
                </Button>
              </Row>
              <Alert {...alertProps} />
            </Stack>
          </form>
        </ContentBox>
      </Stack>
      {success && (
        <Dialog {...dialogProps} onClose={closeSuccessDialog}>
          <Acknowledge.Success
            title="Donation Received!"
            content={
              <Text.Body>
                <b>Amount</b>: {toAmountString(success.amount)}
                <br />
                <b>To</b>: {generosityFund?.name}
              </Text.Body>
            }
          >
            <Button to={`/funds/${userFundId}`}>Return to Activity</Button>
          </Acknowledge.Success>
        </Dialog>
      )}
    </Container>
  )
}
