import { useState } from 'react'
import { useCreate, useListContext, useNotify, useRecordContext, useResourceContext } from 'react-admin'
import { Box, Button, TextField as TextInput } from '@mui/material'

export function NewNote({ parent }) {
  const resource = useResourceContext()
  const record = useRecordContext()
  const { refetch } = useListContext()
  const [text, setText] = useState('')
  const [create, { isLoading }] = useCreate()
  const notify = useNotify()
  if (!record) return null

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = {
      objectType: parent.type,
      objectId: parent.id,
      note: text,
    }
    create(
      resource,
      { data },
      {
        onSuccess: () => {
          setText('')
          notify('Note added successfully')
          refetch()
        },
      }
    )
    return false
  }
  return (
    <Box mt={4} mb={1}>
      <form onSubmit={handleSubmit}>
        <TextInput
          label="Add a note"
          variant="filled"
          size="small"
          fullWidth
          multiline
          value={text}
          onChange={(event) => setText(event.target.value)}
          rows={3}
        />
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button type="submit" variant="contained" color="primary" disabled={!text || isLoading}>
            Add
          </Button>
        </Box>
      </form>
    </Box>
  )
}
