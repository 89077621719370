import { useState, useEffect } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { Icons } from '..'

export function ToggleButtons({ onClicks, viewToggleState }) {
  const [alignment, setAlignment] = useState(viewToggleState)
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }
  useEffect(() => {
    setAlignment(viewToggleState)
  }, [viewToggleState])

  return (
    <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
      <ToggleButton value="left" aria-label="left aligned" onClick={onClicks?.[0]}>
        <Icons.Grid />
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned" onClick={onClicks?.[1]}>
        <Icons.List />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
