import { useQuery } from '@apollo/client'
import { Navigate, useNavigate } from 'react-router'

import {
  CardBox,
  Container,
  Icons,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '../../components'
import { queries } from '../../graphql'

export function FundList() {
  const navigate = useNavigate()
  const { data: { me: { funds = [] } = {} } = {}, loading } = useQuery(queries.funds.myFunds)
  if (loading) {
    return <LinearProgress />
  }
  if (funds.length === 1) {
    return <Navigate to={`${funds?.[0]?.id}`} />
  }
  return (
    <Container maxWidth="lg">
      <CardBox title="My Funds">
        <List>
          {funds.map((fund) => (
            <ListItem key={fund.id}>
              <ListItemButton onClick={() => navigate(`${fund.id}`)}>
                <ListItemIcon>
                  <Icons.Paid />
                </ListItemIcon>
                <ListItemText primary={`${fund.name} - #${fund.number}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardBox>
    </Container>
  )
}
