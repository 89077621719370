import { useMutation } from '@apollo/client'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { Acknowledge, Button, Dialog, Link, Row, Stack, Text, useDialog } from '..'

const ConfirmContext = createContext(null)

function ConfirmDialog({ confirm, reset }) {
  const {
    title = '',
    message = '',
    success = '',
    error = '',
    mutation,
    variables,
    refetchQueries = [],
    onSuccess = null,
  } = confirm
  const [{ dialogProps }] = useDialog()
  const open = !!mutation
  const [mutate, { data, error: mutationError }] = useMutation(mutation, {
    refetchQueries,
  })
  const Success = useCallback(
    () => (
      <Acknowledge.Success title="Success!" content={success}>
        <Button
          onClick={() => {
            reset()
            if (onSuccess) {
              onSuccess()
            }
          }}
        >
          Done
        </Button>
      </Acknowledge.Success>
    ),
    [success, reset]
  )
  const Error = useCallback(
    () => (
      <Acknowledge.Error title="Uh Oh!" content={error}>
        <Button onClick={reset}>Back</Button>
      </Acknowledge.Error>
    ),
    [reset, error]
  )
  return (
    <Dialog {...dialogProps} open={open} onClose={reset}>
      <Acknowledge.Switch success={!!data && <Success />} error={!!mutationError && <Error />}>
        <Stack spacing={2}>
          <Text.H5>{title}</Text.H5>
          {typeof message === 'string' ? <Text.Body>{message}</Text.Body> : message}
          <Row spacing={2} sx={{ justifyContent: { md: 'flex-end' } }}>
            <Button color="info" onClick={reset}>
              Back
            </Button>
            <Button onClick={() => mutate({ variables })}>Confirm</Button>
          </Row>
        </Stack>
      </Acknowledge.Switch>
    </Dialog>
  )
}

export function ConfirmProvider({ children }) {
  const initialState = {
    title: '',
    message: '',
    success: '',
    error: '',
    mutation: null,
    variables: null,
  }
  const [confirm, setConfirm] = useState(initialState)
  const reset = () => setConfirm(initialState)
  const value = useMemo(() => [confirm, { setConfirm }], [confirm, setConfirm])
  return (
    <ConfirmContext.Provider value={value}>
      {children}
      {confirm?.mutation ? <ConfirmDialog confirm={confirm} reset={reset} /> : null}
    </ConfirmContext.Provider>
  )
}

export function useConfirm() {
  return useContext(ConfirmContext)
}
