import { gql } from '@apollo/client'

export const createGrantRequest = gql`
  mutation CreateGrantRequest($data: CreateGrantRequestInput!) {
    createGrantRequest(data: $data) {
      id
      grantNote
      amount
      tip
      createdAt
      updatedAt
      anonymous
      processingNote
      checkNumber
      checkClearedAt
      processingDate
    }
  }
`

export const createGuestGrantRequest = gql`
  mutation CreateGuestGrantRequest($userId: Int!, $data: CreateGrantRequestInput!) {
    createGuestGrantRequest(userId: $userId, data: $data) {
      id
      grantNote
      amount
      tip
      createdAt
      updatedAt
      anonymous
      processingNote
      checkNumber
      checkClearedAt
      processingDate
    }
  }
`

export const userUpdateRecurrentGrantRequest = gql`
  mutation userUpdateRecurrentGrantRequest($data: UpdateRecurrentGrantRequestInput!) {
    userUpdateRecurrentGrantRequest(data: $data) {
      id
    }
  }
`

export const cancelRecurringGrantRequest = gql`
  mutation cancelRecurrentGrantRequest($id: Int!) {
    cancelRecurrentGrantRequest(id: $id) {
      id
      nextScheduledGrant
      state
    }
  }
`

export const cancelPendingGrantRequest = gql`
  mutation cancelPendingGrantRequest($id: Int!) {
    cancelPendingGrantRequest(id: $id) {
      id
      state
    }
  }
`
