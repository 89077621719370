import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { Icons } from '..'

export function CircularProgressWithLabel(props = {}) {
  const [progress, setProgress] = useState(0)
  const [val, setVal] = useState(props.value)

  useEffect(() => {
    // if (progress >= val) {
    //   return () => {}
    // }

    // const timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10))
    // }, 50)

    // return () => clearInterval(timer)
    setProgress(val)
  }, [progress, val])

  useEffect(() => {
    setVal(props.value)
  }, [props.value])

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{
            fontSize: '1.25rem',
            fontWeight: '450',
            color: 'white',
          }}
        >
          {progress === 100 ? <Icons.Checkmark /> : `${Math.round(val)}%`}
        </Typography>
      </Box>
    </Box>
  )
}
